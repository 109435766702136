<template>
  <div
    class="page-block block-padding"
    style="word-wrap: break-word; margin-top: 40px"
  >
    <h1 align="center">Allgemeine Nutzungsbedingungen</h1>
    <h1 align="center">Comii Alltagshelfer App - Deutschland</h1>
    <h1 align="center">Eine Lösung der Digital Suxess AG, Stuttgart</h1>
    <h1 style="color: #2f5496">Inhalt</h1>

    <p>
      <a
        href="#_Toc82173103"
      >
        WICHTIGES ÜBER Comii IM ÜBERBLICK
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173104"
      >
        ÜBER UNS
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173105"
      >
        DEFINITIONEN
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173106"
      >
        BEDINGUNGEN
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173107"
      >
        1. Überblick
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173108"
      >
        2. Comii App Dienstleistungen
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173109"
      >
        3. Drittleistungen
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173110"
      >
        4. Registrierung und Nutzung der Comii App Dienstleistungen
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173111"
      >
        5. Ihre Pflichten
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173112"
      >
        6. Kosten und Zahlungen
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173113"
      >
        7. Stornierung
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173114"
      >
        8. Lizenz
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173115"
      >
        9. Geistiges Eigentum
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173116"
      >
        10. Datenschutz
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173117"
      >
        11. Ihre Haftung
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173118"
      >
        12. Verfügbarkeit
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173119"
      >
        13. Haftung und Haftungsbegrenzung
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173120"
      >
        14. Kündigung und Beendigung
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173121"
      >
        15. Änderungen von Bedingungen
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173122"
      >
        16. Verfahren zur Streitbeilegung
      </a>
    </p>
    <p>
      <a
        href="#_Toc82173123"
      >
        17. Anwendbares Recht und Gerichtsstand
      </a>
    </p>
    <h1>
      <a name="_Toc82173103">WICHTIGES ÜBER Comii IM ÜBERBLICK</a>
    </h1>
    <p>
      Diese Bedingungen finden auf die Nutzung der Comii Alltagshelfer App
      und/oder Comii Webseiten in Deutschland Anwendung.
    </p>
    <p>
      Die App-/Webseiten-Dienstleistungen ermöglichen es Ihnen, Dienstleistungen
      als Drittanbietern über Comii anzubieten und Dienstleistungsverträge
      direkt abzuschliessen.
    </p>
    <p>
      Sie schliessen die Verträge direkt mit den Auftraggebern (Senioren) ab.
      Comii (Eine Marke der Digital Suxess AG, Stuttgart) agiert als Vermittler.
    </p>
    <p>
      Bitte lesen Sie diese Bedingungen aufmerksam. Sie enthalten Informationen
      über Ihre Rechte, zur Nutzung der App, unseren Dienstleistungen sowie
      weitere andere wichtige Informationen.
    </p>
    <h1>
      <a name="_Toc82173104">ÜBER UNS</a>
    </h1>
    <p>
      Ihr Vertragspartner zur Nutzung der Comii App ist die Digital Suxess AG,
      Stuttgart, eine in Deutschland errichtete Aktiengesellschaft, eingetragen
      beim Amtsgericht Stuttgart unter der Nummer HRB 778492 mit Sitz in
      Löffelstr. 44, 70597 Stuttgart (nachfolgend „Comii“, “wir” oder “uns”).
      Aufsichtsrat Dr. Rainer Schultheiss, Vorstand Florian Buschbacher. Sie
      können uns unter info@comii.de kontaktieren.
    </p>
    <p>
      Unter der Marke Comii vermittelt die Digital Suxess AG Senioren, welche
      Dienstleistungen im Umfeld der Alltagshilfe bedürfen. Diese
      Dienstleistungen, werden nicht durch Comii selbst erbracht, sondern werden
      durch Sie als unabhängige Dritte – genannt Alltagshelfer - geleistet.
      Alltagshelfer können gewerbliche tätige Personen (Hausmeister, mobile
      Pflegedienste, etc.) oder Privatpersonen (selbständige) mit einer gewerblichen Anmeldung
      als Kleinunternehmer (gem. §19 UstG) sein. Comii selbst wird nicht als
      Alltagshelfer tätig und gibt auch kein entsprechendes Angebot zur
      Erbringung einer solchen Leistung ab. Comii wird ausschliesslich als
      Anbieter einer Vermittlungsdienstleistung tätig, indem auf elektronischem
      Weg an einer Alltagshilfe interessierte Nutzer mit Ihnen als
      Alltagshelfern verbunden werden und so das Zustandekommen eines
      Dienstleistungsvertrages zwischen diesen Nutzern (Senioren) und Ihnen als
      Drittanbietern vermittelt wird. Zwischen Comii und Ihnen kommt kein
      Vertrag zur Alltagshilfe zu Stande.
    </p>
    <p>
      Zur Nutzung unserer Vermittlungsplattform Comii schliessen Sie als Comii APP
      Alltagshelfer einen Abovertrag über den jeweiligen APP Store 
      (Google Play Store/Apple APP Store) ab. Parallel werden Verträge zwischen den 
      Alltagshelfern und Comii insoweit geschlossen, als dass Comii die Rechnungserstellung 
      und weiterer Leistungen für Sie als Alltagshelfer (Dritte) übernimmt. Für unsere 
      Dienstleistung der Anbahnung, Vermittlung, Abwicklung, Rechnungserstellung und 
      Zahlungsabwicklung über Stripe (Stripe.com) fallen Transaktionsgebühren an.
    </p>
    <h1>
      <a name="_Toc82173105">DEFINITIONEN</a>
    </h1>
    <p>
      “Senioren Richtlinie” meint die jeweils aktuelle Richtlinie, die
      allgemeinen Verhaltensregeln aller Senioren, welche die Comii APP nutzen
      und Hilfe beanspruchen wollen. Die aktuelle Fassung der Senioren
      Richtlinie kann unter Comii.de eingesehen werden.
    </p>
    <p>
      “Alltagshelfer Richtlinie” meint die jeweils aktuelle Richtlinie, die
      allgemeinen Verhaltensregeln aller Alltagshelfer, welche die Comii App
      nutzen und als Alltagshelfer für Sie tätig werden. Die aktuelle Fassung
      der Alltagshelfer Richtlinie kann hier Comii.de eingesehen werden.
    </p>
    <p>
      “Drittanbieter” sind von Comii unabhängige Dritte und umfassen,
      insbesondere unabhängige Alltagshelfer, mobile Pflegedienste, Handwerker,
      Hausmeister oder Anbieter anderer Dienstleistungen im Leistungsspektrum
      von Comii.
    </p>
    <p>
      “Drittleistungen” umfassen insbesondere Leistungen in den Bereichen
      Alltagsunterstützung wie z.B. putzen, kochen, waschen, Wäsche waschen,
      Blumen giessen, Hunde ausführen, Rezepte in der Apotheke einlösen, kurze
      Fahrdienste zum Arzt oder kleinere Besorgungen des Alltages.
    </p>
    <h1>
      <a name="_Toc82173106">BEDINGUNGEN</a>
    </h1>
    <h2>
      <a name="_Toc82173107">1. Überblick</a>
    </h2>
    <p>
      1.1. Diese Bedingungen regeln Ihre Registrierung, Ihren Zugang und Ihre
      Nutzung unserer Comii App (“Bedingungen”). Sie sollten die Bedingungen
      sorgfältig lesen und müssen diesen zustimmen, bevor Sie auf unsere Comii
      App zugreifen oder diese nutzen wollen. Mit Ihrer Zustimmung zur Geltung
      dieser Bedingungen im Rahmen Ihrer Registrierung und der Bestätigung Ihrer
      Registrierung durch uns, wird eine vertragliche Beziehung zwischen Ihnen
      und der Digital Suxess AG zur Nutzung der Comii App begründet. Wenn Sie
      diesen Bedingungen nicht zustimmen, erhalten Sie keinen Zugang zu unseren
      Dienstleistungen der Comii App und können diese nicht nutzen.
    </p>
    <p>
      1.2. Comii handelt als Vermittler für Sie als Drittanbieter
      (Alltagshelfer) und Ihren Leistungen sowie als Dienstleister für die
      verwaltungstechnischen Vorgänge wie Rechnungserstellung (Alltagshelfer an
      Senior) und Zahlungseinzug nach erbrachter Dienstleistung (Drittleistung).
      Dabei übernimmt Comii kein Inkassorisiko oder wird als Factoring
      Unternehmen tätig. Das Zahlungsausfallrisiko trägt stets der
      Alltagshelfer.
    </p>
    <h2>
      <a name="_Toc82173108">2. Comii App Dienstleistungen</a>
    </h2>
    <p>
      2.1. Comii erbringt Ihnen gegenüber Dienstleistungen, die folgendes
      umfassen:
    </p>
    <p>§ Zugang und Nutzung der Comii Alltagshelfer App und Comii Webseiten</p>
    <p>§ Erstellung eines persönlichen Profils auf Comii</p>
    <p>
      § Vermittlung von Aufträgen als Alltagshelfer in Ihrem Georaum von ca. 10
      km
    </p>
    <p>
      § Sicherstellung eines möglichst korrekten Matchings zwischen Angebot und
      Nachfrage auf Basis der zu erledigenden Aufgaben
    </p>
    <p>
      § Sicherstellung eines auf Ihre Präferenzen dargelegten Auftragswunsches
    </p>
    <p>§ Dokumentation des Vertragsabschlusses</p>
    <p>§ Dokumentation der erbrachten bzw. gewünschten Leistung</p>
    <p>§ Dokumentation Ihres Zeitaufwandes</p>
    <p>§ Berechnung Ihres daraus resultierenden Umsatzes</p>
    <p>§ Erstellung einer Rechnung für Sie mit Adressat Ihres Auftraggebers</p>
    <p>§ Bereitstellung dieser Rechnung in Ihrem Portal</p>
    <p>§ Lastschrifteinzug durch Comii und Weiterleitung an Sie</p>
    <p>
      § Berechnung der Transaktionsgebühr und Darstellung in Ihrer
      Auftragsübersicht
    </p>
    <p>
      § Aufstellung sämtlicher Aufträge und Übersicht über Ihren Zahlungseingang
    </p>
    <p>
      2.2. Diese Bedingungen finden auf Ihre Nutzung der
      App-/Webseiten-Dienstleistungen in Deutschland Anwendung.
    </p>
    <p>
      2.3. Dort wo verfügbar, können Senioren Drittleistungen unter Nutzung der
      Comii App anfordern und erhalten. Comii kann nicht dafür garantieren, dass
      flächendeckend oder zeitlich umfangreiche Anfragen bestehen. Als
      Alltagshelfer sind Sie verpflichtet nach Auftragsannahme unverzüglich beim
      Auftraggeber zu erscheinen. Dabei sollte die Dauer zwischen
      Auftragsannahme und Ankunft beim Senior 30 Minuten nicht überschreiten.
      Dadurch wollen wir eine möglichst kurze Wartezeit und damit ein
      Kundenerlebnis für den hilfsbedürftigen Senior erreichen.
    </p>
    <h2>
      <a name="_Toc82173109">3. Drittleistungen</a>
    </h2>
    <p>
      3.1. Comii erbringt die Drittleistungen nicht selbst. Alle Drittanbieter
      sind unabhängige dritte Auftragnehmer, Kleinunternehmer, Unternehmer oder
      sonstige juristische Personen, die nicht bei Comii angestellt sind und
      nicht als Comii Subunternehmer, Beauftragte oder im Auftrag von Comii
      handeln. Wir behalten uns vor, eigene Comii Alltagshelfer zu engagieren,
      sollten es die äusseren Umstände erforderlich machen bspw. bei einer zu
      geringen Verfügbarkeit von Alltagshelfern in bestimmten Regionen.
    </p>
    <p>
      3.2. Verfügbare Alltagshelfer können sich frei entscheiden, eine Anfrage
      anzunehmen oder abzulehnen. Anfragen über die Comii App erhalten Sie
      ausschliesslich nur, wenn Sie Ihre Standortdaten (GPS) aktiviert haben.
      Diese Aktivierung kann entweder nur bei Nutzung bzw. aktivierter App
      erfolgen oder auf Wunsch auch ohne aktiver App, also im Hintergrund
      laufen. Dies garantiert Ihnen sämtliche Anfragen als Push Notification
      direkt auf Ihrem Handy zu erhalten. Derjenige Alltagshelfer der zuerst die
      Anfrage bestätigt erhält den Auftrag. Damit ist der Vertrag zwischen Ihnen
      und dem Senior begründet. Dies ist der Startpunkt sich an die Adresse
      (bzw. Aktive Geoposition) des Seniors zu begeben.
    </p>
    <p>
      3.4. Für manche Drittleistungen ist es gegebenenfalls erforderlich, dass
      Sie gesonderten Vertragsbedingungen mit Ihrem Auftraggeber aushandeln.
    </p>
    <p>
      3.5. Sie werden nach jedem Auftrag gebeten, Ihren Auftraggeber (Senior) zu
      bewerten. Die Senioren werden ebenso gebeten, den Auftrag mit Ihnen zu
      bewerten. Alltagshelfer und Senioren können jeweils die Bewertungen
      einsehen. Des Weiteren können diese Bewertungen entscheidungserheblich
      dafür sein, ob Drittanbieter Drittleistungen weiter erbringen dürfen oder
      nicht. Für zusätzliche Informationen zu den Bewertungen lesen Sie bitte
      unsere Senioren bzw. Alltagshelfer Richtlinien auf Comii.de.
    </p>
    <p>
      3.6. Der Alltagshelfer ist für sämtliche Verpflichtungen verantwortlich,
      die aus der Erbringung der vermittelten Drittleistungen entstehen.
    </p>
    <h2>
      <a name="_Toc82173110">
        4. Registrierung und Nutzung der Comii App Dienstleistungen
      </a>
    </h2>
    <p>
      4.1. Die Comii App kann auf den meisten modernen, mit den verbreiteten
      Betriebssystemen wie Android oder iOS ausgestatteten Mobilgeräten mit
      Internetzugang und GPS Funktion heruntergeladen und genutzt werden.
    </p>
    <p>
      4.2. Für den Zugang zum Internet als Voraussetzung für die Nutzung der
      Comii App Dienstleistungen sind Sie selbst verantwortlich, ebenso wie für
      sämtliche Kosten und Gebühren Ihres Mobilfunknetzbetreibers,
      einschliesslich des Datenverbrauchs bei Nutzung unserer App. Sie sind auch
      für den Zugang zu kompatiblen Geräten und Betriebssystemen verantwortlich,
      die für die Nutzung der App erforderlich sind, inklusive sämtlicher
      zukünftiger Software- oder Hardware-Updates.
    </p>
    <p>
      4.3. Zur Nutzung der Comii Alltagshelfer APP müssen Sie sich in der 
      Comii App registrieren und einen Account erstellen. Eingabefehler können 
      Sie hierbei jederzeit über die entsprechende Funktionalität in der App 
      insbesondere im Bereich „Mein Profil“ korrigieren. Zum Zeitpunkt der 
      Registrierung müssen Sie mindestens achtzehn (18) Jahre alt sein. Ohne 
      die Registrierung Ihrer Bankverbindung (für Stripe.com) in der APP ist 
      eine Auftragsabwicklung nicht möglich. Ebenso sind Sie verpflichtet die 
      Unternehmenseigenschaft (Kleingewerbe, Freiwilliger Helfer, Ehrenamt, gewerbliche Tätigkeit) 
      korrekt auszufüllen bzw. jeweils korrekt einzustellen, damit eine Rechnung 
      ordnungsgemäss ausgestellt werden kann.
    </p>
    <p>
      4.4. Zur Registrierung müssen Sie uns bestimmte persönliche Informationen 
      inklusive Ihrer Adresse, Alter, Geschlecht, Steuernummer, ggf. USt. ID Nr., 
      polizeiliches Führungszeugnis, Qualifikationsnachweise, Personalausweis, 
      Telefonnummer und E-Mail-Adresse sowie weitere zur Nutzung bestimmter 
      Angaben mitteilen, damit wir später Ihre Aufträge vermitteln können. 
      Dies setzt insbesondere die Aktivierung der Standortinformationen (GPS) 
      voraus. Nur durch das Matching zwischen Geoposition des Alltagshelfers 
      und der Geoposition des Seniors können Aufträge vermittelt werden. Der 
      aktuelle Radius beträgt derzeit ca. 10 km. Zudem benötigen wir u.a. Ihre 
      Bankverbindungs- und Kontoinformationen, damit wir Ihre geleisteten 
      Dienstleistungen abrechnen und Transaktionskosten für Stripe.com erheben 
      können. Details zur Nutzung Ihrer personenbezogenen Daten und den Gründen 
      für diese Erhebung verweisen wir auf unsere Datenschutzrichtlinie die Sie 
      unter comii.de finden.
    </p>
    <p>
      4.5. Es ist möglich, dass vor der Nutzung spezifische ergänzende
      Informationen wie bspw. ein Selbstbild zur Identifikation, Kopie des
      Personalausweises oder ähnliche Daten angefordert werden. Erst nach
      vollständiger Übermittlung sämtlicher geforderter Dokumente und
      Informationen erfolgt die Freischaltung Ihres Profils zur Annahme von
      Aufträgen.
    </p>
    <p>
      4.6. Weder Sie noch die Senioren haben die Verpflichtung, sich bei Comii
      einzuloggen oder unsere Dienstleistungen zu nutzen. Wenn Sie sich
      entscheiden, die Nutzung von Comii zu beenden, so können Sie dies
      jederzeit anhand des gewählten Abovertrages in den jeweiligen App Stores
      durchführen.
    </p>
    <p>
      4.7. Wir können Ihren Zugang zu und die Nutzung der App vorübergehend oder
      dauerhaft einschränken oder sperren, wenn konkrete Anhaltspunkte die
      Annahme rechtfertigen, dass es zu einer von Ihnen zu vertretenen
      Verletzung Ihrer Pflichten oder einem Verstoss gegen diese Bedingungen
      gekommen ist (beispielsweise, wenn wir eine Beschwerde zu einem
      hinreichend konkreten Betrugsvorwurf erhalten). Es können Umstände
      vorliegen, unter denen es uns nicht gestattet ist, Sie über die Beschwerde
      zu informieren, solange die Ermittlungen (durch uns und/oder Dritte, wie
      z.B. die Polizei) hierzu noch laufen. Bei der Entscheidung über die
      Einschränkung oder Sperrung werden wir Ihre berechtigten Interessen
      angemessen berücksichtigen und die Einschränkung und Sperrung entsprechend
      wieder aufheben, wenn die Gründe hierfür wegfallen (z.B., wenn die oben
      genannten Zahlungen erfolgt sind).
    </p>
    <h2>
      <a name="_Toc82173111">5. Ihre Pflichten</a>
    </h2>
    <p>
      5.1. Sie dürfen bei der Nutzung bzw. unter Ausnutzung von Informationen,
      die Sie über Ihre Dienstleistung, Auftraggeber oder der Comii App erhalten
      haben, nicht gegen anwendbare Gesetze oder sonstige Rechtsvorschriften
      verstossen. Sie dürfen die App nur für rechtmässige Zwecke und solche Zwecke
      verwenden, für die sie bestimmt sind. Sie dürfen die Comii App nicht
      missbräuchlich nutzen oder versuchen, uns oder die Auftraggeber (z.B. in
      betrügerischer Absicht) zu schädigen. Für weitere Informationen zu den
      allgemeinen Verhaltensregeln beachten Sie bitte unsere Alltagshelfer und
      Senioren Richtlinien unter comii.de.
    </p>
    <p>
      5.2. Bei Anlegen eines Accounts und bei der Nutzung der App müssen Sie
      korrekte und vollständige Informationen angeben. Wir behalten uns das
      Recht zur Überprüfung Ihrer Angaben vor.
    </p>
    <p>
      5.3. Ihr Account ist nur für Sie bestimmt. Er kann nicht unterlizenziert
      oder geteilt werden, es sei denn, dies wird ausdrücklich von uns
      gestattet. Sobald Sie einen Account eingerichtet haben:
    </p>
    <p>§ dürfen Sie sich nicht für einen zusätzlichen Account registrieren;</p>
    <p>
      § dürfen Sie Dritten die Nutzung Ihres Accounts nicht erlauben und Ihren
      Account nicht auf Dritte übertragen;
    </p>
    <p>
      § müssen Sie Ihre erforderlichen Account-Informationen korrekt,
      vollständig und auf dem aktuellen Stand halten;
    </p>
    <p>§ müssen Sie Ihre Login-Angaben zu jeder Zeit vertraulich halten und</p>
    <p>
      § müssen Sie uns unverzüglich mitteilen, wenn Sie aus irgendeinem Grund
      den Verdacht haben, dass jemand anderes Ihren Benutzernamen oder Ihr
      Passwort kennt oder dass jemand anderes Ihren Account nutzt.
    </p>
    <p>
      5.4. Sie sind für sämtliche Gebühren oder Kosten verantwortlich, die durch
      das Anbieten Ihrer Dienstleistungen entstehen, die über Ihren Account
      angefragt, beauftragt oder storniert wurden.
    </p>
    <p>
      5.5. Bei Nutzung der Comii App -Dienstleistungen ist es nicht gestattet,
      Alltagshelfer (Drittanbieter), Comii oder sonstige Dritte zu belästigen,
      in deren Rechten zu beeinträchtigen oder Schäden an deren Eigentum zu
      verursachen.
    </p>
    <h2>
      <a name="_Toc82173112">6. Kosten und Zahlungen</a>
    </h2>
    <p>6.1. Kosten für die Nutzung der Comii App</p>
    <p>
      6.1.1. Die Nutzung der App ist für Alltagshelfer bei der Erstanmeldung
      kostenfrei. Wir behalten uns jedoch vor, eine Nutzungsgebühr (App Gebühr)
      einzuführen, worüber Sie in diesem Fall in Textform (ggf. über die App
      oder dem jeweiligen App Store) informiert werden und wobei Sie die
      Gelegenheit haben werden, das Vertragsverhältnis zu kündigen, bevor eine
      solche Gebühr eingeführt wird. Wenn Sie die Kündigung wählen, werden Sie
      keinen Zugang zu den Comii App Dienstleistungen mehr haben und diese nicht mehr
      nutzen können.
    </p>
    <p>
      6.1.2. Wir behalten uns das Recht vor, eine Gebühr für bestimmte Elemente
      sog. „In App Käufe“ einzuführen. Über die Einführung, Verfügbarkeit und
      den damit verbundenen Kosten informieren wir stets im Voraus.
    </p>
    <p>6.2. Kosten für Drittleistungen</p>
    <p>
      6.2.1. Wenn Sie eine Anfrage über Comii erhalten, erklären Sie sich damit
      einverstanden, Ihre Drittleistungen unverzüglich am Ort des Auftraggebers
      zu beginnen.
    </p>
    <p>
      6.2.2. Jeder Alltagshelfer erhält aktuell 25 Euro brutto pro Stunde. Die
      ersten 30 Minuten werden unabhängig von der konkreten Dauer pauschal
      abgerechnet. Danach wird im 15 Minuten Takt abgerechnet. Kosten für die
      An- und Abfahrt werden nicht erstattet. Der Beginn Ihrer Tätigkeit ist am
      Ort des Seniors. Erst dort dürfen Sie die Statusmeldung „Auftrag beginnen“
      drücken. Ab dieser Statusmeldung wird Ihnen die Zeit gutgeschrieben. Die
      Zeiterfassung erfolgt über Statusmeldungen in der App, so dass Sie in
      Ihrer Abrechnung, die für jeden Auftrag/Dienstleistung separat erstellt
      und in Ihrem Profil zugänglich gemacht wird und im Detail für Sie
      einsehbar ist, erkennen können. Bitte beachten Sie, dass Comii keine genaue Zeit und
      Kostenschätzung abgeben kann. Sie müssen sich mit dem Senior vor Ort über
      den zeitlichen und inhaltlichen Aspekt abstimmen.
    </p>
    <p>
      6.2.3. Eine missbräuchliche Nutzung bspw. durch das Starten der Zeit
      vor Ankunft beim Senior kann durch uns festgestellt werden (hierfür
      verwenden wir Geomatching Algorithmen) und wir dürfen Sie gemäss unserer
      Alltagshelfer Richtlinie für einen gewissen Zeitraum sperren (erstmalig 1
      Woche, bei zweitem Verstoss 4 Wochen).
    </p>
    <p>
      6.2.4. Im Rahmen Ihrer Leistungen können ggf. Schäden auftreten. Diese
      sind unverzüglich an den Senior zu melden. Jeder Alltagshelfer hat Comii
      gegenüber bestätigt, dass eine Haftpflichtversicherung existiert. Sie sind
      verpflichtet diese Schadensmeldung unverzüglich mit Ihrem Versicherer zu
      klären. Comii ist nicht Vertragspartner und haftet somit für keine dieser
      Schäden.
    </p>
    <p>
      6.2.5. Für die Fälle, in denen Ihre Leistungen aufgrund eines von Ihnen zu
      vertretenden Handelns oder Unterlassens nicht erbracht werden können (zum
      Beispiel, wenn Sie nicht zur vereinbarten Zeit am vereinbarten Ort
      anwesend sind, um eine Dienstleistung zu erbringen), sind Sie für ggf.
      entstehende Schäden verantwortlich. In jedem Fall sind Sie verpflichtet
      Ihren Auftraggeber darüber zu informieren.
    </p>
    <p>
      6.2.6. Sobald Sie Ihre Leistung erbracht haben, bestätigen Sie das 
      Arbeitsende und den Abschluss über die Statusmeldung in der App. 
      Sodann können wir in Ihrem Namen gegenüber dem Senior eine Rechnung 
      ausstellen. Direkt nach Abschluss Ihrer Hilfe kann der Senior die Kosten 
      an Sie über die Zahlungsmöglichkeit vornehmen. Der Rechnungsbetrag wird 
      dann, je nach gewählter Zahlungsart, direkt über Stripe.com an Sie – unter 
      Abzug unserer Transaktionsgebühr und der Kosten von Stripe.com –weitergeleitet. 
      Der Rechnungsbetrag wird Ihnen als Alltagshelfer direkt von Stripe.com auf Ihr 
      hinterlegtes Bankkonto überwiesen. Hierzu benötigt Stripe.com ca. 3-5 Werktage. 
      Für den Abzug unserer Transaktionsgebühr erhalten Sie eine Rechnung direkt 
      in Ihrer App. Die Höhe der Transaktionsgebühr liegt derzeit bei 9,5% des 
      Bruttoauftragswertes. Für alle anderen Rechtsprofile verweisen wir auf 
      unsere Preisliste bzw. individueller Vereinbarungen. Soweit anwendbar, 
      wird die geltende Umsatzsteuer in der Rechnung ausgewiesen. Wir behalten 
      uns vor, die Höhe der Transaktionsgebühr anzupassen, worüber wir Sie in 
      Textform im Vorfeld informieren. 
    </p>
    <p>6.3. Allgemeines</p>
    <p>
      6.3.1. Die Preise können mit Wirkung für die Zukunft in der Comii App von
      Zeit zu Zeit geändert werden.
    </p>
    <p>6.3.2. Die Kosten beinhalten keine Trinkgelder.</p>
    <p>
      6.3.3. Sämtliche erledigten Aufträge werden am Ende des Monats für Sie 
      übersichtlich zusammengestellt. Sie sind damit einverstanden, 
      dass Rechnungen nur in elektronischer Form verfügbar gemacht werden. 
      Sie sind damit einverstanden, dass die von Ihnen getätigten Umsätze 
      durch Ihren Auftraggeber über Stripe.com abgewickelt werden. Darüber 
      hinaus sind Sie damit einverstanden, dass wir unsere Transaktionsgebühr 
      im Rahmen dieses Zahlungsweges direkt zum Abzug bringen.
    </p>
    <p>
      6.3.4. Die Kosten und Gebühren beinhalten alle anwendbaren Steuern,
      inklusive Umsatzsteuer.
    </p>
    <h2>
      <a name="_Toc82173113">7. Stornierung</a>
    </h2>
    <p>
      7.1. In manchen Fällen ist es nicht möglich, eine bereits zugesagte
      Dienstleistung zu stornieren, nachdem diese angenommen wurde. In diesen
      Fällen kann Ihnen jedoch die vor der Stornierung angezeigte Stornogebühr
      in Höhe von 9,50 Euro von Comii in Rechnung gestellt werden.
    </p>
    <p>
      7.2. Comii, Senioren und Drittanbieter können Anfragen ablehnen und die
      Drittleistungen stornieren, wenn konkrete Anhaltspunkte hinsichtlich der
      Korrektheit oder Authentizität der Anfrage oder der Kontaktinformation es
      rechtfertigen.
    </p>
    <h2>
      <a name="_Toc82173114">8. Lizenz</a>
    </h2>
    <p>
      Unter der Voraussetzung der Einhaltung dieser Bedingungen, gewähren wir
      Ihnen eine persönliche, deutschlandweite, nicht exklusive, nicht
      übertragbare, widerrufbare, nicht unterlizenzierbare Lizenz, Comii
      ausschliesslich für Ihre Nutzung und für Ihren Zugang auf Ihrem Gerät zu
      installieren und zu nutzen, und die über Comii zugänglich gemachten
      Informationen zu nutzen. Sämtliche hierin nicht ausdrücklich gewährten
      Rechte bleiben Comii vorbehalten.
    </p>
    <h2>
      <a name="_Toc82173115">9. Geistiges Eigentum</a>
    </h2>
    <p>
      Wir behalten uns alle Ihnen nicht ausdrücklich mit diesen Bedingungen
      gewährten Rechte vor. Unsere App und alle über Comii erfassten, nicht
      personenbezogenen Daten (inklusive aller Rechte des geistigen Eigentums)
      sind und verbleiben bei uns und unseren Lizenzgebern. Es ist Ihnen nicht
      gestattet und Sie dürfen es auch Dritten nicht gestatten: (a) Comii oder
      Teile hiervon unterzulizenzieren, zu kopieren, zu verändern, zu
      vertreiben, zu verkaufen, weiterzuverkaufen, zu übertragen oder zu
      vermieten; (b) auf andere als gesetzlich erlaubte Weise den Quellcode von
      Comii zu verändern oder zu entwickeln oder versuchen, den Quellcode von
      Comii auszulesen; (c) Programme oder Skripts zum Zwecke des Data-Minings,
      der Indexierung, der Überwachung oder anderweitiger Datengewinnung von
      jeglichen Teilen von Comii Daten einzusetzen oder die Einsetzung zu
      veranlassen; (d) jegliche Schutzmarken oder Werke für andere Zwecke als
      für die Nutzung von Comii zu nutzen, darzustellen oder zu manipulieren;
      (e) (i) Unternehmungen, (ii) URLs, (iii) Domainnamen, (iv)
      Softwareanwendungen, -namen oder -titel oder (v) Social-Media-Profile zu
      erstellen oder erstellen zu lassen oder sich mit Nutzernamen zu
      registrieren, die Comii-Namen, -Marken, oder -Werke oder mit diesen
      verwechselbare Marken, Namen, Titel oder Werke enthalten; (f) Comii-Namen,
      -Marken, oder -Werke als Ihr Profil- oder Hintergrundbild in sozialen
      Netzwerken zu nutzen; (g) Keywords (insbesondere bei Google Ads) zu
      buchen, die Comii-Namen, -Marken, oder -Werke enthalten; oder (h) für
      Comii Namen, Marken, oder Werke in irgendeiner Weise und unabhängig vom
      Zweck, eine Registereintragung zu beantragen, zu behaupten Eigentümer zu
      sein und/oder diese zu kopieren. Dies gilt auch für verwechselbare oder im
      Wesentlichen ähnliche Marken, Namen, Titel, oder Werke, unabhängig davon,
      ob diese alleinstehend oder zusammen mit anderen Buchstaben, anderer
      Zeichensetzung, weiteren Wörtern, Symbolen, Designs, und/oder
      irgendwelchen kreativen Werken verwendet werden; aber nur insofern, als
      dass diese Handlungen über den Umfang, der Ihnen in diesen Bedingungen
      gewährten beschränkten Lizenz hinausgehen.
    </p>
    <h2>
      <a name="_Toc82173116">10. Datenschutz</a>
    </h2>
    <p>
      Wir verarbeiten Ihre personenbezogenen Daten wie in unserer
      Datenschutzerklärung näher beschrieben. Auf Comii Dienstleistungen können
      ergänzende Datenschutzbestimmungen, inklusive solcher von Dritten,
      Anwendung finden.
    </p>
    <h2>
      <a name="_Toc82173117">11. Ihre Haftung</a>
    </h2>
    <p>
      Sie haften uns gegenüber für aufgrund eines von Ihnen zu vertretenden
      Verstosses gegen diese Bedingungen oder Missbrauchs der
      Comii-Dienstleistungen, eines von Ihnen begangenen schuldhaften
      Gesetzesverstosses oder schuldhaften Verstosses gegen Rechte Dritter für
      seitens Comii erlittene Schäden entsprechend den einschlägigen
      gesetzlichen Bestimmungen. Sie haften für alle Aktivitäten, die über Ihren
      Account getätigt werden, sofern sie solche Aktivitäten gestattet oder auf
      andere Weise zu vertreten haben.
    </p>
    <h2>
      <a name="_Toc82173118">12. Verfügbarkeit</a>
    </h2>
    <p>
      Es ist uns ein besonderes Anliegen, die App möglichst ohne Beschränkungen,
      Verzögerungen oder andere Probleme zur Verfügung zu stellen. Die
      Comii-Dienstleistungen können allerdings insbesondere von technisch
      bedingten Beschränkungen, Verzögerungen und/oder anderen Problemen
      betroffen sein, auf die wir keinen Einfluss haben und die für das Internet
      und die elektronische/digitale Kommunikation charakteristisch sind. Daher
      ist für die Comii-Dienstleistungen von uns keine bestimmte
      Mindestverfügbarkeit und hinsichtlich etwaiger auftretender
      Beschränkungen, Verzögerungen oder anderen Problemen keine bestimmte
      Reaktions- oder Fehlerbehebungszeit geschuldet.
    </p>
    <h2>
      <a name="_Toc82173119">13. Haftung und Haftungsbegrenzung</a>
    </h2>
    <p>13.1. Keine Verantwortlichkeit für Drittleistungen</p>
    <p>
      Als Vermittler sind wir für die Drittleistungen nicht verantwortlich. Wir
      sind daher nicht verantwortlich für Schäden, einschliesslich Geldbussen oder
      Schadensersatzforderungen Dritter oder für sonstige Verluste, die Ihnen
      aufgrund Ihrer Leistungserbringung entstehen. Comii gibt keine Zusicherung
      und übernimmt keinerlei Garantie und/oder Gewährleistung hinsichtlich der
      Qualität, Eignung, Sicherheit und Befähigung sowie Pünktlichkeit und
      Verlässlichkeit von Drittanbietern.
    </p>
    <p>13.2. Haftungsbeschränkung für App-Dienstleistungen</p>
    <p>
      Für Schäden, die durch uns, unsere gesetzlichen Vertreter oder
      Erfüllungsgehilfen verursacht wurden, haften wir unbeschränkt (i) bei
      Verletzungen des Lebens, des Körpers oder der Gesundheit, (ii) bei
      vorsätzlicher oder grob fahrlässiger Pflichtverletzung, (iii) bei
      Verletzung von Garantieversprechen und (iv) sofern der Anwendungsbereich
      des Produkthaftungsgesetzes eröffnet ist. Bei der Verletzung wesentlicher
      Vertragspflichten, deren Erfüllung die ordnungsgemässe Durchführung des
      Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der
      Vertragspartner regelmässig vertrauen darf (sog. Kardinalpflichten) durch
      leichte Fahrlässigkeit von uns, unseren gesetzlichen Vertretern oder
      Erfüllungsgehilfen, ist die Haftung der Höhe nach auf den bei
      Vertragsschluss vorhersehbaren Schaden begrenzt, mit dessen Entstehung
      typischerweise gerechnet werden muss. Im Übrigen sind Ansprüche auf
      Schadensersatz ausgeschlossen.
    </p>
    <p>
      13.3. Haftungsbeschränkung für Comii Dienstleistungen (Rechnungserstellung,
      Zeiterfassung, etc.)
    </p>
    <p>
      Bei der Inanspruchnahme von Comii Dienstleistungen gelten die jeweiligen
      die Haftung von Comii betreffenden Regelungen. Sollte keine separate
      Vereinbarung getroffen sein oder sollte die separate Vereinbarung keine
      Regelungen zur Beschränkung der Haftung von Comii enthalten, gilt Ziffer
      14.2 entsprechend.
    </p>
    <h2>
      <a name="_Toc82173120">14. Kündigung und Beendigung</a>
    </h2>
    <p>
      14.1. Sie können die App jederzeit je nach Abomodell kündigen, indem Sie
      Ihren Vertrag/Abo kündigen, und Ihren Account schliessen und die App
      löschen.
    </p>
    <p>
      14.2. Wir können diese Bedingungen und damit Ihren Zugang zu den Comii
      APP-Dienstleistungen mit sofortiger Wirkung ausserordentlich aus wichtigem
      Grund kündigen, z.B. wenn Sie in schwerwiegender Weise gegen diese
      Bedingungen oder andere Vereinbarungen zwischen Ihnen und uns verstossen
      haben und diesen Verstoss nicht innerhalb einer angemessenen, von uns
      gesetzten Frist beheben (eine Fristsetzung ist in den Fällen von § 314
      Abs. 2 S. 2 und 3 BGB entbehrlich).
    </p>
    <p>
      14.3. Uns steht es frei, den Vertrag über die Nutzung der Comii
      App-Dienstleistungen jederzeit unter Einhaltung einer Frist von 7 Tagen in
      Textform (z.B. per E-Mail) ordentlich zu kündigen oder - ebenfalls nach
      vorheriger Ankündigung mit angemessener Frist - die App-Dienstleistungen
      insgesamt dauerhaft einzustellen oder zeitweise zu unterbrechen.
    </p>
    <p>
      14.4. Im Falle der Beendigung haben Sie sämtliche ausstehenden,
      Drittanbietern und/oder Comii geschuldeten Kosten unverzüglich zu zahlen.
      Jegliche solche Ansprüche bestehen nach Beendigung fort und wir behalten
      uns sämtliche Rechte vor, diese Zahlungen nach Beendigung einzufordern.
    </p>
    <h2>
      <a name="_Toc82173121">15. Änderungen von Bedingungen</a>
    </h2>
    <p>
      15.1. Wir können Ihnen jederzeit eine Änderung dieser Bedingungen
      vorschlagen. Dies erfolgt entweder durch eine Bekanntgabe der neuen
      Bedingungen in den Comii Mobil-App(s) und/oder Webseite(n), denen Sie dann
      unmittelbar zustimmen, oder per E-Mail. Im Fall der Bekanntgabe per E-Mail
      werden Ihnen Änderungen dieser Bedingungen spätestens einen Monat vor dem
      vorgeschlagenen Zeitpunkt ihres Wirksamwerdens angeboten. Ihre Zustimmung
      gilt als erteilt, wenn die Ablehnung nicht vor dem vorgeschlagenen
      Zeitpunkt des Wirksamwerdens der Änderungen gegenüber uns in Textform
      angezeigt wird. Wenn Sie mit den Änderungen nicht einverstanden sind,
      steht Ihnen bis zu dem vorgeschlagenen Zeitpunkt des Wirksamwerdens der
      Änderungen ein fristloses und kostenfreies Kündigungsrecht zu (siehe
      Ziffer 15.1). Wir werden Sie in der Nachricht, mit der die Änderungen
      angeboten werden, auch noch einmal besonders auf das Ablehnungsrecht, die
      Frist dafür und die Möglichkeit zur Kündigung hinweisen. Unser
      Kündigungsrecht gemäss Ziffer 15.3 bleibt unberührt.
    </p>
    <p>
      15.2. Sollte eine Bestimmung dieser Bedingungen ganz oder teilweise
      nichtig oder unwirksam sein oder werden, so wird dadurch die Wirksamkeit
      der übrigen Bestimmungen nicht berührt. An die Stelle von nicht
      einbezogenen oder unwirksamen Bestimmungen dieser Bedingungen tritt das
      Gesetz. Sofern ein solches Gesetz im jeweiligen Fall nicht zur Verfügung
      steht (Regelungslücke) oder zu einem untragbaren Ergebnis führen würde,
      werden die Parteien in Verhandlungen darüber eintreten, anstelle der nicht
      einbezogenen oder unwirksamen Bestimmung eine wirksame Regelung zu
      treffen, die ihr wirtschaftlich möglichst nahekommt.
    </p>
    <p>
      15.3. Unter der Voraussetzung, dass dies nicht zu einer mehr als
      unerheblichen Beeinträchtigung Ihrer Rechte (wie Sie Ihnen auf Basis
      dieser Bedingungen oder aufgrund geltender Gesetze zustehen) führt, können
      wir diesen Vertrag über die Nutzung der App-/Webseiten-Dienstleistungen
      oder jegliche unserer Rechte und Verpflichtungen daraus im Ganzen oder
      teilweise ohne Ihre vorherige Zustimmung von Subunternehmern und/oder
      Erfüllungsgehilfen erbringen lassen oder auf einen Dritten übertragen,
      sofern Sie durch eine solche Übertragung auf einen Dritten nicht
      wirtschaftlich schlechter gestellt werden. Im Falle der Übertragung an
      einen Dritten steht Ihnen ein fristloses Kündigungsrecht zu (vgl. Ziffer
      15.1)
    </p>
    <p>
      15.4. Diese Bedingungen, gemeinsam mit jeglicher ergänzenden Vereinbarung
      über die Gewährung der App-Dienstleistung, stellen unsere gesamte
      Vereinbarung mit Ihnen dar.
    </p>
    <h2>
      <a name="_Toc82173122">16. Verfahren zur Streitbeilegung</a>
    </h2>
    <p>
      Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von
      Streitigkeiten (sog. „OS-Plattform“) geschaffen. Die OS-Plattform dient
      als Anlaufstelle zur aussergerichtlichen Beilegung von Streitigkeiten
      betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen oder
      Online-Dienstleistungsverträgen erwachsen. Sie können die OS-Plattform
      hier abrufen: ec.europa.eu/consumers/odr.
    </p>
    <p>
      Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor
      einer Verbraucherschlichtungsstelle teilzunehmen.
    </p>
    <h2>
      <a name="_Toc82173123">17. Anwendbares Recht und Gerichtsstand</a>
    </h2>
    <p>
      Diese Bedingungen und die zwischen Ihnen und der Digital Suxess AG
      (Bereitstellung der Comii APP) auf dieser Basis zustande kommenden
      Rechtsverhältnisse unterliegen dem Recht der Bundesrepublik Deutschland.
      Gerichtsstand ist Stuttgart.
    </p>
    <p>
      Als Verbraucher mit Wohnsitz im Europäischen Wirtschaftsraum (EWR) können
      Sie Ansprüche im Zusammenhang mit diesen Bedingungen und den zwischen
      Ihnen und uns auf dieser Basis zustande kommenden Rechtsverhältnissen
      sowohl vor dem zuständigen Gericht an Ihrem Wohnsitz, als auch vor dem
      zuständigen Gericht am Unternehmenssitz von Comii geltend machen. Wenn
      Comii seine Rechte gegen Sie als Verbraucher mit Wohnsitz im Europäischen
      Wirtschaftsraum (EWR) durchsetzen möchte, können wir das nur vor den
      zuständigen Gerichten an Ihrem Wohnsitz tun.
    </p>
    <p>
      Mit der Annahme dieser Bedingungen erklären Sie Ihr Einverständnis zur
      Geltung und Einhaltung der Bedingungen.
    </p>
  </div>
</template>

<script>
export default {
    mounted(){
        window.scrollTo(0,0);
    }
};
</script>

<style lang="scss" scoped>
h1,
h2,
h3 {
  color: #2f5496;
  margin-bottom: 15px;
}
p {
  margin-bottom: 25px;
  a {
    color: #2f5496;
    text-decoration: underline;
  }
}
</style>
