<template>
  <div id="app">
    <app-header class="z-50" :colorClass="colorClass"/>
    <router-view class="router-view"/>
    <app-footer :colorClass="colorClass"/>
    
    <div class="main-wrapper">
      <cookie-law id="law" theme="dark-lime--rounded" buttonText="Akzeptieren" buttonDeclineText="Ablehnen" buttonDecline=""> 
        <div slot="message">
          Wir nutzen Cookies, um unsere Dienste zu erbringen und zu verbessern. Mit Nutzung dieser Seite akzeptieren Sie Cookies. &nbsp; <router-link id="mehrErfahren" to="/cookies-policy">Mehr erfahren</router-link>
        </div>
      </cookie-law>
    </div>
    
  </div>
</template>

<script>
import AppHeader from "./components/global/AppHeader.vue";
import AppFooter from "./components/global/AppFooter.vue";
import {mapGetters} from 'vuex';

import CookieLaw from 'vue-cookie-law';

export default {
  components:{
    CookieLaw,
    AppHeader,
    AppFooter
  },

  computed:{
    ...mapGetters(['colorClass'])
  },

  created() {

    
  }
}
</script>
<style lang="scss">
  .router-view{
    min-height:53vh;
  }
</style>

<style>
#law{
  background-color: #46464a;
  color: white;
}
#law button{
  background-color: #78599f;
  color: white;
}
#law button:hover{
  background-color: #6d469e;
}

#mehrErfahren{
  text-decoration: underline;
}
#mehrErfahren:hover{
  color: rgb(236, 236, 236);
}
</style>




