 <template>
    <div class="contact-us  page-block block-padding mb-8">

        <div class="w-full sm:w-5/6 mx-auto text-center responsive-heading3 mb-10">
            <div class="top-bar rounded bg-purple-400 responsive-top-bar h-1 mx-auto my-4"></div>
            <h2 class="text-purple-main responsive-heading1.5 font-bold mb-16">
              Kontaktieren Sie uns
            </h2>

            <div class="contacts flex flex-wrap items-center justify-evenly sm:justify-between text-gray-main mb-20">
                <div class="responsive-heading2 flex flex-col items-center sm:mr-6 mb-6">
                    <div class="flex w-11 sm:w-14 mb-2">
                        <img v-lazy="require('@/assets/images/telephone-call.png')" alt="phone call"/>
                    </div>
                    <span class="mb-2">Sprechen Sie mit einem Comii Mitarbeiter</span>
                    <span class="mb-2"><strong>07434/741-9820</strong></span>
                </div>
                <div class="responsive-heading2 flex flex-col items-center mb-6">
                    <div class="flex text-5xl sm:text-7xl">
                        <span style="color:var(--icon-gray);">&#9993;</span>
                    </div>
                    <span class="mb-2">Senden Sie uns eine E-Mail</span>
                    <span><strong>Info@Comii.de</strong></span>
                </div>
            </div>
            
            <form action="https://formsubmit.co/4ea16046e0e7b7d5428a2f9a76886751" class="text-left text-lg" method="POST">
                <h3 class="text-purple-main mb-6 text-2xl">
                    Haben Sie eine Frage?
                </h3>

                <p class="mb-6">
                    
                    <input  type="text" name="name" placeholder="Name" class="px-4 py-2 border  w-full" />
                </p>
                <p class="mb-6">
                    
                    <input  type="email" name="email" placeholder="E-Mail Adresse" class="px-4 py-2 border  w-full" />
                </p>
                <p class="mb-6">
                    
                    <input  type="text" name="stadt" placeholder="Stadt" class="px-4 py-2 border  w-full" />
                </p>
                <p class="mb-6">
                    
                    <textarea name="comments" placeholder="Geben Sie hier Ihre Nachricht ein..." rows="10" class="px-8 py-8 border  w-full resize-none" ></textarea>
                </p>
                <p class="flex justify-center sm:justify-start">
                    <input type="submit" value="Absenden" class="px-12 py-3 bg-purple-main text-white cursor-pointer hover:bg-purple-700 transition-colors">
                </p>
            </form>
            
        </div>

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>
