<template>
  <div class="comii-safe">
    <div class="w-full sm:-w-4/5 md:w-3/5 flex flex-col items-center mx-auto text-center">
      <div
        class="top-bar rounded bg-green-light-main responsive-top-bar h-1 my-4 mx-auto"
      ></div>
      <h2 class="text-green-main responsive-heading1.5 font-bold mb-6">Comii ist sicher</h2>

      <p class="mb-12 text-gray-main responsive-heading3">
        Wir bei Comii setzen auf höchste Massstäbe, um Ihre und unsere Sicherheit zu gewährleisten.
      </p>
    </div>

    <div class="flex flex-col-reverse md:flex-row mb-6">
      <div class="bg-gray-100 w-full md:w-4/6 pl-10 pt-6 pb-24">
        <ul class="w-full sm:w-3/4 green-plist">
          <h1></h1>
          <li class="plist-item">Server ausschliesslich in Deutschland</li>
          <li class="plist-item">
            Keine Weitergabe von Daten an Dritte (ausser an Alltagshelfer zur
            Erfüllung Ihrer Aufgaben)
          </li>
          <li class="plist-item">
            Ständige Überwachung unserer Datenbank und unserer Apps
          </li>
          <li class="plist-item">
            Mit dem LKA Baden-Württemberg abgestimmtes Sicherheitskonzept
          </li>
          <li class="plist-item">Echtzeitverfolgung von Alltagshelfern</li>
          <li class="plist-item">Alarmierung bei ungewöhnlichen Aktivitäten</li>

          <li class="plist-item">
            Verschlüsselte Sprachaufzeichnung während der Tätigkeit von
            Alltagsassistenten (nur bei Verdachtsmomenten)
          </li>
          <li class="plist-item">
            Identifizierung und Authentifizierung von Alltagshelfern
          </li>
        </ul>
      </div>
      <div class=" w-full md:w-2/6 bg-purple-light-main">
        <img
          v-lazy="require('@/assets/images/oldman-with-youngman.jpg')"
          class="object-cover h-full"
          alt=""
        />
      </div>
    </div>

    <div class="w-full md:w-4/5 flex flex-col items-center mx-auto text-center">
      <div
        style="background-color: #10a82f"
        class="top-bar rounded responsive-top-bar h-1 my-4 mx-auto"
      ></div>
      <h2 style="color: #10a82f" class="responsive-heading2 font-bold mb-6">
        Wir sind da, um Ihnen zu helfen
      </h2>

      <p class="mb-12 text-gray-main responsive-heading3 w-full sm:w-4/5 mx-auto">
        Brauchen Sie Hilfe bei einem Problem, die mit dem
        Betrieb der Comii App zusammenhängen?
      </p>

      <div
        class="contacts flex flex-col sm:flex-row items-center justify-between text-gray-main mb-20"
      >
        <div class="responsive-heading2 flex flex-col items-center sm:mr-24 mt-4 mb-6 sm:mb-0">
          <div class="flex w-10 md:w-12 mb-2">
            <img v-lazy="require('@/assets/images/telephone-call.png')" alt="phone call" />
          </div>
          <span class="mb-2">Sprechen Sie mit einem Comii Mitarbeiter</span>
          <span class="mb-2 font-bold"><strong>07434/741-9820</strong></span>
        </div>
        <div class="responsive-heading2 flex flex-col items-center">
          <div class="flex text-4xl md:text-6xl">
            <span style="color: var(--icon-gray)">&#9993;</span>
          </div>
          <span class="mb-2">Senden Sie uns eine E-Mail</span>
          <span class="font-bold"><strong>Info@Comii.de</strong></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
