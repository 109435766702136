e<template>
  <div class="home">
    <top-hero
      image="old-couple.jpg"
      mainTitle="Willkommen bei Comii: Klick! Klick! – Hilfe kommt!"
      :colorClass="colorClass"
      :heroWhiteGradient="true"
      :headingBorderLeft="true"
    >
      Comii besteht aus 2 Apps: Eine App für Senioren/Hilfesuchende und eine App für
      Alltagshelfer:  
    </top-hero>
    <!-- <tab-bar-view>
      This is the content of my tab view
    </tab-bar-view> -->

    <div class="home-content page-block block-padding pt-6">
      <div class="home-title w-4/5 sm:w-3/5 text-center mx-auto">
        <div
          class="top-bar rounded bg-purple-400 responsive-top-bar h-1 mx-auto my-4"
        ></div>
        <h2
          class="text-purple-main responsive-heading1.5 text-center font-bold mb-5"
        >
          Was wir tun
        </h2>

        <p class="mx-auto text-gray-main text-responsive mb-5">
          Comii bringt Alltagshelfer und Senioren/Hilfesuchende zusammen - spontan und unkompliziert!
        </p>

        <div
          class="flex items-center justify-center text-purple-main text-sm font-bold mb-12"
        >
          Mehr erfahren
          <router-link
                  to="/about"><span
            class="ml-2 flex border font-bold justify-center items-center flex-shrink-0 border-purple-600 rounded-full w-5 h-5"
            >&#10132;</span
          ></router-link>
          
        </div>
      </div>

      <div class="pictures-block flex items-stretch mb-10">
        <div class="picture-block-item">
          <img v-lazy="require('../assets/images/old-couple.jpg')" alt="old couple" />
          <p>
            <span class="text-purple-main italic"
              >Im Alltag Gutes tun - spontan und wenn Du Zeit hast!</span
            >
            <span> Egal ob als freiwillige(r) Helfer(in), ehrenamtliche(r) Helfer(in) oder selbständige(r) Alltagshelfer(in)</span>
          </p>
        </div>
        <div class="picture-block-item">
          <img v-lazy="require('@/assets/images/old-woman1.jpg')" alt="old couple" />
          <p>
            <span class="text-purple-main italic"
              >Melde jetzt dein Nachbarschaftsnetzwerk an und unterstütze
              Senioren/Hilfesuchende.</span
            >
            <span> (Comii untersützt Euch bei Organisation &amp; Ablauf)</span>
          </p>
        </div>
        <div class="picture-block-item">
          <img v-lazy="require('@/assets/images/oldman-doc.jpg')" alt="old couple" />
          <p>
            <span class="text-purple-main italic"
              >Comii macht Ihnen das Leben leichter!
            </span>
            <span>
              Als Senior oder als jemand, der Hilfe sucht, ist es nur einen
              Klick entfernt, um Hilfe spontan zu erhalten - egal an welchem Ort Sie sich befinden!</span
            >
          </p>
        </div>
      </div>
    </div> 

    <div class="purple-band bg-purple-main py-10 mb-10">
      <div class="band-wrapper block-padding page-block text-white">
        <div
          class="top-bar rounded bg-white responsive-top-bar-lg h-1 mx-auto mb-6"
        ></div>
        <h2 class="text-center responsive-heading2 font-bold mb-10">
          Aktuelle Kennzahlen
        </h2>
        <div
          class="flex flex-wrap justify-evenly mmd:justify-between smaller-text-responsive text-purple-100 w-11/12 mmd:w-10/12 mx-auto"
        >
          <div class="flex flex-col items-center mb-6 mmd:mb-0 w-48 md:mr-6">
            <span class="responsive-heading0 text-purple-300 font-bold mb-2"
              ><countTo :startVal='0' :endVal='379' :duration="20000"></countTo></span
            ><span class="text-center">Aktive Senioren bisher</span>
          </div>
          <div class="flex flex-col items-center mb-6 mmd:mb-0 w-48 md:mr-6">
            <span class="responsive-heading0 text-purple-300 font-bold mb-2"
              ><countTo :startVal='0' :endVal='264' :duration="20000"></countTo></span
            ><span class="text-center">Anzahl Aufträgen über Comii</span>
          </div>
          <div class="flex flex-col items-center mb-6 mmd:mb-0 w-48">
            <span class="responsive-heading0 text-purple-300 font-bold mb-2"
              ><countTo :startVal='0' :endVal='472' :duration="20000"></countTo></span
            ><span class="text-center"
              >Registrierte Alltagshelfer bei Comii</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="lg:w-3/5 w-full lg:mx-auto page-block mb-10">
      <div
        class="top-bar rounded bg-purple-400 responsive-top-bar h-1 mx-auto my-4"
      ></div>
      <h2
        class="text-purple-main responsive-heading2 text-center font-bold mb-5"
      >
        App herunterladen
      </h2>

      <p class="mx-5 lg:mx-auto text-gray-main text-center text-responsive">
        Hier haben Sie die Möglichkeit die Comii App herunterzuladen. Sollten
        Sie neben der Senioren/Hilfesuchenden App auch selbst Alltagshelfer
        sein, so können Sie auch beide Apps herunterladen. Auch Alltagshelfer
        könnten mal Hilfe benötigen oder Senioren Ihre Hilfe anbieten.
      </p>
    </div>

    <div class="page-block block-padding mb-10 bottom-last-block">
      <bloc-1 image="home-3.jpg">
        <template #text>
          Hier geht es zur Senioren App:
        </template>
      </bloc-1>

      <bloc-1
        image="photo1.jpg"
        :reverse="true"
        title="Für Alltagshelfer"
      >
        <template #text>
          Hier geht es zur Alltagshelfer App:
        </template>
      </bloc-1>
    </div>

    <div class="w-4/5 md:w-3/5 mx-auto page-block mb-10">
      <div
        class="top-bar rounded bg-purple-400 responsive-top-bar h-1 mx-auto my-4"
      ></div>
      <h2
        class="text-purple-main responsive-heading2 text-center font-bold mb-5"
      >
        Schauen Sie sich unsere Alltagshelfer an
      </h2>

      <p class="mx-auto text-gray-main text-responsive mb-5">
        Unsere Alltagshelfer sind freundlich, engagiert und erfahren:
      </p>
      <div
        class="flex items-center justify-center text-purple-main text-sm font-bold mb-12"
      >
        Mehr sehen
        <span
          class="ml-2 flex border font-bold justify-center items-center flex-shrink-0 border-purple-600 rounded-full w-5 h-5"
          >&#10132;</span
        >
      </div>
    </div>

    <div
      class="flex flex-wrap justify-evenly xl:justify-between page-block block-padding mb-14"
    >
      <card-1 
        image="IMG_3206.png"
        name= "Florian"
        age= "48 Jahre"
        occupation= "Alltagshelfer"
        desc= "Ich betreue seit 3 Jahren eine 92 Jahre alte Dame"
      />

      <card-1 
        image="IMG_4667.jpg"
        name= "Eme"
        age= "28 Jahre"
        occupation= "Krankenpfleger"
        desc= "In meiner Freizeit helfe ich Senioren mit Comii"
      />

      <card-1 
        image="IMG_4668.jpg"
        name= "Joy"
        age= "25 Jahre"
        occupation= "Pflegekraft"
        desc= "Mit Comii kann ich spontan helfen und gutes tun"
      />

      <card-1
        :toUrl="$route.path+'?details='+num" 
        image="iStock-1198560607_comii.jpg"
        name= "Sylvia"
        age= "42 Jahre"
        occupation= "Pflegefachkraft "
        desc= "Ich nutze meine freie Zeit um mit Comii im Alltag zu unterstützen"
      />
      
      <!-- <card-1 v-for="num in 4" :key="`card1${num}`" /> -->
    </div>
  </div>
</template>

<script>
import Bloc1 from "../components/Bloc1.vue";
import Card1 from "../components/Card1.vue";
import TopHero from "../components/global/TopHero.vue";
import countTo from 'vue-count-to';
// import TabBarView from '../components/global/TabBarView.vue'
// @ is an alias to /src

export default {
  name: "Home",

  data() {
    return {
      colorClass: "purple",
    };
  },
  components: {
    TopHero,
    Bloc1,
    Card1,
    countTo,
    // TabBarView
  },

  mounted(){
    window.scrollTo(0, 0);
  },

  created() {
    this.$store.dispatch("setColorClass", "purple");
  },
};
</script>
