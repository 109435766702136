<template>
  <div
    class="page-block block-padding"
    style="word-wrap: break-word; margin-top: 40px"
  >
   
    <p align="center">
      <a name="_Hlk81482714"></a>
      <br />
    </p>
    <p align="center">
      Comii Datenschutzerklärung
      <br />
      Eine Lösung der Digital Suxess AG
    </p>
    <p align="center">Stand vom 13. Januar 2022</p>

    <h2 style="color: #2f5496">Inhalt</h2>

    <p>
      <a
        href="#_Toc92965226"
      >
        I. Einführung
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965227"
      >
        II. Übersicht
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965228"
      >
        A. Geltungsbereich
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965229"
      >
        B. Verantwortliche Stelle, Datenschutzbeauftragter &amp; Datentransfer
        3
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965230"
      >
        III. Datenerhebung und Datennutzung
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965231"
      >
        A. Von uns erhobene Daten
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965232"
      >
        1. Daten, die von Nutzern bereitgestellt werden
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965233"
      >
        2. Daten die während der Nutzung unserer Dienstleistungen generiert
        werden
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965234"
      >
        3. Daten aus anderen Quellen
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965235"
      >
        B. Wie wir personenbezogene Daten verwenden
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965236"
      >
        1. Um unsere Dienstleistung bereitzustellen
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965237"
      >
        2. Um Sicherheit und Schutz zu gewährleisten
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965238"
      >
        3. Kundensupport
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965239"
      >
        4. Forschung und Entwicklung
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965240"
      >
        5. Ermöglichen der Kommunikation zwischen Nutzern
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965241"
      >
        6. Nicht-marketingbezogene Kommunikation
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965242"
      >
        7. Rechtliche Verfahren und Bestimmungen
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965243"
      >
        C. Cookies und Drittanbieter-Technologien
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965244"
      >
        D. Weitergabe und Offenlegung von Daten
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965245"
      >
        1. An andere Nutzer
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965246"
      >
        2. An die Öffentlichkeit
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965247"
      >
        3. An den Inhaber des Comii Kontos
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965248"
      >
        4. An Dienstleister und Geschäftspartner von Comii 
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965249"
      >
        5. Aus rechtlichen Gründen oder im Streitfall
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965250"
      >
        6. Mit Einwilligung
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965251"
      >
        E. Speichern und Löschen von Daten
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965252"
      >
        IV. Wahlmöglichkeiten und Transparenz
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965253"
      >
        1. Datenschutzeinstellungen
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965254"
      >
        2. Bewertungsseiten in den Apps
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965255"
      >
        3. Anfragen zu personenbezogenen Daten
      </a>
    </p>
    <p>
      <a
        href="#_Toc92965256"
      >
        V. Aktualisierungen der Datenschutzerklärung
      </a>
    </p>
    <h1>
      <a name="_Toc92965226">I. Einführung</a>
    </h1>
    <p>
      Wenn du Comii nutzt, vertraust du uns verschiedene deiner
      personenbezogenen Daten an. Wir verpflichten uns, dieses Vertrauen zu
      wahren und schränken die Datenweitergabe auf ein notwendiges Minimum ein.
      Daher möchten wir dir zunächst helfen, unsere Datenschutzpraktiken zu
      verstehen. Wir empfehlen dir, diesen Text sorgfältig zu lesen. Gerne
      beantworten wir Fragen zum Datenschutz unter der Mailadresse:
      <a href="mailto:Datenschutz@Comii.de">Datenschutz@Comii.de</a>. Hier
      kannst Du auch alle Deine Daten, die wir über Dich gespeichert haben,
      abrufen.
    </p>
    <p>
      In dieser Datenschutzerklärung beschreiben wir, welche personenbezogenen
      Daten wir erheben, wie diese genutzt und weitergegeben werden und welche
      Optionen du im Hinblick auf diese Daten hast.
    </p>
    <h1>
      <a name="_Toc92965227">II. Übersicht</a>
    </h1>
    <h2>
      <a name="_Toc92965228">A. Geltungsbereich</a>
    </h2>
    <p>
      Diese Datenschutzerklärung gilt für die Nutzer der Dienstleistungen der
      Comii App (Comii Alltagshelfer App und Comii Senioren App), bereitgestellt
      durch die Digital Suxess AG, Stuttgart, vertreten durch den Vorstand
      Florian Buschbacher.
    </p>
    <p>
      Diese Datenschutzerklärung beschreibt die Art, den Umfang und den Zweck,
      wie Comii und seine verbundenen Unternehmen personenbezogene Daten
      erheben, verarbeiten und verwenden. Diese Datenschutzerklärung gilt für
      alle Nutzer unserer Apps, Websites, Funktionen oder anderen
      Dienstleistungen. Diese Datenschutzerklärung gilt insbesondere für:
    </p>
    <p>
      § Senioren: Einzelpersonen, die Alltagshilfen anfordern und nutzen,
      einschliesslich der Nutzer die über eine andere Person (bspw. Angehörige)
      bestellt wurden
    </p>
    <p>
      § Alltagshelfer: Einzelpersonen (Kleinunternehmer im Sinne des §19 UStG)
      oder Gewerbetreibende oder Angestellte von Gewerbetreibenden (mobile
      Hilfsdienste oder Pflegedienste)
    </p>
    <p>
      Diese Datenschutzerklärung regelt die weitere Erfassung personenbezogener
      Daten durch Comii in Zusammenhang mit den Dienstleistungen von Comii. Wir
      können beispielsweise folgende Daten erfassen: Kontaktinformationen von
      Einzelpersonen oder Gewerbetreibenden die Comii nutzen oder
      Dienstleistungen über Comii bereitstellen sowie personenbezogene Daten von
      Personen, die Anträge zur Registrierung beginnen, aber nicht beenden;
      andere personenbezogene Daten im Zusammenhang mit unseren
      Mapping-Technologien, Geolokationsverfahren, und Analysefunktionen.
    </p>
    <p>
      Alle Einzelpersonen und Gewerbetreibende sowie deren Angestellte, die
      Gegenstand dieser Datenschutzerklärung sind, werden in dieser
      Datenschutzerklärung als „Nutzer“ oder „Senioren“ oder als „Alltagshelfer“
      bezeichnet.
    </p>
    <h2>
      <a name="_Toc92965229">
        B. Verantwortliche Stelle, Datenschutzbeauftragter &amp; Datentransfer
      </a>
    </h2>
    <p>
      Die verantwortliche Stelle für den Datenschutz und der
      Informationssicherheit ist die Digital Suxess AG, Löffelstr. 44, 70579
      Stuttgart als Betreiberin der APP „Comii“. Ihr Ansprechpartner für alle in
      diesem Zusammenhang stehenden Fragen (Erhebung, Nutzung, Auswertung und
      Weitergabe von Daten) ist unser Datenschutzbeauftragter, erreichbar unter:
    </p>
    <p>Datenschutz@Comii.de</p>
    <p>
      Comii ist global tätig und verarbeitet personenbezogene Daten weltweit.
      Wir übertragen solche Daten möglicherweise auch in andere Länder als in
      das Land, in dem unsere Nutzer leben oder die Dienste von Comii nutzen.
      Dies dient der Erfüllung von Nutzervereinbarungen wie unseren
      Nutzungsbedingungen oder basiert auf der vorherigen Zustimmung der Nutzer,
      Beschlüssen zur Angemessenheit für die entsprechenden Länder oder
      sonstigen nach geltendem Recht verfügbaren Übertragungsmechanismen wie den
      Standardvertragsklauseln.
    </p>
    <p>
      Fragen, Kommentare und Beschwerden in Bezug auf die Datennutzung von Comii
      können über Datenschutz@comii.de eingereicht werden.
    </p>
    <h1>
      <a name="_Toc92965230">III. Datenerhebung und Datennutzung</a>
    </h1>
    <h2>
      <a name="_Toc92965231">A. Von uns erhobene Daten</a>
    </h2>
    <p>Comii erfasst folgende personenbezogene Daten:</p>
    <p>
      § Daten, die Comii von Nutzern bereitgestellt werden, z. B. bei der
      Registrierung und Kontoerstellung
    </p>
    <p>
      § Daten, die während der Verwendung unserer Dienstleistungen entstehen,
      wie Standort, angefragte Dienstleistung, App-Nutzung und Gerätedaten
    </p>
    <p>
      § Daten, die aus anderen Quellen stammen, wie etwa von anderen Nutzern
      oder Kontoinhabern, Geschäftspartnern, Anbietern von
      Bonitätsdienstleistungen, Versicherungsanbietern und Regierungsbehörden
    </p>
    <p>Die folgenden Daten werden von oder im Auftrag von Comii erhoben:</p>
    <h3>
      <a name="_Toc92965232">1. Daten, die von Nutzern bereitgestellt werden</a>
    </h3>
    <p>Hierzu gehören:</p>
    <p>
      <u>Benutzerprofil:</u>
      Wir erheben Daten, wenn Nutzer (Senioren und Alltagshelfer) ihre Comii
      Konten erstellen oder aktualisieren. Dazu gehören deren Name,
      E-Mail-Adresse, Telefonnummer, Anmeldename und Passwort, Adresse,
      Profilbild, Zahlungs- oder Bankdaten (einschliesslich zugehöriger Daten zur
      Zahlungsverifizierung), Personalausweis, polizeiliches Führungszeugnis,
      Steuernummer, Geburtsdatum, Führerschein und andere amtliche
      Ausweisdokumente (die möglicherweise die Dokumentennummer sowie das
      Geburtsdatum, Geschlecht und Foto enthalten). Dies umfasst auch
      Versicherungsinformationen oder Kontaktinformationen für Notfälle,
      Nutzereinstellungen und Nachweise über die Gesundheit oder Eignung zur
      Erbringung von Dienstleistungen über die Comii Apps.
    </p>
    <p>
      Dies umfasst auch das Geschlecht und/oder den Beruf bzw. der jeweiligen
      Qualifikation, wenn dies für bestimmte Dienstleistungen oder Programme von
      Comii erforderlich ist, die es bspw. Frauen ermöglichen, Dienstleistungen
      von/für Frauen anzubieten oder zu erhalten.
    </p>
    <p>
      Wir können die von den Nutzern übermittelten Fotos zur Verifizierung ihrer
      Identität, beispielsweise durch Technologien zur Gesichtserkennung,
      verwenden. Weitere Informationen findest du im Abschnitt „Wie wir
      personenbezogene Daten verwenden“.
    </p>
    <p>
      Zuverlässigkeitsüberprüfung und Identitätsprüfung (Senioren,
      Alltagshelfer). Dies kann Informationen wie Personalausweis, Führerschein,
      bekannte Aliase und frühere Adressen sowie ggf. eine Arbeitserlaubnis
      umfassen. Diese Informationen können durch einen autorisierten
      Dienstleister im Auftrag von Comii erhoben werden.
    </p>
    <p>
      <u>Nutzerinhalte:</u>
      Wir erheben die von den Nutzern übermittelten Daten, wenn sie den
      Kundensupport von Comii kontaktieren (bspw. Videokonferenz-Tools),
      Bewertungen für Senioren oder Alltagshelfer abgeben oder Comii anderweitig
      kontaktieren. Dies kann Feedback, Fotos oder andere Aufnahmen umfassen,
      die von Nutzern gesammelt wurden, einschliesslich Audio- oder
      Videoaufnahmen, die von Nutzern im Zusammenhang mit dem Kundensupport
      eingereicht wurden. Hierzu gehören auch Metadaten zu anderen Methoden,
      über die Du mit Comii kommunizierst.
    </p>
    <p>
      <u>Bewegungsinformationen:</u>
      Wir erfassen Informationen, wo (Geoposition) Sie sich gerade befinden,
      einschliesslich der Zeitstempel und Datumsangaben. Wir erfassen diese
      Informationen, um Ihnen jederzeit entsprechende Hilfe zukommen zu lassen
      bzw. den Alltagshelfern entsprechende Aufträge zu vermitteln. Dabei halten
      wir uns an die API-Services-Nutzerdatenrichtlinie von Google-Maps bzw.
      Apple Maps.
    </p>
    <h3>
      <a name="_Toc92965233">
        2. Daten die während der Nutzung unserer Dienstleistungen generiert
        werden
      </a>
    </h3>
    <p>Dazu gehören:</p>
    <p>
      <u>Standortdaten</u>
      (von Senioren und Alltagshelfern): Wir erfassen die genauen oder
      ungefähren Standortdaten, unter anderem, um die Nachverfolgung und
      Zeiterfassung von Fahrten und Dienstleistungen zu ermöglichen,
      Sicherheitsfunktionen bereitzustellen, Betrug zu verhindern und die
      gesetzlichen Anforderungen zu erfüllen. Comii erhebt diese Daten, wenn die
      Comii App auf dem Mobilgerät im Vordergrund (App geöffnet und auf dem
      Bildschirm) oder im Hintergrund (App geöffnet, aber nicht auf dem
      Bildschirm) läuft.
    </p>
    <p>
      Wir erfassen diese Daten von den mobilen Geräten der Nutzer, sofern sie
      uns dies ermöglichen. (Siehe „Auswahl und Transparenz“ unten, um zu
      erfahren, wie Senioren und Alltagshelfer die Standortdatenerfassung
      aktivieren können). Comii sammelt solche Daten ab dem Zeitpunkt der
      Anfrage bis zu deren Abschluss (und kann je nach Betriebssystem des Geräts
      über ein Symbol oder eine Benachrichtigung auf deinem Mobilgerät auf eine
      solche Erfassung hinweisen) und jedes Mal, wenn die App auf ihrem
      Mobilgerät im Vordergrund läuft (d.h. wenn die App geöffnet ist und auf
      dem Bildschirm angezeigt wird).
    </p>
    <p>
      <u>Transaktionsinformationen:</u>
      Wir erfassen Transaktionsinformationen im Zusammenhang mit der Nutzung
      unserer Dienstleistungen. Hierzu gehören u. a. folgende Daten: Art der
      angeforderten oder bereitgestellten Dienstleistungen, Bestelldetails,
      Zahlungsinformationen (wie Namen und Standort sowie Betrag der
      Transaktion), Datum und Uhrzeit der Erbringung der Dienstleistung,
      berechneter Betrag.
    </p>
    <p>
      <u>Nutzungsdaten:</u>
      Wir erheben Daten dazu, wie Nutzer mit unseren Dienstleistungen
      interagieren. Hierzu gehören Daten wie das Datum und die Uhrzeit des
      Zugriffs, App-Funktionen oder aufgerufene Seiten, App-Abstürze und andere
      Systemaktivitäten sowie der Browsertyp. Wir können auch Daten in Bezug auf
      die Websites oder Dienste von Dritten sammeln, die der Nutzer vor der
      Interaktion mit unseren Dienstleistungen verwendet hat.
    </p>
    <p>
      In manchen Fällen erheben wir diese Daten, indem wir Cookies, Pixel, Tags
      und ähnliche Technologien verwenden, die eindeutige Identifikatoren
      erstellen und beibehalten. Weitere Informationen über diese Technologien
      findest du in unserer Cookie-Richtlinie unter Comii.de.
    </p>
    <p>
      <u>Gerätedaten:</u>
      Wir können Daten über die Geräte erheben, mit denen auf unsere Dienste
      zugegriffen wird. Hierzu gehören: Hardware-Modelle, IP-Adresse des Geräts,
      Betriebssysteme und -versionen, Software, bevorzugte Sprachen, eindeutige
      Geräte-Identifikatoren, Werbe-Identifikatoren sowie Daten zur
      Gerätebewegung und zum Mobilnetzwerk.
    </p>
    <p>
      <u>Kommunikationsdaten:</u>
      Wir ermöglichen den Nutzern, miteinander und mit Comii über die mobilen
      Apps und Websites von Comii zu kommunizieren. Beispielsweise ermöglichen
      wir einander anzurufen, Textnachrichten zu schreiben oder weitere Dateien
      zu senden. Um diese Serviceleistung bereitstellen zu können, erhebt Comii
      einige Daten zu den Anrufen, Nachrichten oder anderen Kommunikationen wie
      etwa Datum und Uhrzeit der Kommunikation und deren Inhalt. Comii kann
      diese Daten auch für Kundenserviceleistungen (u. a. zur Beilegung von
      Streitfällen zwischen Nutzern), für Sicherheitszwecke, zur Verbesserung
      unserer Dienstleistungen und Funktionen und für Analysen verwenden.
    </p>
    <h3>
      <a name="_Toc92965234">3. Daten aus anderen Quellen</a>
    </h3>
    <p>Diese Daten umfassen:</p>
    <p>
      § Nutzer, die an unseren Empfehlungsprogrammen teilnehmen. Wenn
      beispielsweise ein Nutzer eine andere Person empfiehlt, erhalten wir die
      personenbezogenen Daten der empfohlenen Person von diesem Nutzer.
    </p>
    <p>
      § Inhaber von Comii Konten, die Dienstleistungen für oder im Auftrag von
      anderen Nutzern bestellen, oder die es solchen Nutzern ermöglichen, über
      ihre Konten Dienstleistungen zu bestellen oder zu nutzen.
    </p>
    <p>
      § Nutzer oder andere Dritte, die Informationen in Verbindung mit
      Forderungen oder Streitigkeiten bereitstellen.
    </p>
    <p>
      § Comii Geschäftspartner, über die Nutzer ihr Comii Konto erstellen oder
      darauf zugreifen, wie Zahlungsdienstleister, Social Media-Services oder
      Apps oder Websites, die die API von Comii nutzen oder deren API Comii
      nutzt.
    </p>
    <p>
      § Anbieter, die uns dabei helfen, die Identität der Nutzer, ihre
      Hintergrundinformationen und ihre Arbeitseignung zu verifizieren, oder die
      Nutzer im Zusammenhang mit Sanktionen, Geldwäschebekämpfung oder
      Kundenwissensanforderungen überprüfen.
    </p>
    <p>§ Anbieter von Versicherungen.</p>
    <p>§ Öffentlich verfügbare Quellen.</p>
    <p>
      § Marketing-Dienstleister oder Daten-Reseller, deren Daten Comii für
      Marketing oder Forschung verwendet.
    </p>
    <p>
      § Strafverfolgungsbehörden, Gesundheitsbehörden und andere
      Regierungsbehörden.
    </p>
    <p>
      Comii kann die über diese Quellen erhobenen Daten mit anderen sich in
      seinem Besitz befindlichen Daten kombinieren.
    </p>
    <h2>
      <a name="_Toc92965235">B. Wie wir personenbezogene Daten verwenden</a>
    </h2>
    <p>
      Comii nutzt personenbezogene Daten, um zuverlässige und komfortable
      Alltagsbegleitung zu ermöglichen. Wir verwenden diese Daten auch:
    </p>
    <p>§ zur Förderung der Sicherheit unserer Nutzer und Dienstleistungen</p>
    <p>§ für den Kundensupport</p>
    <p>§ zur Forschung und Entwicklung</p>
    <p>§ zur Ermöglichung der Kommunikation zwischen Nutzern</p>
    <p>
      § zur Übermittlung marketingbezogener und nicht-marketingbezogener
      Kommunikationen
    </p>
    <p>§ in Verbindung mit Gerichtsverfahren</p>
    <p>
      Personenbezogene Daten der Nutzer werden von Comii nicht zum Zwecke des
      Direktmarketings an Dritte verkauft oder weitergegeben, es sei denn, der
      Nutzer hat dem zugestimmt.
    </p>
    <p>Wir verwenden personenbezogene Daten, die wir erheben:</p>
    <h3>
      <a name="_Toc92965236">1. Um unsere Dienstleistung bereitzustellen</a>
    </h3>
    <p>
      Comii verwendet Daten, um seine Dienste bereitzustellen, zu
      personalisieren, zu pflegen und zu verbessern.
    </p>
    <p>Dies umfasst die Verwendung von Daten für folgende Zwecke:</p>
    <p>§ Das Erstellen/Aktualisieren von Konten</p>
    <p>
      § Die Verarbeitung von vermittelten Anfragen, Dienstleistungen, Zahlungen
      und Transaktionsgebühren
    </p>
    <p>
      § Das Nachverfolgen und der Prüfung angenommener und erledigter Aufträge
      durch ein sogenanntes Geomatching Verfahren. Hierdurch ist es uns möglich
      zu prüfen, ob die Standortdaten von Senior und Alltagshelfer zum
      Abrechnungszeitpunkt bzw. der Erbringung der Dienstleistung auch am
      gleichen oder ähnlichen Standort sind bzw. waren.
    </p>
    <p>§ Die Zeiterfassung und die Berechnung des Rechnungsbetrages</p>
    <p>
      § Die Durchführung von Vorgängen, die zur Aufrechterhaltung unserer
      Dienstleistungen erforderlich sind. Dies umfasst das Beheben von Software-
      und Betriebsfehlern, das Durchführen von Datenanalysen, Tests und
      Forschungen sowie die Überwachung und Analyse von Nutzungs- und
      Aktivitätstrends.
    </p>
    <p>
      Comii führt die oben genannten Aktivitäten, einschliesslich der Erfassung
      und Nutzung von Standortdaten für die Zwecke dieser Aktivitäten, mit der
      Begründung durch, dass sie zur Erfüllung unserer Verpflichtungen gegenüber
      Nutzern gemäss unseren Nutzungsbedingungen oder anderen Vereinbarungen mit
      den Nutzern erforderlich sind.
    </p>
    <h3>
      <a name="_Toc92965237">2. Sicherheit und Schutz</a>
    </h3>
    <p>
      Wir nutzen personenbezogene Daten, um die Sicherheit, den Schutz und die
      Integrität unserer Dienstleistungen und Nutzer aufrechtzuerhalten. Dazu
      gehören:
    </p>
    <p>
      Die Verifizierung der Identität der Nutzer und der Eignung
      Alltagsbegleitung anzubieten. Dies geschieht u. a., soweit gesetzlich
      zulässig, in Form von Zuverlässigkeitsüberprüfungen, die gewährleisten,
      dass unsere Dienstleistungen nicht von Personen genutzt werden, die ein
      Sicherheitsrisiko darstellen.
    </p>
    <p>
      In bestimmten Regionen umfasst dies die Funktion ID-Überprüfung in
      Echtzeit, mit der Comii Alltagshelfer App dazu auffordert, ein Selfie von
      sich zu übermitteln, bevor sie online gehen, um sicherzustellen, dass der
      Alltagshelfer, der die App nutzt, mit dem bei uns hinterlegten Profil
      übereinstimmt. Soweit gesetzlich zulässig, umfasst dies auch die
      Durchführung einer Gesichtsüberprüfung anhand von Fotos, die von Nutzern
      eingereicht oder aus öffentlichen Datenbanken gesammelt wurden, um
      Identitätsdiebstal zu verhindern oder die Identität von Nutzern zu
      verifizieren.
    </p>
    <p>
      Verwenden von Geräte-, Standort-, Profil-, Nutzungs- und anderen Daten, um
      Betrug zu verhindern, zu erkennen und zu bekämpfen. Dies umfasst die
      Identifizierung betrügerischer Konten oder der betrügerischen Nutzung
      unserer Dienste, die Verhinderung der Nutzung unserer Dienste durch nicht
      autorisierte Alltagshelfer, die Verifizierung von Nutzeridentitäten im
      Zusammenhang mit bestimmten Zahlungsmitteln und die Verhinderung und
      Bekämpfung von unbefugtem Zugriff auf Nutzerkonten.
    </p>
    <p>
      Verwenden von Nutzer-Bewertungen, gemeldeten Vorfällen, und anderem
      Feedback, um das Einhalten unserer Community-Richtlinien zu fördern und
      als Grundlage für das Deaktivieren der Konten von Nutzern mit schlechten
      Bewertungen oder solchen, die anderweitig gegen diese Richtlinien in
      bestimmten Ländern verstossen haben.
    </p>
    <p>
      Comii führt die oben genannten Aktivitäten mit der Begründung aus, dass
      sie zur Erfüllung unserer Verpflichtungen gegenüber Nutzern gemäss unseren
      Nutzungsbedingungen oder anderen Vereinbarungen mit Nutzern erforderlich
      sind.
    </p>
    <h3>
      <a name="_Toc92965238">3. Kundensupport</a>
    </h3>
    <p>
      Comii verwendet die erfassten Informationen (einschliesslich
      Anrufaufzeichnungen), um den Kundensupport bereitzustellen, einschliesslich
      der Untersuchung und Bearbeitung von Anliegen der Nutzer und der
      Überwachung und Verbesserung unserer Reaktionen und Prozesse im
      Kundensupport.
    </p>
    <p>
      Comii führt die oben genannten Aktivitäten mit der Begründung aus, dass
      sie zur Erfüllung unserer Verpflichtungen gegenüber Nutzern gemäss unseren
      Nutzungsbedingungen oder anderen Vereinbarungen mit Nutzern erforderlich
      sind.
    </p>
    <h3>
      <a name="_Toc92965239">4. Forschung und Entwicklung</a>
    </h3>
    <p>
      Wir nutzen personenbezogene Daten möglicherweise zum Testen, Erforschen,
      Analysieren sowie für die Produktentwicklung und maschinelles Lernen, um
      die Nutzererfahrung zu verbessern. Dies hilft uns dabei, unsere Dienste
      angenehmer und benutzerfreundlicher zu gestalten, die Sicherheit unserer
      Dienste zu verbessern und neue Dienste und Funktionen wie bspw. InApp
      Käufe zu entwickeln.
    </p>
    <p>
      Comii führt die oben genannten Aktivitäten mit der Begründung durch, dass
      sie erforderlich sind, um unsere Verpflichtungen gegenüber Nutzern gemäss
      unseren Nutzungsbedingungen oder anderen Vereinbarungen mit Nutzern zur
      Verbesserung unserer bestehenden Dienste und Funktionen zu erfüllen, oder
      um die berechtigten Interessen von Comii an der Entwicklung neuer Dienste
      und Funktionen zu wahren.
    </p>
    <h3>
      <a name="_Toc92965240">
        5. Ermöglichen der Kommunikation zwischen Nutzern
      </a>
    </h3>
    <p>
      Ein Alltagshelfer kann z. B. einem Senior eine Textnachricht schicken oder
      ihn anrufen, um den Abholort und/oder die Dienstleistung in Art und Dauer
      zu bestätigen; ein Senior kann einen Alltagshelfer kontaktieren, um bspw.
      Details der Dienstleistung zu klären.
    </p>
    <p>
      Comii führt die oben genannten Aktivitäten mit der Begründung aus, dass
      sie zur Erfüllung unserer Verpflichtungen gegenüber Nutzern gemäss unseren
      Nutzungsbedingungen oder anderen Vereinbarungen mit Nutzern erforderlich
      sind.
    </p>
    <h3>
      <a name="_Toc92965241">6. Nicht-marketingbezogene Kommunikation</a>
    </h3>
    <p>
      Comii kann persönliche Daten zum Generieren und Bereitstellen von Belegen
      für Nutzer verwenden; um sie über Änderungen unserer AGB, Dienstleistungen
      oder Richtlinien zu informieren; oder um andere Kommunikationen zu
      versenden, die nicht zu Zwecken der Vermarktung der Dienstleistungen oder
      Produkte/Lösungen von Comii oder seinen Partnern dienen.
    </p>
    <p>
      Comii führt die oben genannten Aktivitäten mit der Begründung durch, dass
      sie erforderlich sind, um unsere Verpflichtungen gegenüber Nutzern gemäss
      unseren Nutzungsbedingungen oder anderen Vereinbarungen mit Nutzern zu
      erfüllen, oder um die berechtigten Interessen von Comii und seinen Nutzern
      zu wahren, indem Comii die Nutzer über Ereignisse informiert, die sich auf
      die Nutzung der Dienste von Comii auswirken können.
    </p>
    <h3>
      <a name="_Toc92965242">7. Rechtliche Verfahren und Bestimmungen</a>
    </h3>
    <p>
      Wir können personenbezogene Daten verwenden, um Ansprüche oder
      Rechtsstreitigkeiten in Bezug auf die Nutzung der Dienstleistungen von
      Comii zu untersuchen oder zu bearbeiten, um die Anforderungen der
      geltenden Gesetze, Vorschriften oder Betriebserlaubnisse oder
      -vereinbarungen zu erfüllen, oder gemäss Gerichtsverfahren oder
      behördlichen Anfragen, einschliesslich Anfragen von
      Strafverfolgungsbehörden zu erfüllen.
    </p>
    <p>
      Comii führt die oben genannten Aktivitäten mit der Begründung aus, dass
      sie für die berechtigten Interessen von Comii an der Untersuchung und
      Beantwortung von Ansprüchen und Streitigkeiten im Zusammenhang mit der
      Nutzung der Dienste und Funktionen von Comii und/oder zur Einhaltung der
      geltenden gesetzlichen Vorschriften erforderlich sind.
    </p>
    <h2>
      <a name="_Toc92965243">C. Cookies und Drittanbieter-Technologien</a>
    </h2>
    <p>
      Comii und seine Partner benutzen Cookies und andere
      Identifikationstechnologien in unseren Apps, Websites, E-Mails und
      Online-Werbungen für die in dieser Datenschutzerklärung und der
      Cookie-Richtlinie von Comii beschriebenen Zwecke.
    </p>
    <p>
      Cookies sind kleine Textdateien, die von Websites, Apps, Online-Medien und
      Werbeanzeigen auf Browsern oder Geräten gespeichert werden. Comii nutzt
      Cookies und ähnliche Technologien u. a. zu folgenden Zwecken:
    </p>
    <p>§ Authentifizierung von Nutzern</p>
    <p>§ Speichern von Nutzerpräferenzen und -einstellungen</p>
    <p>
      § Bestimmen der Beliebtheit von Inhalten (bspw. Kategorien, Alltagshelfer)
    </p>
    <p>§ Erfassen und Bewerten der Effektivität von Werbekampagnen</p>
    <p>
      Analysieren der Seitenaufrufe und Trends und Entwickeln eines allgemeinen
      Verständnisses des Online-Verhaltens und der Interessen der Personen, die
      mit unseren Dienstleistungen interagieren
    </p>
    <p>
      Wir können auch anderen erlauben, uns Aufrufquoten und
      Analysedienstleistungen zur Verfügung zu stellen, in unserem Namen Werbung
      im Internet zu schalten und die Performance solcher Werbungen zu verfolgen
      und darüber zu berichten. Diese Einheiten dürfen Cookies, Web-Beacons und
      andere Technologien nutzen, um das Gerät zu identifizieren, mit dem
      Besucher unsere Website besuchen, und auch wenn sie andere Websites und
      Dienste besuchen.
    </p>
    <p>
      Weitere Informationen zur Nutzung von Cookies und anderen in diesem
      Abschnitt beschriebenen Technologien findest du in unserer
      Cookie-Richtlinie.
    </p>
    <h2>
      <a name="_Toc92965244">D. Weitergabe und Offenlegung von Daten</a>
    </h2>
    <p>
      Einige der Dienstleistungen und Funktionen von Comii erfordern, dass wir
      personenbezogene Daten an andere Nutzer weitergeben, oder dies auf den
      Wunsch eines Nutzers hin tun. Wir können solche Daten auch mit unseren
      verbundenen Unternehmen, Tochtergesellschaften und Partnern teilen, wenn
      dies aus rechtlichen Gründen oder in Zusammenhang mit Ansprüchen oder
      Streitigkeiten erforderlich ist.
    </p>
    <p>Comii kann personenbezogene Daten wie folgt weitergeben:</p>
    <h3>
      <a name="_Toc92965245">1. An andere Nutzer</a>
    </h3>
    <p>Dies umfasst die Weitergabe folgender Daten:</p>
    <p>
      Vorname, Name, Adresse, aktueller Aufenthaltsort, Alter, Geschlecht,
      angeforderte Dienstleistung und Rufnummer sowie ein Bild des Nutzers
      (Senior oder Alltagshelfer) oder der Zugriff auf das jeweilige Profil mit
      entsprechenden Informationen die zur Vertragserfüllung notwendig werden.
    </p>
    <h3>
      <a name="_Toc92965246">2. An die Öffentlichkeit</a>
    </h3>
    <p>
      Fragen oder Kommentare von Nutzern, die über öffentliche Foren wie die
      Blogs oder Social-Media-Seiten von Comii eingesendet werden, können für
      die Öffentlichkeit einsehbar sein. Hierzu gehören auch personenbezogene
      Daten, die in den eingesandten Fragen oder Kommentaren enthalten sind.
    </p>
    <h3>
      <a name="_Toc92965247">3. An den Inhaber des Comii Kontos</a>
    </h3>
    <p>
      Wenn ein Nutzer eine Dienstleistung über ein Konto, das einer anderen
      Person gehört (Mutter/Vater), bestellt, können wir die Informationen
      einschliesslich der Standortdaten, mit dem Besitzer dieses Kontos teilen.
      Dies geschieht z. B. in folgenden Fällen:
    </p>
    <p>
      Die Oma beauftragt Ihren Enkel für Sie ein Rezept in der Apotheke
      abzuholen, wobei sich der Sohn nicht in der Nähe befindet. Hier kann er für
      seine Oma über Comii einen Alltagshelfer für seine Oma beauftragen.
    </p>
    <h3>
      <a name="_Toc92965248">
        4. An Dienstleister und Geschäftspartner von Comii
      </a>
    </h3>
    <p>
      Comii übermittelt personenbezogene Daten an Dienstleister, Berater,
      Kooperationspartner und andere Geschäftspartner. Hierzu gehören:
    </p>
    <p>§ APP Nutzer wie Alltagshelfer und Senioren</p>
    <p>§ IT-Dienstleister</p>
    <p>§ Anbieter von Zuverlässigkeitsüberprüfungen und Identitätsprüfungen</p>
    <p>§ Zahlungsdienstleister</p>
    <p>§ Anbieter von Cloud-Services</p>
    <p>
      § Apple &amp; Google, in Verbindung mit der Verwendung von
      Kartendienstleistungen, die in den Comii Apps eingebunden sind (siehe
      Informationen über die Erhebung und Verwendung von Daten findest in der
      Datenschutzrichtlinie von Google und Apple)
    </p>
    <p>
      § Forschungspartner, darunter solche, die Umfragen oder Forschungsprojekte
      zusammen mit Comii oder in dessen Namen durchführen
    </p>
    <p>
      § Anbieter, die Comii bei der Verbesserung der Sicherheit seiner Apps
      unterstützen
    </p>
    <p>
      § Berater, Anwälte, Wirtschaftsprüfer und andere professionelle
      Dienstleister
    </p>
    <p>§ Versicherungs- und Finanzierungspartner</p>
    <h3>
      <a name="_Toc92965249">5. Aus rechtlichen Gründen oder im Streitfall</a>
    </h3>
    <p>
      Comii kann die personenbezogenen Daten der
      Nutzer weitergeben, wenn dies unserer Ansicht nach aufgrund von geltendem
      Recht (bspw. Steuerbehörden), Bestimmungen, Betriebslizenzen oder
      -vereinbarungen, Gerichtsverfahren oder behördlichen Anfragen erforderlich
      ist oder wenn die Offenlegung aufgrund von Sicherheits- oder sonstigen
      Bedenken angemessen ist.
    </p>
    <p>
      Hierzu gehört die Weitergabe von personenbezogenen Daten an
      Strafverfolgungsbeamte, Vertreter der Gesundheitsbehörden oder andere
      Vertreter der Regierung oder anderen Dritten, wenn dies notwendig ist, um
      unsere Nutzungsbedingungen, Nutzervereinbarungen oder andere Richtlinien
      durchzusetzen; Rechte oder Eigentum von Comii oder die Rechte, die
      Sicherheit oder das Eigentum anderer zu schützen oder wenn es zu
      Ansprüchen oder Streitigkeiten in Zusammenhang mit der Nutzung unserer
      Dienstleistungen kommt. Bei Streitigkeiten über die Verwendung der
      Kreditkarte einer anderen Person können wir gesetzlich verpflichtet sein,
      die personenbezogenen Daten an den betreffenden Kreditkarteninhaber
      weiterzugeben, einschliesslich Informationen zur Fahrt oder Bestellung.
    </p>
    <p>
      Hierzu gehört auch die Weitergabe von personenbezogenen Daten an Dritte in
      Zusammenhang mit oder während Verhandlungen über eine Fusion, den Verkauf
      von Vermögenswerten des Unternehmens, eine Konsolidierung oder
      Restrukturierung, Finanzierung oder den Erwerb des gesamten oder eines
      Teils unseres Geschäfts durch ein anderes Unternehmen.
    </p>
    <h3>
      <a name="_Toc92965250">6. Mit Einwilligung</a>
    </h3>
    <p>
      Comii kann die personenbezogenen Daten eines Nutzers auch auf eine andere
      als die in dieser Datenschutzerklärung beschriebene Weise weitergeben,
      wenn wir den Nutzer über die Weitergabe informieren und dieser darin
      einwilligt.
    </p>
    <h2>
      <a name="_Toc92965251">E. Speichern und Löschen von Daten</a>
    </h2>
    <p>
      Comii speichert Nutzerdaten so lange, wie es für die oben beschriebenen
      Zwecke erforderlich ist.
    </p>
    <p>
      Nutzer können jederzeit die Löschung ihrer Konten beantragen. Aufgrund
      gesetzlicher oder behördlicher Anforderungen oder aus den in dieser
      Richtlinie aufgeführten Gründen kann Comii Nutzerdaten auch nach einer
      Löschanfrage speichern.
    </p>
    <p>
      Comii speichert Nutzerdaten so lange, wie es für die oben beschriebenen
      Zwecke erforderlich ist. Das heisst, je nach Nutzerkategorie, auf die sich
      die Daten beziehen, der Datenart und dem Zweck der Datenerhebung speichern
      wir verschiedene Datenkategorien für unterschiedlich lange Zeiträume.
      Diese Zeiträume richten sich nach Steuerrecht, Handelsrecht und weiteren
      Gesetzen und sind somit längstenfalls 10 Jahre gespeichert.
    </p>
    <p>
      Nutzer können die Löschung ihres Kontos jederzeit über das Menü
      „Einstellungen“ &gt; „Datenschutz“ in der Comii App von Comii anfordern.
    </p>
    <p>
      Nach einer Anfrage zur Kontolöschung löscht Comii das Konto und die Daten
      des Nutzers, es sei denn, sie müssen aufgrund gesetzlicher oder
      behördlicher Anforderungen zum Zwecke der Sicherheit und Betrugsbekämpfung
      oder aufgrund eines Problems im Zusammenhang mit dem Konto des Nutzers
      gespeichert werden. Dies ist z. B. bei noch ausstehenden Beträgen oder
      nicht beigelegten Ansprüchen oder Streitigkeiten der Fall. Da wir im
      Hinblick auf gesetzliche und behördliche Vorschriften gebunden sind,
      bedeutet das grundsätzlich, dass wir das Konto und die Daten des Nutzers
      nach einem Löschantrag mindestens 7 Jahre lang speichern.
    </p>
    <h1>
      <a name="_Toc92965252">IV. Wahlmöglichkeiten und Transparenz</a>
    </h1>
    <p>
      Comii ermöglicht es den Nutzern unter anderem durch die folgenden
      Optionen, auf die von Comii erhobenen Daten zuzugreifen und diese zu
      prüfen:
    </p>
    <ul>
      <li>Datenschutzeinstellungen</li>
      <li>Zugriffsberechtigungen der Geräte</li>
      <li>Bewertungsseiten in den Apps</li>
      <li>Marketing-Entscheidungen</li>
    </ul>
    <p>
      Comii ermöglicht es den Nutzern zudem, Zugang zu oder Kopien ihrer Daten
      anzufordern, Änderungen oder Aktualisierungen an ihren Konten vorzunehmen,
      ihre Konten zu löschen oder die Verarbeitung der personenbezogenen Daten
      durch Comii einzuschränken.
    </p>
    <h3>
      <a name="_Toc92965253">1. Datenschutzeinstellungen</a>
    </h3>
    <p>
      Über das Menü „Einstellungen“ &gt; „Datenschutz“ in der Comii App können
      Senioren und Alltagshelfer ihre Präferenzen in Bezug auf die Erfassung und
      Weitergabe von Standortdaten, die Weitergabe von Notfalldaten und
      Benachrichtigungen festlegen oder aktualisieren.
    </p>
    <p>Erhebung von Standortdaten (Senioren und Alltagshelfer)</p>
    <p>
      Senioren und Alltagshelfer) können die Erfassung von Standortdaten durch
      Comii in den Geräteeinstellungen ihrer Mobilgeräte unter „Einstellungen“
      &gt; „Datenschutz“ &gt; „Standort“ aktivieren oder deaktivieren.
    </p>
    <p>Live-Standort teilen (Senioren &amp; Alltagshelfer)</p>
    <p>
      Nutzer können das Teilen ihrer Echtzeit-Standortdaten durch Comii in den
      Geräteeinstellungen ihrer Mobilgeräte unter „Einstellungen“ &gt;
      „Datenschutz“ &gt; „Standort“ aktivieren oder deaktivieren.
    </p>
    <p>Übermittlung deiner Daten im Notfall</p>
    <p>
      Nutzer können es Comii ermöglichen, Echtzeit-Standortdaten von ihren
      mobilen Geräten mit Notruf-, Feuerwehr- und Rettungsdiensten zu teilen.
      Solche Daten umfassen möglicherweise den ungefähren Standort zum Zeitpunkt
      des Anrufs bei den Notdiensten; den Namen und die Telefonnummer des
      Nutzers; die Adresse des Seniors sowie den Namen des Alltagshelfers.
    </p>
    <h3>
      <a name="_Toc92965254">2. Bewertungsseiten in den Apps</a>
    </h3>
    <p>
      Nach jeder Dienstleistung können Senioren und Alltagshelfer einander mit 1
      bis 5 Sternen bewerten.
    </p>
    <p>
      Durch dieses beidseitige System müssen alle Verantwortung für ihr
      Verhalten übernehmen. Diese Verantwortung trägt zu einer respektvollen,
      sicheren Umgebung bei.
    </p>
    <h3>
      <a name="_Toc92965255">3. Anfragen zu personenbezogenen Daten</a>
    </h3>
    <p>
      Comii bietet den Nutzern verschiedene Möglichkeiten, mehr über den Umgang
      mit ihren personenbezogenen Daten zu erfahren, Fragen dazu zu stellen,
      Kommentare abzugeben und die Daten zu prüfen.
    </p>
    <p>
      Zugriff auf Daten: Nutzer können über die Comii Apps auf Daten,
      einschliesslich ihres Profils und des Dienstleistungsverlaufes, zugreifen.
      Ausserdem können die Nutzer hier den Zugriff auf Ihre Daten beantragen.
    </p>
    <p>
      Erhalt von Daten: Nutzer können eine Kopie ihrer personenbezogenen Daten
      herunterladen oder anfordern.
    </p>
    <p>
      Ändern oder Aktualisieren von Daten: Nutzer können den in ihrem Konto
      hinterlegten Namen, die Telefonnummer, die E-Mail-Adresse, das
      Zahlungsmittel und das Foto über das Menü „Einstellungen“ der Comii App
      ändern. Ausserdem können die Nutzer hier die Aktualisierung oder Korrektur
      ihrer Daten beantragen.
    </p>
    <p>
      Löschen von Daten: Nutzer können die Löschung ihres Kontos jederzeit über
      das Menü „Einstellungen“ &gt; „Datenschutz“ in der Comii App oder über die
      Website von Comii anfordern
    </p>
    <p>
      Einwände, Einschränkungen und Beschwerden: Nutzer können verlangen, dass
      wir sämtliche oder einen Teil ihrer personenbezogenen Daten nicht länger
      verwenden oder dass wir die Nutzung ihrer Daten einschränken. Dies umfasst
      auch den Widerspruch gegen die Verwendung personenbezogener Daten, die auf
      den berechtigten Interessen von Comii basieren. Comii kann diese Daten
      nach einem solchen Widerspruch oder einer solchen Anfrage auch weiterhin
      in dem vom Gesetz erforderlichen oder erlaubten Umfang verwenden.
    </p>
    <h1>
      <a name="_Toc92965256">V. Aktualisierungen der Datenschutzerklärung</a>
    </h1>
    <p>
      Wir dürfen diese Datenschutzerklärung gelegentlich aktualisieren. Sollten
      wir signifikante Änderungen vornehmen, werden wir die Nutzer im Vorfeld
      durch die Comii Apps oder auf anderem Wege, wie etwa E-Mail, über die
      Änderungen informieren. Wir empfehlen den Nutzern, diese
      Datenschutzerklärung regelmässig im Hinblick auf die jüngsten Informationen
      zu unseren Datenschutzpraktiken zu überprüfen.
    </p>
    <p>
      Die Nutzung unserer Dienste nach einer Aktualisierung stellt eine
      Einwilligung in die aktualisierte Datenschutzerklärung in dem vom Gesetz
      erlaubten Masse dar.
    </p>
  </div>
</template>

<script>
export default {

     mounted(){
        window.scrollTo(0,0);
    }
};
</script>

<style lang="scss" scoped>
h1,
h2,
h3 {
  color: #2f5496;
  margin-bottom: 15px;
}

p {
  margin-bottom: 25px;

  a {
    color: #2f5496;
    text-decoration: underline;
  }
}
</style>
