<template>
  <div>
    <bloc-21
      class="mb-8"
      title="Für Nachbarschaftsnetzwerke & ehrenamtliche Helfer: Preise, Vergütung und Rechnungsstellung"
      no-content
      full-width
    >
    </bloc-21>
    <divided-table
      :colorClass="colorClass"
      :firstRowBackgroundColored="false"
      class="text-xl"
    >
      <tr class="heading">
        <th></th>
        <th>
          <div class>
            <h1>Comii Alltagshelfer App</h1>
          </div>
        </th>
      </tr>
      <tr>
        <td class="text-orange-main">APP-Gebühren</td>
        <td class="text-orange-main">Kostenlos</td>
      </tr>
      <tr>
        <td>Funktionen der APP</td>
        <td>
          <p class="font-semibold">Umfassende Funktionen</p>
          <div class="-ml-7">
            <left-arrow-text
              text="Automatische Suche nach Aufträgen von hilfesuchenden Personen in deiner Region."
              class="py-2"
              no-predefined-font-size
              color-class-not-on-text
              :color-class="colorClass"
            />
            <left-arrow-text
              text="Aufträge mit einem Klick annehmen"
              class="py-2"
              no-predefined-font-size
              color-class-not-on-text
              :color-class="colorClass"
            />
            <left-arrow-text
              text="Persönliches Profil mit den Aktivitäten, die du anbietest"
              class="py-2"
              no-predefined-font-size
              color-class-not-on-text
              :color-class="colorClass"
            />
            <left-arrow-text
              text="Transparente Übersicht über Bestellungen und Rechnungen in deinem Profil"
              class="py-2"
              no-predefined-font-size
              color-class-not-on-text
              :color-class="colorClass"
            />
            <left-arrow-text
              text="Transparenter Zeitstempel (Stundenerfassung)"
              class="py-2"
              no-predefined-font-size
              color-class-not-on-text
              :color-class="colorClass"
            />
            <left-arrow-text
              text="Möglichkeit, von einer ehrenamtlichen Tätigkeit zu einer beruflichen Tätigkeit in der App zu wechseln"
              class="py-2"
              no-predefined-font-size
              color-class-not-on-text
              :color-class="colorClass"
            />
          </div>
        </td>
      </tr>
    </divided-table>
  </div>
</template>

<script>
import Bloc21 from "../../components/Bloc21.vue";
import DividedTable from "../../components/DividedTable.vue";
import LeftArrowText from "../../components/utils/LeftArrowText.vue";

export default {
  components: {
    Bloc21,
    DividedTable,
    LeftArrowText,
  },
  computed: {
    colorClass() {
      return this.$store.getters.colorClass;
    }
  }
};
</script>

<style>
</style>
