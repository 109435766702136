<template>
  <div
    class="flex flex-col lg:px-2 px-0 page-block"
    :class="{ 'md:flex-row-reverse': reverse, 'md:flex-row': !reverse }"
  >
    <div class="sidebar">
      <img v-lazy="require(`@/assets/images/${image}`)" alt="" />
    </div>
    <div class="asid">
      <div class="lg:pt-8 pt-2 lg:px-12 px-5">
        <div
          v-if="!noTopHLine"
          :style="`border-bottom-color: var(${currentLightColor})`"
          class="tiret responsive-top-bar flex mx-auto md:mx-0"
        ></div>
        <div class="bloc1-text">
          <h1
            class="font-bold responsive-heading3 text-center mx-auto md:mx-0 md:text-left"
            :style="`color: var(${currentColor})`"
          >
            {{ title }}
          </h1>
          <p class="lg:pb-0 pb-5 text-responsive">
            <slot name="text"></slot>
          </p>
        </div>
        <div v-if="!noStoreButtons" class="imgs flex justify-start">
          <a href="https://play.google.com/store/apps/details?id=com.comii.senior&gl=DE" class="img1"
            ><img v-lazy="require('@/assets/images/play-store.png')"
          /></a>
          <a href="https://apps.apple.com/de/app/comii-senior/id1584937013" class="img2"
            ><img v-lazy="require('@/assets/images/app-store.png')"
          /></a>
        </div>
        <!-- <div v-if="!noActionButton" class="her flex justify-start">
          <a
            href="#"
            class="text-responsive"
            :class="`text-main-${colorClass} border-main-${colorClass}
            hover:text-white hover:bg-main-${colorClass} transition-colors
          
          `"
            :style="``"
            >{{ action }}</a
          >
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bloc1",

  props: {
    image: { type: String, default: "photo1.jpg" },
    title: {
      type: String,
      default: "Für Senioren und Menschen, die Hilfe suchen",
    },
    // action: { type: String, default: "App herunterladen" },

    reverse: { type: Boolean, default: false },

    noActionButton: { type: Boolean, default: false },
    noStoreButtons: { type: Boolean, default: false },
    noTopHLine: { type: Boolean, default: false },
  },
  computed: {
    currentColor() {
      switch (this.$store.getters.colorClass) {
        case "green":
          return "--main-green";
        case "purple":
          return "--main-purple";
        default:
          return "--main-orange";
      }
    },

    colorClass() {
      return this.$store.getters.colorClass;
    },
    currentLightColor() {
      switch (this.$store.getters.colorClass) {
        case "green":
          return "--main-green-light";
        case "purple":
          return "--main-purple-light";
        default:
          return "--main-orange-light";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
