<template>
	<div>
		<template v-if="!detailsId">
			<bloc-21
				title="Unsere Alltagshelfer"
				content="Unsere Alltagshelfer wollen unterstützen und Gutes tun!."
			>
			</bloc-21>

			<div> 
				<div class="help-cards lg:mx-0 mx-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3">
					<!-- <card-1 :toUrl="$route.path+'?details='+num" style="width: 100%" v-for="num in 12" :key="`card${num}`"  /> -->
					<card-1 
						:toUrl="$route.path+'?name='+data[0].name+'&age='+data[0].age+'&warum='+data[0].warum+'&occupation='+data[0].occupation+'&wie='+data[0].wie+'&was='+data[0].was+'&image='+data[0].image+'&details='+num" 
						style="width: 100%"
						image="IMG_3206.png"
						name= "Florian"
						age= "48 Jahre"
						occupation= "Alltagshelfer"
						desc= "Ich betreue seit 3 Jahren eine 92 Jahre alte Dame" 
					/>
					<card-1 
						:toUrl="$route.path+'?name='+data[1].name+'&age='+data[1].age+'&warum='+data[1].warum+'&occupation='+data[1].occupation+'&wie='+data[1].wie+'&was='+data[1].was+'&image='+data[1].image+'&details='+num" 
						style="width: 100%"
						image="IMG_4667.jpg"
						name= "Eme"
						age= "28 Jahre"
						occupation= "Krankenpfleger"
						desc= "In meiner Freizeit helfe ich Senioren mit Comii" 
					/>
					<card-1 
						:toUrl="$route.path+'?name='+data[2].name+'&age='+data[2].age+'&warum='+data[2].warum+'&occupation='+data[2].occupation+'&wie='+data[2].wie+'&was='+data[2].was+'&image='+data[2].image+'&details='+num" 
						style="width: 100%"
						image="IMG_4668.jpg"
						name= "Joy"
						age= "25 Jahre"
						occupation= "Pflegekraft"
						desc= "Mit Comii kann ich spontan helfen und gutes tun" 
					/>
					<card-1 
						:toUrl="$route.path+'?name='+data[3].name+'&age='+data[3].age+'&warum='+data[3].warum+'&occupation='+data[3].occupation+'&wie='+data[3].wie+'&was='+data[3].was+'&image='+data[3].image+'&details='+num"
						image="iStock-1198560607_comii.jpg" 
						style="width: 100%"
						name= "Sylvia"
						age= "42 Jahre"
						occupation= "Pflegefachkraft "
						desc= "Ich nutze meine freie Zeit, um mit Comii im Alltag zu unterstützen" 
					/>
				</div>
			</div>
		</template> 

		<template v-else>
			<our-help-details :detailsData="sampleHelpDetails"/>
		</template>
	</div>
</template>

<script>
import Bloc21 from '../../components/Bloc21.vue';
import Card1 from "../../components/Card1.vue";
import OurHelpDetails from "../for_seniors/our-help-details.vue";
import {data} from "../../data/data"

export default {
	components: {
		Bloc21,
		Card1,
		OurHelpDetails
	},

	data() {
    return {
      data,
      sampleHelpDetails: {
				image:"old-black-man.png",
				age:'65 Ans',
				name:"Vincent",
				occupation:'Hausmann'
				}
    };
  },

	computed: {
		detailsId() {
			return this.$route.query.details
		}
	}
};
</script>

<style>
</style>
