<template>
	<div>
		<div>
			<!-- For Everyday helpers first block after 
				the main top image and tab bar view
			-->
			<bloc-21
				title="Aufgaben einer Alltagshelfers"
				content="Die Aufgaben eines Alltagshelfers könnnen sehr unterschiedlich sein."
			/>

			<!-- Article gallery block, the block contains 
				3 aritcles per line and is displayed on 2 
				lines in the mockup 
			-->
			<div>
				<div class="grid lg:grid-cols-3 grid-cols-1 gap-3">
					<img v-lazy="require('../../assets/images/Leistungen1.png')" alt="" />
					<img v-lazy="require('../../assets/images/Leistungen2.png')" alt="" />
					<img v-lazy="require('../../assets/images/Leistungen3.png')" alt="" />
					<img v-lazy="require('../../assets/images/Leistungen4.png')" alt="" />
					<img v-lazy="require('../../assets/images/Leistungen5.png')" alt="" />
					<img v-lazy="require('../../assets/images/Leistungen6.png')" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Bloc21 from "../../components/Bloc21.vue";


export default {
  name: "Tasks",
  components: {
    Bloc21,
  }
};
</script>

<style></style>
