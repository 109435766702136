<template>
  <div class="tabbar-view">
    <div class="tab-names mb-6 py-4 page-block hidden sm:block">
      <div
        class="tabs font-bold flex justify-start gap-2 md:gap-4 lg:gap-6 xl:gap-8 items-center block-padding page-block text-gray-400"
      >
        <button
          v-for="(tab, index) in tabs"
          :key="`tab${index}`"
          @click="selectTab(index)"
          class="focus:outline-none font-bold cursor-pointer text-center transition-all tab-name text-xs sm:text-sm lg:text-base px-2"
          :style="{
            color: `${
              $route.params.subheading === tab.param
                ? `var(${currentColor})`
                : ''
            }`,
          }"
        >
          <router-link
            :to="`${baseUrl}/${tab.param}`"
            class="w-full h-full block text-center"
            v-html="tab.text"
          ></router-link>
        </button>
      </div>
    </div>

    <!-- submenu equivalent to tabbar names for small screens ------------------>

    <aside
      @click.stop="() => {}"
      :class="`block sm:hidden side-menu sub-menu bg-${colorClass}-main ${
        showSubMenu? 'show' : ''
      }`"
      class="w-full h-full"
    >
      <div class="content-wrapper">
        <div
          :class="`delete-ribon bg-${colorClass}-light-main text-white text-2xl pt-2 pr-4 flex justify-end`"
        >
          <button
            @click="toggleMenu(false,false)"
            class="border flex items-center justify-center border-white rounded-full w-8 h-8 p-2"
          >
            &#10005;
          </button>
        </div>

        <div
          :class="`back-ribon bg-${colorClass}-light-main text-${colorClass}-main text-2xl py-4 flex pl-20`"
        >
          <button @click="toggleMenu(true,false)" class="flex items-center">
            <span class="pr-3 text-2xl align-middle">&#8249;</span>
            <span>{{ sectionTitle }}</span>
          </button>
        </div>
        <nav class="menu-items flex flex-col">
          <router-link
            v-for="(tab, index) in tabs"
            :key="`submenulink${index}`"
            :to="`${baseUrl}/${tab.param}`"
            @click.native="toggleMenu(false,false)"
            :class="`menu-item text-white `"
          >
            <span v-html="tab.text"></span>
          </router-link>
        </nav>
      </div>
    </aside>

    <!-------------------------------------------------------------------------->

    <div
      class="block-padding page-block mt-2 sm:w-auto mx-auto sm:mx-0 
      smaller-text-responsive current-path italic text-lg w-full
      page-block block-padding mb-6 text-breadcrump-dark"
    >
      <router-link to="/">{{ basePath }}</router-link>
      <span v-if="secondaryBasePath" class="font-bold"> | </span>
      <span
        v-if="secondaryBasePath"
        :style="{ color: activeTabIndex < 0 ? `var(${currentColor})` : '' }"
        v-html="secondaryBasePath"
      ></span>
      <span v-if="activeTabIndex >= 0" class="font-bold"> | </span>
      <span
        
        :style="{ color: `var(${currentColor})` }"
        v-html="activeTabIndex >= 0?tabs[activeTabIndex].text:sectionTitle"
      ></span>
      
    </div>

    <main :class="`view-main-content ${hasBlockPadding?'page-block block-padding':''}`">
      <slot></slot>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // showSubMenu:false
    };
  },
  props: {
    //[tabs] is an array which contains names of tabs to be displayed
    tabs: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => [
        { text: "Wie funktioniert Comii?", param: "" },
        { text: "Aufgaben eines Alltagshelfers", param: "" },
        { text: "Wie man Alltagshelfer wird", param: "" },
        { text: "Bezahlung, Preise&nbsp;& Rechnungsstellung", param: "" },
        { text: "Unsere Alltagshelfer", param: "" },
        { text: "FAQs", param: "" },
      ],
    },

    //the base url without the '/' at the end
    baseUrl: { type: String, default: "" },

    //[basePath] represents the current navigation path
    basePath: {
      type: String,
      default: "Startseite",
    },

    secondaryBasePath: {
      type: String,
      default: "",
    },

    //[colorClass] is the current color theme; values are 'orange', 'purple' or 'green'
    colorClass: {
      type: String,
      default: "orange",
    },

    sectionTitle: {
      type: String,
      default: "Über Comii",
    },

    hasBlockPadding:{
      type:Boolean,
      default:true
    }
  },

  computed: {
    showSubMenu() {
      return this.$store.getters.showSubMenu;
    },
    //[currentColor] is computed property which determines the hex value of the color depending on the color class
    currentColor() {
      switch (this.colorClass) {
        case "green":
          return "--main-green";
        case "purple":
          return "--main-purple";
        default:
          return "--main-orange";
      }
    },
    activeTabIndex() {
      return this.tabs.findIndex(
        (tab) => tab.param === this.$route.params.subheading
      );
    },

    canShowSubMenu(){

      return this.$route.path.split('/').length>1;
    }
  },

  methods: {
    selectTab(index) {
      this.$emit("onTabSelected", index);
    },

    // toggleSubMenu(hideMainMenu = false, forceValue) {
    //   this.$store.dispatch(
    //     "toggleSubMenu",
    //     forceValue === true || forceValue === false ? forceValue : undefined
    //   );

    //   if (this.$store.getters.showSubMenu === false && hideMainMenu === true) {
    //     console.log("removing main menu");
    //     this.$store.dispatch("toggleMainMenu");
    //   }
    // },

    toggleMenu(mainMenuValue,subMenuValue) {

      this.$store.dispatch('toggleMainMenu',mainMenuValue);

      this.$store.dispatch('toggleSubMenu',subMenuValue);
    

    },

  },
  created() {
    // console.log("this is the acitve", this.activeTabIndex);
  },
};
</script>

<style lang="scss" scoped></style>
