<template>
  <div>
    <bloc-21
        title="Übersicht über Preise und Leistungen"
        content="Die Tarife und Preise bei Comii sind transparent und von Senioren erarbeitet."
    ></bloc-21>
	<divided-table 
		:colorClass="colorClass" 
		:firstRowBackgroundColored="false"
		class="text-xl"
	>
		<tr class="heading">
			<th></th>
			<th>
				<div class>
					<h1>Comii Alltagshelfer APP</h1>
				</div>
			</th>
		</tr>
		<tr>
			<td class="text-orange-main">
				APP-Gebühren
			</td>
			<td class="text-orange-main">
				APP-Gebühren
			</td>
		</tr>
		<tr>
			<td>
				Dein Gehalt
			</td>
			<td>
				Alltagshelfer erhalten <span class="font-semibold text-orange-main">25 €/Stunde</span> (Alle Kategorien)
			</td>
		</tr>
		<tr>
			<td>
				Zeitintervall für die Rechnungsstellung
			</td>
			<td>
				Du wirst pauschal für die ersten 30 Minuten vergütet, danach alle 15 Minuten. Die Ankunfts- und Abfahrtszeiten beim Senior/Hilfesuchenden sind in der Pauschale enthalten.
			</td>
		</tr>
		<tr>
			<td>
				Transaktionsgebühren
			</td>
			<td>
				Pro ausgeführtem Auftrag erheben wir 9,5% Transaktionskosten, um die Kosten des Zahlungsdienstleisters Stripe sowie weitere technische Dienstleistungen zu kompensieren.
			</td>
		</tr>
		<tr>
			<td>
				Auszahlung deines Umsatzes
			</td>
			<td>
				Nachdem der Senior die Bezahlung über die App veranlasst hat, wird Dein Umsatz abzüglich der Transaktionskosten direkt auf dein Stripe Konto überwiesen.
			</td>
		</tr>
		<tr>
			<td>
				Comii Funktionsumfang
			</td>
			<td>
				<p class="font-semibold">Volle Funktionalität</p>
				<div class="-ml-7">
					<left-arrow-text
						text="Automatische Suche nach Aufträgen von hilfesuchenden Personen in deiner Region."
						class="py-2"
						no-predefined-font-size
						color-class-not-on-text
						:color-class="colorClass"
					/>
					<left-arrow-text
						text="Akzeptiere Aufträge mit einem Klick."
						class="py-2"
						no-predefined-font-size
						color-class-not-on-text
						:color-class="colorClass"
					/>
					<left-arrow-text
						text="Persönliches Profil mit den Aktivitäten, die du vorschlägst."
						class="py-2"
						no-predefined-font-size
						color-class-not-on-text
						:color-class="colorClass"
					/>
					<left-arrow-text
						text="Transparente Übersicht über Aufträge und Rechnungen in deinem Profil."
						class="py-2"
						no-predefined-font-size
						color-class-not-on-text
						:color-class="colorClass"
					/>
					<left-arrow-text
						text="<span class='font-semibold'>25€</span>/Stunde Einfache und bargeldlose Überweisung."
						class="py-2"
						no-predefined-font-size
						color-class-not-on-text
						:color-class="colorClass"
					/>
					<left-arrow-text
						text="Einfache und bargeldlose Überweisung."
						class="py-2"
						no-predefined-font-size
						color-class-not-on-text
						:color-class="colorClass"
					/>
					<left-arrow-text
						text="Transparenter Zeitstempel (Erfassung der Stunden)"
						class="py-2"
						no-predefined-font-size
						color-class-not-on-text
						:color-class="colorClass"
					/>
				</div>
			</td>
		</tr>
	</divided-table>
  </div>
</template>

<script>
import Bloc21 from '../../components/Bloc21.vue';
import DividedTable from '../../components/DividedTable.vue';
import LeftArrowText from '../../components/utils/LeftArrowText.vue';
export default {
	components: {
		Bloc21,
		DividedTable,
		LeftArrowText
	},
	props: {
		colorClass: {
			type: String,
			default: "orange"
		}
	}
};
</script>

<style>
</style>
