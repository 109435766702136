<template>
  <div class="about-comii">
    <top-hero
      :colorClass="$store.getters.colorClass"
      :image="tabsImages[currentTabIndex] || 'doctor-2-old-women.jpg'"
      :isMainHero="false"
      :mainTitle="`${!currentTab ? 'Über Comii' : currentTab.text}`"
      :heroDarkGradient="true"
      heroBottomPadding="20px"
      :imagePosition="'0 87%'"
      :isWhiteTitle="true"
    />

    <tab-bar-view
      :tabs="tabs"
      @onTabSelected="onTabChanged"
      :basePath="'Startseite'"
      secondary-base-path="Über Comii"
      baseUrl="/about" 
      :colorClass="$store.getters.colorClass"
      :hasBlockPadding="false" 
      section-title=""
    >
      <about-comii v-show="!currentSubHeading" />
      <comii-idea v-show="currentSubHeading === 'comii-idea'" />
      <media-library v-show="currentSubHeading === 'media-library'" />
      <beirat v-show="currentSubHeading === 'beirat'" />
      <partner v-show="currentSubHeading === 'partner'" />
      <hurry v-show="currentSubHeading === 'hurry'" />
      <contact-us v-show="currentSubHeading === 'contact-us'"/>
    </tab-bar-view>
  </div>
</template>

<script>
import TabBarView from "../../components/global/TabBarView.vue";
import TopHero from "../../components/global/TopHero.vue";
import ComiiIdea from "./comii_idea.vue";
import AboutComii from "./about_comii.vue";
import MediaLibrary from "./media_library.vue";
import Beirat from "./beirat.vue";
import Hurry from "./hurry.vue";
import ContactUs from './contact_us.vue';
import Partner from './partner.vue';
export default {
  data() {
    return {
      tabs: [
        { text: "Comii die Idee", param: "comii-idea" },
        { text: "Mediathek", param: "media-library" },
        { text: "Beirat", param: "beirat" },
        { text: "Partner", param: "partner" },
        { text: "Presse", param: "hurry" },
        { text: "Kontaktieren Sie uns", param: "contact-us" },
      ],
      tabsImages: [
        "idea-bulb.jpg",
        "media-library.jpg",
        "partners-greeting.jpg",
        "man-wife-sitting.jpg",
        'man-woman-with-phone.jpg'
      ],
      //   currentTab: null,
    };
  },
  components: {
    TopHero,
    TabBarView,
    AboutComii,
    ComiiIdea,
    MediaLibrary,
    Beirat,
    Hurry,
    ContactUs,
    Partner,
  },

  methods: {
    onTabChanged(index) {
      this.currentTab = index;
    },
  },

  computed: {
    currentSubHeading() {
      return this.$route.params.subheading;
    },

    currentTab() {
      return this.tabs.find((t) => t.param === this.currentSubHeading);
    },

    currentTabIndex() {
      const index = this.tabs.findIndex(
        (t) => t.param === this.currentSubHeading
      );
      return index >= 0 ? index : null;
    },
  },

  created() {
    this.$store.dispatch("setColorClass", "purple");
  },
};
</script>

<style lang="scss" scoped></style>
