<template>
  <div class="faqs">
    <div class="w-full sm:w-4/5 md:w-3/5 mx-auto text-center mb-12">
      <div
        class="top-bar rounded w-16 h-1 my-4 mx-auto"
        :class="`bg-${colorClass}-light-main`"
      ></div>
      <h2
        :class="`text-${colorClass}-main`"
        class="responsive-heading1.5 font-bold mb-6"
      >
        Willkommen bei unseren häufig gestellten Fragen
      </h2>

      <p class="mb-12 text-gray-main responsive-heading3">
        Comii - Fragen &amp; Antworten<br />
        <span class="lg:mx-0 mx-5" :class="`text-${colorClass}-main`"
          >Unser Motto: Comii - Selbständig bleiben!</span
        >
      </p>
    </div>

    <div class="faqs-proper lg:mx-0 mx-5 text-gray-main text-responsive">
      <div v-for="(item, index) in faqContent" :key="index" class="mb-6">
        <template v-if="!item.list">
          <div
            v-if="item.question"
            v-html="item.question"
            class="comii-faq-question mb-1"
          ></div>
          <div v-if="item.multipleAnswers">
            <p
              v-for="(answer, answer_idx) in item.answer"
              :key="answer_idx"
              v-html="answer"
              :class="{
                'pt-2': answer_idx > 0,
              }"
            ></p>
          </div>
          <p v-else-if="item.answer" v-html="item.answer"></p>
        </template>
        <template v-else>
          <ul v-if="item.listType === 'ulist'" :class="`${colorClass}-plist`">
            <li
              v-for="(list_item, list_idx) in item.list_items"
              :key="list_idx"
              class="mb-4"
            >
              <p v-html="list_item.answer"></p>
            </li>
          </ul>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    faqContent: {
      type: Array,
      default() {
        return [
          {
            question: "Kann ich mir meinen Helfer persönlich aussuchen?",
            answer: `Ja! Sobald Sie eine Anfrage gestartet haben, zeigt Comii Ihnen 
            sämtliche Helfer an, die zu Ihrer Anfrage passen. Durch das Klicken auf
            das Profilbild der Alltagshelfer, können Sie ein Kurzprofil des Helfers ansehen.
            Ebenso haben Sie die Möglichkeit ganz bestimmte Alltagshelfer oder alle verfügbaren 
            Alltagshelfer anzufragen.`,
          },
          {
            question: "Kann ich meinen Helfer bewerten?",
            answer: `Ja, jeder Helfer wünscht sich ein Feedback nach Auftragsabschluss anhand
        einer Sterneskala von 1-5 (5 Sterne = sehr gut).`,
          },
          {
            question: "Wieviel kostet mich der Einsatz eines Helfers?",
            // multipleAnswers: true,
            answer:
              `Für jede Dienstleistung erhält der Helfer eine Aufwandsentschädigung.
              Sie zahlen pro Stunde maximal 25 Euro. Die ersten 30 Minuten (12,50 Euro) 
              werden pauschal berechnet, danach in einer Frequenz von jeweils 15 Minuten. 
              Nach Beendingung sehen Sie Ihre Rechnung sofort im Profil.`,
          },
             {
            question: "Welche Tätigkeiten übernimmt der Helfer?",
            answer:  `Unsere Helfer unterstützen Sie bei Erledigungen aller Art. Hierfür
            haben wir Kategorien entwickelt aus denen Sie einen Service auswählen können.
            Ebenso haben Sie die Möglichkeit ein Kommentar mitzusenden, damit der Helfer 
            besser entscheiden kann, ob dessen Hilfe passt.
            Alltagshelfer werden nur angezeigt, wenn Sie diese Dienstleistung auch in Ihrem
            Profil hinterlegt haben.`,
          },
          {
            question: `Welche Tätigkeiten wird der Helfer nicht übernehmen?`,
            answer: `Die Helfer übernehmen keine Tätigkeiten, die eine erlaubnispflichtige
          Tätigkeit oder Handwerkerrolle benötigen. Das betrifft unter anderem
          Elektriker, Rechtsberatung, Steuerberatung.`,
          },
          {
            question: `Wie erfolgt die Abrechnung und Bezahlung?`,
            answer: `Alles ist bargeldlos und so einfach wie möglich. Sie benötigen für die
          Anforderung und schnelle Inanspruchnahme der verschiedenen
          Dienstleistungen auch kein Bargeld zu Hause oder müssen über Preise
          und geleistete Stunden verhandeln. Nach Abschluss des Auftrages wird
          die erbrachte Helferzeit für die Rechnungserstellung verwendet. Auf Basis dieser
          Abrechnung wird der Gesamtbetrag errechnet und Sie bezahlen Ihren
          Rechnungsbetrag bequem (Kreditkarte oder SEPA) direkt in unserer App.`,
          },
          {
            question: `Kann ich mir Hilfe von der Krankenkasse/Pflegekasse erstatten lassen?`,
            answer: `Die Regelungen können pro Bundesland und Sozial- bzw. Krankenkasse abweichen. 
            Bitte informieren Sie sich bei Ihrem Sozialversicherungsträger. Allgemein werden diese 
            Kosten erstattet (Entlastungsbeitrag 125 Euro/Monat ab PG 1) oder sind in der Steuererklärung 
            (haushaltsnahe Dienstleistungen) absetzbar. Eine Konkretisierung und Anzeige zertifizierter 
            Alltagshelfer mit Akkreditierung bei den Pflegekassen erfolgt in Kürze.`,
          },
          {
            question: `Wie wird mein Standort ermittelt?`,
            answer: `Durch die Nutzung unserer sicheren Comii-App wird Ihr Standort über Ihr
          Mobiltelefon nur an uns übertragen. Dadurch stellen wir sicher, dass
          Sie an jedem Ort auf unsere Helfer zurückgreifen können. Dieser Ort
          muss nicht zwangsläufig bei Ihnen zu Hause sein.`,
          },
          {
            question: `Welche Qualifikationen haben die Helfer?`,
            answer: `Alle Helfer werden durch Comii angemessen identifiziert,
          authentifiziert und auch qualitätsgeprüft. Für spezifische
          Dienstleistungen fordern wir ergänzend noch spezifische Nachweise und
          Zertifikate, damit Sie guten Gewissen alle Leistungen anfordern
          können. Im Profil der Helfer sind alle verifizierten
          Qualifikationsmerkmale hinterlegt.`
          },
          {
            question: `Sind die Helfer versichert und wenn ja wie?`,
            answer: `Alle Helfer müssen laut unseren Comii-Qualitätsanforderungen eine
          Berufshaftpflichtversicherung abgeschlossen haben.`,
          },
          {
            question: `Was mache ich, wenn ich mit dem Auftrag nicht zufrieden bin?`,
            answer: `Hier muss man zwischen einer nicht zufriedenstellend erfüllten
          Dienstleistung und der Unzufriedenheit mit einem Helfer unterschieden.
          Bitte bewerten Sie beides direkt nach dem Auftrag - 5 Sterne bedeutet
          „Sie sind sehr zufrieden“ und 1 Stern bedeutet „Sie sind überhaupt
          nicht zufrieden“. Ihre Rückmeldung wird sehr geschätzt, auch wir
          entwickeln uns und wollen uns stetig verbessern.`,
          },
          {
            question: `Was passiert, wenn ich nur einen Stern als Bewertung abgebe?`,
            answer: `Wir überprüfen regelmässig alle Helfer mit ungenügenden Bewertungen. In
          solchen einzelnen Fällen werden wir mögliche Ursachen und verbessernde
          Massnahmen mit dem Helfer persönlich besprechen und zeitnah einleiten.
          Sollte keine der Massnahmen greifen, werden wir im schlimmsten Fall
          solche Helfer auch sperren.`,
          },
          {
            question: `In welchem Takt wird die Leistung des Helfers verrechnet?`,
            answer: `Comii berechnet die ersten 30 Minuten (12,50 Euro) pauschal ab. 
            Danach erfolgt die Abrechnung im 15-Minuten-Takt. Die Zeitmessung beginnt
            ab dem Zeitpunkt, ab dem der Helfer bei Ihnen eintrifft. Unsere ausgefeilte Technik erlaubt es dem
            Alltagshelfer die Zeit erst ab dem Eintreffen bei Ihnen zu starten. Sie selbst haben die volle Kontrolle der Zeitmessung,
            da Sie diese Zeitmessung in Echtzeit verfolgen können. Auch Pausen sind möglich. Hierzu muss der Alltagshelfer die "Pausetaste" drücken.`,
          },
        ];
      },
    },
  },
  computed: {
    colorClass() {
      return this.$store.getters.colorClass;
    },
  },

  mounted() {
    for (let faq of document.getElementsByClassName("comii-faq-question")) {
      faq.classList.add(`text-${this.colorClass}-main`);
    }
  },
};
</script>

<style lang="scss" scoped></style>
