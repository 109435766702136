<template>
  <div class="card1">
    <div
      class="bancard"
      v-lazy:background-image="require('@/assets/images/' + image)"
     
    ></div>
    <div class="cont">
      <div class="prop">
        <div class="identi">
          <div class="name">
            <p :style="`color:${currentColor}`">{{ name }}</p>
          </div>
          <div :style="`border-left-color:${currentColor}`" class="tir"></div>
          <div class="age">
            <p :style="`color:${currentColorLight}`">{{ age }}</p>
          </div>
        </div>
        <div class="statut">
          <p class="text-sm">{{ occupation }}</p>
        </div>
      </div>
      <p class="text-sm">{{ desc }}</p>
      <div class="etoile">
        <span>&#9733;</span>
        <span>&#9733;</span>
        <span>&#9733;</span>
        <span>&#9733;</span>
        <span>&#9733;</span>
      </div>
    </div>
    <div class="lien">
      <router-link
        :to="toUrl"
        class="hover:text-white transition-colors"
        :class="{
            'hover:bg-green-main border-green-main text-green-main':colorClass==='green',
            'hover:bg-orange-main border-orange-main text-orange-main':colorClass==='orange',
            'hover:bg-purple-main border-purple-main text-purple-main':colorClass==='purple',
        }"
        >Mehr erfahren</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Card1",

  props: {
    image: { type: String, default: "aged-people.jpg" },
    name: { type: String, default: "Vincent" },
    age: { type: String, default: "71 Jahre" },
    occupation: { type: String, default: "Rentner" },
    desc: {
      type: String,
      default:
        "Vincent engagiert sich in seinem Ort als Alltagshelfer.",
    },
    toUrl: { type: String, default: "/" },
  },
  computed: {
    currentColor() {
      return this.$store.getters.currentColor;
    },

    colorClass(){
        return this.$store.getters.colorClass;
    },

    currentColorLight() {
      return this.$store.getters.currentColorLight;
    },
  },
};
</script>

<style lang="scss" scoped></style>
