<template>
  <div class="our-help-details">
    <div class="w-full sm:w-4/5 mx-auto text-center responsive-heading2 flex flex-col items-center">
      <div
        class="w-56 lg:w-64 h-56 lg:h-64 rounded-full flex items-center justify-center shadow mx-auto mb-6"
      >
        <img
          class="w-40 h-40 lg:w-56 lg:h-56 rounded-full flex-shrink-0 object-cover"
          v-lazy="
            require(`@/assets/images/${
              $route.query.image || 'IMG_3206.png'
            }`)
          "
          alt="person"
        />
      </div>

      <div class="flex items-stretch align-base justify-center">
        <span :class="`text-${colorClass}-main`" class="align-baseline pr-2">
          {{ $route.query.name }}
        </span>
        <div :class="`border-${colorClass}-main`" class="border-l-2 my-1 pr-2"></div>
        <span :class="`text-${colorClass}-light-main`"> {{ $route.query.age }} </span>
      </div>
      <div class="text-gray-main text-responsive italic mb-10">
        {{ $route.query.occupation }}
      </div>

      <h3 :class="`text-${colorClass}-main`" class="mb-2">Warum ich helfe?</h3>
      <p class="text-gray-main mb-6">
        {{ $route.query.warum }}
      </p>
      <h3 :class="`text-${colorClass}-main`" class="mb-2">Wie ich Alltagshelfer wurde?</h3>
      <p class="text-gray-main mb-6">
        {{ $route.query.wie }}
      </p>
      <h3 :class="`text-${colorClass}-main`" class="mb-2">Was ich als Alltagshelfer mache?</h3>
      <p class="text-gray-main mb-6">
        {{ $route.query.was }}
      </p>

      <div class="stars text-3xl lg:text-5xl mb-6">
        <span
          v-for="num in 5"
          :key="'star' + num"
          class="mx-1"
          :class="{ 'text-yellow-500': num < 5, 'text-gray-500': num === 5 }"
          >&#9733;</span
        >
      </div>

      <router-link
        class="block mx-auto px-6 mt-6 py-2 border w-max hover:text-white transition-colors"
        :class="`border-${colorClass}-main text-${colorClass}-main hover:bg-${colorClass}-main`"
        :to="$route.path.split('?')[0]"
      >
        Zurück
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurHelpDetails",

  props: {
    detailsData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    currentColor() {
      return this.$store.getters.currentColor;
    },

    colorClass(){
        return this.$store.getters.colorClass;
    },

    currentColorLight() {
      return this.$store.getters.currentColorLight;
    },
  },
};
</script>

<style lang="scss" scoped></style>
