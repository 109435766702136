<template>
    <div class="partner  page-block block-padding">
        <div class="w-full xs:w-11/12 mx-auto text-center responsive-heading3 mb-10">
            <div class="top-bar rounded bg-purple-400 responsive-top-bar h-1 mx-auto my-4"></div>
            <h2 class="text-purple-main responsive-heading1.5 font-bold mb-5">
              Beirat
            </h2>
            
            <p class="mx-auto text-pruple-main mb-10 text-purple-main">
              Comii hat ein starkes Netzwerk! Sie wollen auch dazugehören? 
            </p>
            
            <p class="text-gray-main">
              Unser Beirat stellt sich vor! Comii ist nicht nur eine Infrastruktur für Alltagshelfer und Senioren. Comii ist mehr. 
              Comii möchte Senioren, pflegende Angehörige, Alltagshelfer und die Nachbarschaft sowie Nachbarschaftsnetzwerke unterstützen um Probleme des
              Alltags zu lösen. Und gerade deshalb ist Comii auf Unterstützung angewiesen. Jeder kann dazu beitragen dass Comii noch besser wird. Deshalb sind wir dankbar und stolz auf die tolle Unterstützung unserer ehrenamtlichen Beiräte. Dadurch erhalten auch Sie Gewissheit, dass wir alles dafür unternehmen, Senioren in Zukunft noch besser zu unterstützen. In Zukunft wollen wir auch Senioren und Alltagshelfer einladen sich bei Comii zu engagieren. Bei Interesse melden Sie sich gerne unter Info@comii.de
            </p>
        </div>
        <div class="plan-cards 
      grid gap-4 grid-cols-1  sm:grid-cols-2 lg:grid-cols-3  items-stretch
    
     mb-5">
      <!---- ----------------------->

      <div class="plan-card responsive-heading3 w-full mb-4">
        <div class="plan-card__image">
          <img class="image_resize" v-lazy="require('@/assets/images/Daniel_Hirt.jpg')" alt="" />
        </div>
        <!-- <div class="plan-card__name">Comii Basic</div> -->
        <div class="plan-card__down px-8 py-5">
          <h1 class="plist-item">Daniel Hirt</h1>
         <div class="plist-item">Daniel war Mitinitiator von Comii.
          Ohne Ihn wäre Comii nicht zustande gekommen.
          Mit seinem Know-How als Leiter Finanzen u.a. in der Healthcare-Branche sowie als Hochschuldozent 
          für betriebliches Rechnungswesen und Betriebswirtschaftslehre, steht uns Daniel  
          als Sparringspartner und Unterstützer verlässlich zu Verfügung.</div>
        </div>
      </div>
      <!---- ----------------------->

      <div class="plan-card responsive-heading3 w-full mb-4">
        <div class="plan-card__image">
          <img class="image_resize" v-lazy="require('@/assets/images/annette.jpg')" alt="" />
        </div>
        <!-- <div class="plan-card__name">Comii Basic</div> -->
        <div class="plan-card__down px-8 py-5">
          <h1 class="plist-item">Annette Hempen MHBA</h1>
         <div class="plist-item">Als Geschäftsführerin des Ärztenetzes MuM- eG und Mitglied des Vorstands der DG Telemed- Deutschen Gesellschaft für Telemedizin e.V. sowie
stellv. Vorsitzende der AdA – Bundesverband der Arzt-, Praxis-, und Gesundheitsnetze e.V. ist Sie Expertin für die intersektorale Versorgung sowie ambulante und stationäre Prozesse. Sie hat diverse Preise für innovative Versorgungslösungen erhalten. Frau Hempen ist weiterhin Autorin und gern gesehene Vortragende diverser Konferenzen. Sie fordert uns mit Ihrem umfangreichen Fachwissen und unterstützt Comii.</div>
        </div>
      </div>
      <!---- ----------------------->

      <div class="plan-card responsive-heading3 w-full mb-4">
        <div class="plan-card__image">
          <img class="image_resize" v-lazy="require('@/assets/images/gerhard.jpg')" alt="" />
        </div>
        <!-- <div class="plan-card__name">Comii Basic</div> -->
        <div class="plan-card__down px-8 py-5">
          <h1 class="plist-item">Gerhard Fischbach</h1>
         <div class="plist-item">Als Markenprofi mit mehr als 30 Jahren Erfahrung hat Herr Fischbach zahlreiche nationale und internationale Auszeichnungen und Preise erhalten. Die Marke Bruno Banani kennt wohl jeder. Er lebt „Marken“ und  sprüht vor Kreativität.
Herr Fischbach steuert uns in Marketing und Pressearbeit und gibt Anstösse zum weiteren Ausbau der Marke Comii – Klick! Klick! Hilfe kommt.
</div>
        </div>
      </div>
      <!---- ----------------------->

      <div class="plan-card responsive-heading3 w-full mb-4">
        <div class="plan-card__image">
          <img class="image_resize" v-lazy="require('@/assets/images/zenobia.jpg')" alt="" />
        </div>
        <!-- <div class="plan-card__name">Comii Basic</div> -->
        <div class="plan-card__down px-8 py-5">
          <h1 class="plist-item">Prof Dr. Zenobia Frosch</h1>
         <div class="plist-item">Frau Prof Dr. Frosch ist Spezialistin für Gesundheits- und Sozialmanagement insb. Pflegemanagement. Sie lehrt an der FOM in Stuttgart.  Mit über 15 Jahren Erfahrung als Pflegedirektorin im Pflegemanagement ist Frau Prof. Dr. Frosch im Themengebiet von Comii die wesentliche Stütze uns regelmässig zu fordern und unsere Konzepte zu hinterfragen. 
</div>
        </div>
      </div>
      <!---- ----------------------->

      <div class="plan-card responsive-heading3 w-full mb-4">
        <div class="plan-card__image">
          <img class="image_resize" v-lazy="require('@/assets/images/florian.jpg')" alt="" />
        </div>
        <!-- <div class="plan-card__name">Comii Basic</div> -->
        <div class="plan-card__down px-8 py-5">
          <h1 class="plist-item">Florian Buschbacher</h1>
         <div class="plist-item">Florian war Mitinitiator von Comii. Ohne Ihn wäre Comii nicht zustande gekommen. Als Vorstand der Digital Suxess AG und über 20 Jahre Erfahrung im Bereich KI, Analytics, Softwareentwicklung, insbesondere zu digitalen Ökosystemen und Geschäftsmodellen ist Comii sein „Lebensprojekt“. Neben vielen Fachbeiträgen, Buchartikeln und Auszeichnungen war Herr Buschbacher über 7 Jahre im Vorstand des BitKom und ist Dozent an Hochschulen. Für den Beirat koordiniert er die Termine und Themen im Beirat, nimmt Themen auf und berichtet über neue strategische Entwicklungen. Er ist damit Bindeglied zwischen dem Comii Team und dem Beirat.
</div>
        </div>
      </div>
      <!---- ----------------------->

      <div class="plan-card responsive-heading3 w-full mb-4">
        <div class="plan-card__image">
          <img class="image_resize" v-lazy="require('@/assets/images/jürgen.jpg')" alt="" />
        </div>
        <!-- <div class="plan-card__name">Comii Basic</div> -->
        <div class="plan-card__down px-8 py-5">
          <h1 class="plist-item">Jürgen Neumeister</h1>
         <div class="plist-item">Als Spezialist für IT Datenschutz ist Herr Neumeister seit über 30 Jahren selbständig. In dieser Zeit hat er sich im Sozialverband VdK als wesentliche Stütze unserer Gesellschaft und den Bedürfnissen von Senioren und sozialen Angelegenheiten verdient gemacht. Herr Neumeister ist stellvertretender Vorsitzende des Sozialverbandes VdK Baden-Württemberg und Vorstand im VdK Bezirksverband Südwürttemberg-Hohenzollern. Herr Neumeister kennt die sozialen Herausforderungen und Probleme wie kein anderer. Hier unterstützt er uns mit seinem Fachwissen aber auch seinem Wissen über die Probleme die Comii lösen will.
</div>
        </div>
      </div>
      <!---- ----------------------->

      <div class="plan-card responsive-heading3 w-full mb-4">
        <div class="plan-card__image">
          <img class="image_resize" v-lazy="require('@/assets/images/gerrit.jpg')" alt="" />
        </div>
        <!-- <div class="plan-card__name">Comii Basic</div> -->
        <div class="plan-card__down px-8 py-5">
          <h1 class="plist-item">Dr. Gerrit Hötzel</h1>
         <div class="plist-item">Als Fachanwalt für IT-Recht sowie Urheber- und Medienrecht begleitet er Unternehmen wie Comii bei der Umsetzung von Digitalisierungsprojekten unter sämtlichen rechtlichen Aspekten. Er ist Partner der Kanzlei VOELKER & Partner mbB</div>
        </div>
      </div>
      <!---- ----------------------->

    </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.image_resize {
  height: 300px;
  object-fit: cover;
}

</style>
