import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state() {
    return {
      colorClass: "purple",
      currentColor: "#78589F",
      currentColorLight: "#B29FC3",

      currentSection: {
        title: "Startseite",
        baseUrl: "/",
      },

      showMainMenu: false,
      showSubMenu: false,
    };
  },
  mutations: {
    setColorClassMut(state, val) {
      state.colorClass = val;
      if (val === "purple") {
        state.currentColor = "#78589F";
        state.currentColorLight = "#B29FC3";
      } else if (val === "orange") {
        state.currentColor = "#FF5900";
        state.currentColorLight = "#FFAA00";
      } else {
        state.currentColor = "#A3A704";
        state.currentColorLight = "#DBE000";
      }
    },

    setCurrentSectionMut(state, payload) {
      state.currentSection = payload;
    },

    toggleMainMenuMut(state, val) {
      if (val!==undefined) state.showMainMenu = val;
      else state.showMainMenu = !state.showMainMenu;

    },
    toggleSubMenuMut(state, val) {
      if (val!==undefined) state.showSubMenu = val;
      else state.showSubMenu = !state.showSubMenu;
    },
  },
  actions: {
    setColorClass({ commit }, value = "purple") {
      commit("setColorClassMut", value);
    },

    setCurrentSection({ commit }, payload) {
      commit("setCurrentSectionMut", payload);
    },

    toggleMainMenu({ commit }, value) {
      commit("toggleMainMenuMut", value);
    },
    toggleSubMenu({ commit }, value) {
      commit("toggleSubMenuMut", value);
    },
  },

  getters: {
    colorClass: (state) => state.colorClass,
    currentColor: (state) => state.currentColor,
    currentColorLight: (state) => state.currentColorLight,
    currentSection: (state) => state.currentSection,
    showMainMenu: (state) => state.showMainMenu,
    showSubMenu: (state) => state.showSubMenu,
  },
  modules: {},
});
