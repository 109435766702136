<template>
  <div 
    class="flex"
    :class="{
        'text-xl': !noPredefinedFontSize
    }"
  >
    <span 
        class="pr-4 font-bold"
        :class="`text-${colorClass}-main`"
    >
        &#10095;
    </span>
      <span 
        :class="!colorClassNotOnText? `text-${colorClass}-main`: ''"
        v-html="text"
        ></span>
  </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: 'Ein von der Polizei ausgestelltes Führungszeugnis'
        },

        noPredefinedFontSize: {
            type: Boolean,
            default: false
        },

        colorClassNotOnText: {
            type: Boolean,
            default: false
        },

        colorClass: {
            type: String,
            default: 'orange'
        }
    }
}
</script>

<style>

</style>