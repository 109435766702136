<template>
  <div :class="`top-hero ${isMainHero ? 'main-hero' : ''}`">
    <div
      :style="`background-image: ${
        heroWhiteGradient
          ? 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,7) 20%, rgba(255,255,255,0) 50%)'
          : heroDarkGradient
          ? 'radial-gradient(closest-side at 50% 50%, rgba(3, 1, 91, 0) 0%, rgba(3, 1, 90, 0.024) 18%, rgb(34 36 34 / 50%) 100%)'
          : 'linear-gradient(90deg, transparent, transparent)'
      }, url( ${require('@/assets/images/' + image)});

			background-position: ${imagePosition};
      padding-bottom: ${heroBottomPadding}
    `"
      class="content-wrapper page-block block-padding"
      :class="{
        'py-20': !isMainHero && !headingBorderTop,
        'pt-8': headingBorderTop,
      }"
    >
      <div
        class="hero-content w-full sm:w-96"
        :class="`${isMainHero ? 'pt-32' : 'pt-24'} ${
          isMainHero ? 'pb-12' : 'pb-20'
        }`"
      >
        <div
          v-if="headingBorderTop || headingBorderLeft"
          class="h-1 responsive-top-bar-lg rounded-xl mx-0 sm:mx-auto "
          :class="{ 'md:hidden ml-4 mb-2':!headingBorderTop && headingBorderLeft}"
          :style="{
            'background-color': `var(${currentColor})`,
            'margin-top': headingBorderTop ? '' : '',
          }"
        ></div>
        <div
          class="hero-title responsive-heading1.5 text-left font-bold mb-6"
          :class="{
            'pt-6 mx-auto sm:mx-0': headingBorderTop,
            'border-0 md:border-l-4 pl-4': !headingBorderTop && headingBorderLeft,
            
          }"
          :style="{
            color:
              isColorTitle ? `var(${currentColor})` : isWhiteTitle? 'white': 'var(--breadcrump-dark)',
            'border-left-color': `var(${currentColorBorderLeft})`,
          }"
          v-html="mainTitle"
        >
        </div>

        <div :class="{ 'pl-4': headingBorderLeft }">
          <div
            v-if="isMainHero"
            class="hero-text smaller-text-responsive text-gray-main text-left text-lg mb-6 "
          >
            <slot></slot>
          </div>

          <!-- <div v-if="isMainHero && !hideSelect" class=" mb-3 sm:mb-6 md:mb-12">
            <select
              name="some-select"
              id="old-select"
              class=" flex w-auto cursor-pointer pl-2 pr-2 text-sm py-2 text-white focus:outline-none"
              :style="{ 'background-color': `var(${currentColor})` }"
            >
              <option
                v-for="(val, index) in selectOptions"
                :value="val"
                :key="`opt${index}`"
              >
                {{ val }}
              </option>
            </select>
            <span
              class="block transform rotate-90 -translate-y-1/2 cursor-pointer text-xl text-white absolute right-4 top-1/2"
              >&#10095;</span
            > 
          </div> -->

          <div
            v-if="isMainHero"
            class="store-buttons lg:w-3/5 w-2/3 flex lg:justify-start justify-start"
          >
            <a v-if="appLink == 'senior'" href="https://play.google.com/store/apps/details?id=com.comii.senior&gl=DE" class="storebut mr-4">
              <img
                v-lazy="require('@/assets/images/play-store.png')"
                alt="google play link"
              />
            </a>
            <a v-if="appLink == 'senior'" href="https://apps.apple.com/de/app/comii-senior/id1584937013" class="storebut">
              <img v-lazy="require('@/assets/images/app-store.png')" alt="google play link" />
            </a>
            <a v-if="appLink == 'helfer'" href="https://play.google.com/store/apps/details?id=com.comii.support&gl=DE" class="storebut mr-4">
              <img
                v-lazy="require('@/assets/images/play-store.png')"
                alt="google play link"
              />
            </a>
            <a v-if="appLink == 'helfer'" href="https://apps.apple.com/de/app/comii-alltagshelfer/id1584936494" class="storebut">
              <img v-lazy="require('@/assets/images/app-store.png')" alt="google play link" />
            </a>
          </div>

          <!-- <div v-if="existsTelechargerButton">
            <button
              class="mt-8 cursor-pointer px-8 text-sm py-4 text-white focus:outline-none"
              :style="{ 'background-color': `var(${currentColor})` }"
            >
              Telecharger l'APP
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideSelect: {
      type: Boolean,
      default: false,
    },
    appLink: {
      type: String,
      default: "senior",
    },
    existsTelechargerButton: {
      type: Boolean,
      default: false,
    },

    image: {
      type: String,
    },

    heroBottomPadding: {
      type: String, 
      default: "",
    },

    headingBorderTop: {
      type: Boolean,
      default: false,
    },

    headingBorderLeft: {
      type: Boolean,
      default: false,
    },
    heroWhiteGradient: {
      type: Boolean,
      default: false,
    },
    heroDarkGradient: {
      type: Boolean,
      default: false,
    },

    mainTitle: String,
    isColorTitle: {
      type: Boolean,
      default: false,
    },
    isWhiteTitle: {
      type: Boolean,
      default: false,
    },
    text: String,
    // selectOptions: {
    //   type: Array,
    //   // eslint-disable-next-line vue/require-valid-default-prop
    //   default: () => ["Klicken Sie auf Ihren App Store"],
    // },
    isMainHero: { type: Boolean, default: true },
    colorClass: {
      type: String,
      default: "orange",
    },
    imagePosition: {
      type: String,
      default: "",
    },
  },

  computed: {
    currentColor() {
      switch (this.colorClass) {
        case "green":
          return "--main-green";
        case "purple":
          return "--main-purple";
        default:
          return "--main-orange";
      }
    },
    currentColorBorderLeft() {
      switch (this.colorClass) {
        case "green":
          return "--main-green-light";
        case "purple":
          return "--main-purple";
        case "orange":
          return "--main-orange-light";
        default:
          return "--main-orange-light";
      }
    },

    currentBackgroundShadow() {
      return this.isMainHero
        ? "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,7) 20%, rgba(255,255,255,0) 50%)"
        : "radial-gradient(closest-side at 50% 50%, rgba(3, 1, 91, 0) 0%, rgba(3, 1, 90, 0.024) 18%, rgb(34 36 34 / 50%) 100%)";
    },
  },
};
</script>

<style lang="scss" scoped></style>
