var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card25-container"},[_c('div',{staticClass:"before-2-content card25 card25-false"},[_c('div',{staticClass:"maine25",class:{
                'order-2': _vm.reversed,
                'content-equal-width': _vm.equalWidth
            }},[_vm._t("before-pic")],2),_c('div',{staticClass:"sidee25"})]),_c('div',{staticClass:"lg:flex w-full mb-8 gap-5 text-xl"},[_c('div',{staticClass:"w-full lg:w-1/2 lg:mb-0 mb-10",class:{
                'order-2': _vm.reversed,
                'overflow-y-image': _vm.overflowYImage,
                'content-equal-width': _vm.equalWidth
            }},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require(("@/assets/images/" + _vm.image))),expression:"require(`@/assets/images/${image}`)"}],attrs:{"alt":""}})]),_c('div',{staticClass:"w-full lg:w-1/2",class:{
                'content-equal-width': _vm.equalWidth
            }},[_c('div',{staticClass:"texte25"},[_vm._t("side",function(){return [_c('div',{staticClass:"tiree25"}),_c('h1',{staticClass:"sideet25"},[_vm._v("Alltagshelfer werden")]),_c('p',{staticClass:"sideep25"},[_vm._v("Alltagshelfer zu werden ist ganz einfach! Jeder, ob Schüler, Student, Pflegefachkraft, Teilzeitbeschäftigter oder Frührentner: Jeder kann sich etwas dazuverdienen!")]),_vm._m(0),_vm._m(1)]})],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text125"},[_c('div',{staticClass:"title125"},[_c('span',{staticClass:"sp25"},[_vm._v("01")]),_c('h1',[_vm._v("Informationen für Einzelpersonen")])]),_c('p',[_vm._v("Als Schüler, Student oder Rentner können Sie bis zu "),_c('span',[_vm._v("22.000€")]),_vm._v(" pro Jahr nebenbei verdienen, ohne eine Rechnung mit Mehrwertsteuer ausstellen zu müssen.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text225"},[_c('div',{staticClass:"title225"},[_c('span',{staticClass:"sp25"},[_vm._v("02")]),_c('h1',[_vm._v("Und das beste...")])]),_c('p',[_vm._v("Comii leitet die Anfrage an Sie weiter, wenn Sie Zeit haben akzeptieren Sie und helfen. Comii stellt Ihre Rechnung aus und der Senior überweist Ihnen das Geld auf Ihr Bankkonto. Sie müssen sich um nichts kümmern!")])])}]

export { render, staticRenderFns }