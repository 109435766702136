<template>
  <div>
      <table class="divided-table" :class="`bg-${colorClass}-parent`">
          <tr v-if="firstRowBackgroundColored" class="h-0"></tr>
          <slot></slot>
      </table>
  </div>
</template>

<script>
export default {
    props: {
        colorClass: {
            type: String,
            default: 'orange'
        },
        firstRowBackgroundColored: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>
</style>