<template>
  <header class="app-header">
    <div class="content-wrapper">
      <div class="app-header__upper block-padding">
        <div class="page-block flex justify-between py-4">
          <div class="logo flex w-11 cursor-pointer">
            <router-link to="/">
              <img v-lazy="require('@/assets/images/logo.jpg')" />
            </router-link>
          </div>

          <!-- The upper part of the header which contains the logo and the contact information -->

          <div
            class="header-contact block space-y-2 lg:text-base text-sm lg:flex text-gray-main"
          >
            <div class="flex items-center mr-4">
              <div class="mr-2 text-2xl lg:text-4xl">&#9993;</div>
              <div class="flex flex-col">
                <span>Schreiben Sie uns gerne</span>
                <span class="font-bold">info@comii.de</span>
              </div>
            </div>
            <div class="flex items-center">
              <div class="mr-2 flex w-6 lg:w-10">
                <img v-lazy="require('@/assets/images/telephone-call.png')" alt="telephone icon" />
              </div>
              <div class="flex flex-col">
                <span>Rufen Sie uns gerne an</span>
                <span class="font-bold">07434/741-9820</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- The lower part of the header which is colored and contains the menu and social icons -->
      <div
        :class="`app-header__lower  block-padding py-4 text-white ${currentColor}`"
      >
        <div class="page-block flex justify-between items-center">
          <!-- The menu button icon here -->
          <button
            @click.stop="toggleMenu(true, canShowSubMenu)"
            class="hamburger-icon focus:outline-none border-none text-3xl"
          >
            &#9776;
          </button>

          <!-- The side drawer menu which appears when hamburger icon is clicked -->
          <aside
            @click.stop="() => {}"
            :class="`side-menu not-sub ${currentColor} ${
              showMenu ? 'show' : ''
            }`"
            class="w-full sm:w-96 lg:w-102"
          >
            <div class="content-wrapper block-padding">
              <div
                class="delete-ribon text-white text-2xl py-3 flex justify-end"
              >
                <button
                  @click="toggleMenu(false, false)"
                  class="border flex items-center justify-center border-white rounded-full w-8 h-8 p-2"
                >
                  &#10005;
                </button>
              </div>
              <nav class="menu-items flex flex-col mb-6">
                <router-link
                  to="/"
                  @click.native="toggleMenu(false, false)"
                  class="menu-item"
                  ><span>Startseite</span></router-link
                >
                <router-link
                  to="/about"
                  @click.native="toggleMenu(false, false)"
                  :class="`menu-item ${generateSelectedClass('about')}`"
                  ><span>Über Comii</span></router-link
                >
                <router-link
                  to="/for-seniors"
                  @click.native="toggleMenu(false, false)"
                  :class="`menu-item ${generateSelectedClass('for-seniors')}`"
                  ><span>Für Senioren</span></router-link
                >
                <router-link
                  to="/lifehelpers"
                  @click.native="toggleMenu(false, false)"
                  :class="`menu-item ${generateSelectedClass('lifehelpers')}`"
                  ><span>Für Alltagshelfer</span></router-link
                >
                <router-link
                  to="/neighborhood"
                  @click.native="toggleMenu(false, false)"
                  :class="`menu-item ${generateSelectedClass(
                    'for-neighborhood'
                  )}`"
                  ><span>Für Nachbarschaftsnetzwerke</span></router-link
                >
                <router-link
                  to="/mobile-care"
                  @click.native="toggleMenu(false, false)"
                  :class="`menu-item ${generateSelectedClass('mobile-care')}`"
                  ><span>Für mobile Pflegedienste</span></router-link
                >
              </nav>

              <div class="language-social flex sm:hidden text-sm items-center pl-16 sm:pl-20">
                <div class="social-links flex items-center">
                  <a
                    class="social-link"
                    href="https://www.instagram.com/comii_die_app/"
                  >
                    <instagram-icon :colorClass="colorClass" />
                  </a>
                  <a
                    class="social-link"
                    href="#"
                    ><linkedin-icon :colorClass="colorClass"
                  /></a>
                  <a
                    class="social-link"
                    href="https://www.facebook.com/comiiDieApp/"
                    ><facebook-icon :colorClass="colorClass"
                  /></a>
                </div>
              </div>
            </div>
          </aside>
          <!----------------------------------------------------------------------->

          <div class="language-social hidden text-sm sm:flex items-center ">
            <!-- <div class="app-language mr-6">
              <span class="mr-1">Französisch</span
              ><span class="rotate-90 transform inline-block">&#10095;</span>
            </div> -->

            <div class="social-links flex items-center">
              <a
                class="flex items-center justify-center social-link inst w-8 h-8 mr-3 rounded-full flex-shrink-0 text-xs bg-white"
                href="https://www.instagram.com/comii_die_app"
              >
                <instagram-icon :colorClass="colorClass" />
              </a>
              <a
                class="flex items-center justify-center social-link inst w-8 h-8 mr-3 rounded-full flex-shrink-0 text-xs bg-white"
                href="https://www.youtube.com/channel/UCR6Nds33WnnhS80LzRrQykw"
                ><linkedin-icon :colorClass="colorClass"
              /></a>
              <a
                class="flex items-center justify-center social-link inst w-8 h-8 mr-3 rounded-full flex-shrink-0 text-xs bg-white"
                href="https://www.facebook.com/comiiDieApp/"
                ><facebook-icon :colorClass="colorClass"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import FacebookIcon from "../icons/FacebookIcon.vue";
import InstagramIcon from "../icons/InstagramIcon.vue";
import LinkedinIcon from "../icons/LinkedinIcon.vue";
export default {
  components: { FacebookIcon, InstagramIcon, LinkedinIcon },
  data() {
    return {
      // showMenu: false,
    };
  },
  props: {
    colorClass: {
      type: String,
      default: "orange",
    },
  },

  computed: {
    currentColor() {
      switch (this.colorClass) {
        case "orange":
          return "header-orange";
        case "purple":
          return "header-purple";
        default:
          return "header-green";
      }
    },
    canShowSubMenu() {
      return this.$route.path.split("/").length > 1;
    },

    showMenu() {
      return this.$store.getters.showMainMenu;
    },
  },

  methods: {
    toggleMenu(mainMenuValue, subMenuValue) {
      this.$store.dispatch("toggleMainMenu", mainMenuValue);

      this.$store.dispatch("toggleSubMenu", subMenuValue);
    },

    generateSelectedClass(val) {
      if (this.$route.path.includes(val)) return "selected-main";
      else return "";
    },
  },

  mounted() {
    document.addEventListener("click", () => {
      if (this.showMenu) this.showMenu = false;
    });
  },
};
</script>
