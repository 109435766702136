<template>
<div>
  <div class="lg:flex gap-5 w-full">
    <img class="lg:w-1/2 w-full h-auto" v-lazy="require('@/assets/images/woman-holding-mother.jpg')" alt="">
    <div class="w-full lg:w-1/2 lg:mx-5">
    <div class="text-center lg:text-left">
      <div class="inline-block h-1 w-24 rounded bg-orange-main mb-2"></div>
      <h1 class="text-main-orange text-2xl">Alltagshelfer werden</h1> 
    </div>
    
      <p>Alltagshelfer zu werden ist ganz einfach! Jeder, ob Schüler, Student, 
          Teilzeitarbeiter, Pflegefachkraft oder Frührentner: Jeder kann sich etwas dazuverdienen!</p>
        <div class="ml-5">
          <div class="mt-5 text125">
          <div class="title125 mb-3 text-2xl">
            <span class="sp25">01</span>
            <h1 class="">Informationen für Einzelpersonen</h1>
          </div>
          <p>Als Schüler, Student oder Rentner können Sie bis zu 22.000€ pro Jahr nebenbei verdienen, ohne eine Rechnung mit Mehrwertsteuer ausstellen zu müssen.</p>
          </div>
          <div class="text225">
          <div class="title225 mb-3 text-2xl">
            <span class="sp25">02</span>
            <h1 class="font-bold">Und das Beste...</h1>
          </div>
          <p>Comii leitet die Bestellung an Dich weiter und stellt eine Rechnung für Dich an den Senior aus. Der Senior bezahlt Dich direkt nach Auftragsende in der Comii APP. Du musst Dich um nichts kümmern!</p>
          </div>
      </div>
    </div>
    </div>  
</div>
</template>

<script>
// import Card25 from "../../components/Card25.vue";
// import Bloc21 from "../../components/Bloc21.vue";
export default {
  components: {
    // Card25,
    // Bloc21
  },
};
</script>

<style>
</style>
