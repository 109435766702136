g<template>
  <div class="for-seniors-default mt-16">
    <div class="w-full sm:w-5/6 mx-auto text-center responsive-heading3 text-gray-main page-block block-padding">
      <div
        class="top-bar rounded bg-green-light-main w-16 h-1 my-4 mx-auto"
      ></div>
      <h2 class="text-green-main responsive-heading1.5 font-bold mb-6">
        Comii unterstützt Sie
      </h2>

      <p class="mb-8">
        Jeder von uns kennt diese Situation: Man ist allein und bräuchte
        jemanden, der kurz zur Apotheke geht, einen Schrank aufbaut oder das
        Handy einrichtet. Die eigenen Kinder sind nicht in der Nähe? Der Nachbar
        im Urlaub?
      </p>

      <p class="text-green-main mb-20 lg:mb-28">
        Wer kann sonst noch helfen? Wie kann ich jemanden finden, der gerade jetzt Zeit hat
        und mir helfen kann?
      </p>
    </div>
    <div class="responsive-heading3 text-gray-main mb-10 page-block block-padding">
      <p class="text-left mb-8">
        Ob bei der Gartenarbeit, beim Ausfüllen von Formularen, beim Lesen eines
        Briefes oder eines Buches, bei kleinen Reparaturen im Haus, beim
        Einkaufen, beim Aufbauen des Rollators - oder bei anderen Bedürfnissen
        und Situationen des Lebens: Es gibt immer wieder Situationen, in denen
        man jemanden spontan um Hilfe bitten möchte. Aber wer?
      </p>

      <p class="text-left">
        <span class="text-green-main">
          Comii ist die Abkürzung für Community, Gesellschaft &amp; Gemeinschaft
          oder Komm mit! Wir halten zusammen und helfen uns gegenseitig.
        </span>
        Comii lebt von Kooperation und Partizipation. Comii hat sich zum Ziel
        gesetzt, Ihnen die richtige Unterstützung und Hilfe für Ihre aktuelle
        Situation zu bieten. Schnell, sicher und vor allem individuell, ganz
        nach Ihren Bedürfnissen.
      </p>
    </div>

    <div class="green-band bg-green-main block-padding">

      <div class=" py-12 px-4 page-block ">
        <div class="w-4/5 sm:w-3/5 md:w-2/5 mx-auto">
          <div class="flex w-14 md:w-16 mx-auto mb-2">
            <img v-lazy="require('@/assets/images/left-quote-green.png')" alt="left quote" />
          </div>
          <p class="responsive-heading1 text-white italic text-center mb-4">
            Selbstständig bleiben ist unser Motto.
          </p>
        </div>
        
        <p class="text-xl md:text-2xl font-sophia
          font-extralight text-white w-full sm:w-5/6 mx-auto text-center
         ">
            Wir hoffen, dass sich dieses Modell verbreitet, damit so viele
            Senioren wie möglich aktive Unterstützung erhalten können, wo und wann
            immer sie sich befinden.
          </p>
      </div>

    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
