<template>
    <div class="card25-container">
        <div class="before-2-content card25 card25-false">
            <div 
                class="maine25"
                :class="{
                    'order-2': reversed,
                    'content-equal-width': equalWidth
                }"
            >
                <slot name="before-pic">

                </slot>
            </div>
            <div class="sidee25">
            </div>
        </div>
        <div class="lg:flex w-full mb-8 gap-5 text-xl">
            <div 
                class="w-full lg:w-1/2 lg:mb-0 mb-10"
                :class="{
                    'order-2': reversed,
                    'overflow-y-image': overflowYImage,
                    'content-equal-width': equalWidth
                }"
            >
                <img v-lazy="require(`@/assets/images/${image}`)" alt="">
            </div>
            <div 
                class="w-full lg:w-1/2"
                :class="{
                    'content-equal-width': equalWidth
                }"
            >
                <div class="texte25">
                    <slot name="side">
                        <div class="tiree25"></div>
                        <h1 class="sideet25">Alltagshelfer werden</h1>
                        <p class="sideep25">Alltagshelfer zu werden ist ganz einfach! Jeder, ob Schüler, Student, Pflegefachkraft, Teilzeitbeschäftigter oder Frührentner: Jeder kann sich etwas dazuverdienen!</p>
                        <div class="text125">
                            <div class="title125">
                                <span class="sp25">01</span>
                                <h1>Informationen für Einzelpersonen</h1>
                            </div>
                            <p>Als Schüler, Student oder Rentner können Sie bis zu <span>22.000€</span> pro Jahr nebenbei verdienen, ohne eine Rechnung mit Mehrwertsteuer ausstellen zu müssen.</p>
                        </div>
                        <div class="text225">
                            <div class="title225">
                                <span class="sp25">02</span>
                                <h1>Und das beste...</h1>
                            </div>
                            <p>Comii leitet die Anfrage an Sie weiter, wenn Sie Zeit haben akzeptieren Sie und helfen. Comii stellt Ihre Rechnung aus und der Senior überweist Ihnen das Geld auf Ihr Bankkonto. Sie müssen sich um nichts kümmern!</p>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            overflowYImage: {
                type: Boolean,
                default: false
            },

            reversed: {
                type: Boolean,
                default: false
            },

            image: {
                type: String,
                default: 'photo1.jpg'
            },

            equalWidth: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
