<template>
  <div class="how-comii-works mt-12 mb-12">
    <div
      class="w-full lg:w-5/6 mx-auto text-center responsive-heading3 text-gray-main mb-16"
    >
      <div
        class="top-bar rounded bg-green-light-main responsive-top-bar h-1 my-4 mx-auto"
      ></div>
      <h2 class="text-green-main responsive-heading1.5 font-bold mb-6">
        Wie funktioniert Comii?
      </h2>

      <p class="mb-8">
        Comii funktioniert ganz einfach! Nach dem Herunterladen müssen Sie sich
        registrieren. Danach kann es schon losgehen:
      </p>

      <div
        class="how-blocks grid justify-center grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-6 gap-y-6"
      >
        <bloc-11
          class="justify-self-center"
          style="width: 320px; height: 100%"
          :overridePadding="block.text.length > 60 ? '1em' : null"
          v-for="(block, index) in blocksData"
          :key="`block${index}`"
          :number="`${block.number}`"
          colorClass="green"
          :content="block.text"
        />
      </div>
    </div>

    <div>
      <bloc-1
        image="man-woman-with-tablet.jpg"
        title="Für Senioren und Menschen, die Hilfe suchen"
      >
        <template #text>
          <p>
            Hier geht es zur Senioren APP:
          </p>
        </template>
      </bloc-1>
    </div>
  </div>
</template>

<script>
import Bloc1 from "../../components/Bloc1.vue";
import Bloc11 from "../../components/Bloc11.vue";
export default {
  data() {
    return {
      blocksData: [
        {
          number: 1,
          text: "Wählen Sie eine Kategorie aus (bspw. Garten oder Haushalt)",
        },
        {
          number: 2,
          text: "Alle in Ihrer Nähe verfügbaren Alltagshelfer werden Ihnen angezeigt",
        },
        {
          number: 3,
          text: "Wählen Sie einen Alltagshelfer gezielt aus oder senden Sie Ihre Anfrage an alle",
        },
        {
          number: 4,
          text: "Ein Helfer akzeptiert Ihren Auftrag und begibt sich direkt zu Ihnen",
        },
        {
          number: 5,
          text: `Nachdem der Helfer bei Ihnen ist, muss der Alltagshelfer die Statustaste "Auftrag beginnen" drücken. Sie erhalten eine Meldung.
`,
        },
        {
          number: 6,
          text: `Nach dem Arbeitseinsatz drückt der Alltagshelfer wiederum einen Status "Auftrag beendet". Automatisch werden Sie benachrichtigt und es erscheint ggf. eine Rechnung in Ihrer Übersicht.`,
        },
      ],
    };
  },
  components: { Bloc11, Bloc1 },
};
</script>

<style lang="scss" scoped></style>
