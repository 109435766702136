<template>
    <div class="partner  page-block block-padding">
        <div class="w-full xs:w-11/12 mx-auto text-center responsive-heading3 mb-10">
            <div class="top-bar rounded bg-purple-400 responsive-top-bar h-1 mx-auto my-4"></div>
            <h2 class="text-purple-main responsive-heading1.5 font-bold mb-5">
              Partner
            </h2>
            
            <p class="mx-auto text-pruple-main mb-10 text-purple-main">
              Comii hat ein starkes Netzwerk! Sie wollen auch dazugehören? 
            </p>
            
            <p class="text-gray-main">
              VdK Baden Württemberg,
              AdA - Bundesverband der Arzt-, Praxis- und Gesundheitsnetze,
              Verband der Alltagshelfer in Deutschland,
              PKF Wulf und Partner, Wirtschaftsprüfer, Steuerberater und Rechtsanwälte,
              Württembergische Versicherung,
              FOM Hochschule für Gesundheitsökonomie, 
              Digital Suxess AG,
              VÖLKER und Partner, Rechtsanwälte, Wirtschaftsprüfer und Steuerberater,
              SIC e.V. Mössingen - Senioren Internet Cafe
            </p>
        </div>
        <div class="partner-img">
          <img class="image_resize" v-lazy="require('@/assets/images/partner1.png')">
          <img class="image_resize" v-lazy="require('@/assets/images/partner2.jpg')">
          <img class="image_resize" v-lazy="require('@/assets/images/partner5.png')">
          <img class="image_resize" v-lazy="require('@/assets/images/partner6.svg')">
          <img class="image_resize" v-lazy="require('@/assets/images/partner7.png')">
          <img class="image_resize" v-lazy="require('@/assets/images/partner8.png')">
          <img class="image_resize" v-lazy="require('@/assets/images/partner10.png')">
          <img class="image_resize" v-lazy="require('@/assets/images/partner11.png')">
          <img class="image_resize" v-lazy="require('@/assets/images/partner12.png')">
          <img class="image_resize" v-lazy="require('@/assets/images/partner13.png')">
          <img class="image_resize" v-lazy="require('@/assets/images/SVDGV-Logo.png')">
        </div>
    </div>
    
</template>

<script>

    export default {
    }
</script>

<style lang="scss" scoped>
  .partner-img{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
  .image_resize{
  height: 60px;
  width: 145px;
  margin: 3rem;
}

</style>
