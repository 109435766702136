<template>
  <div class="our-help">
    <template v-if="!detailsId">
      <div class="w-full sm:w-4/5 lg:w-3/5 mx-auto text-center">
        <div
          class="top-bar rounded bg-green-light-main responsive-top-bar h-1 my-4 mx-auto"
        ></div>
        <h2 class="text-green-main responsive-heading1.5 font-bold mb-6">
          Unsere Alltagshelfer
        </h2>

        <p class="mb-12 text-gray-main responsive-heading3 font-extralight">
          Unsere Alltagshelfer sind Menschen wie Du und ich, die Gutes tun wollen.
        </p>
      </div>

      <div class="help-cards grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3">
        <!-- <card-1 :toUrl="$route.path+'?details='+num" style="width: 100%" v-for="num in 4" :key="`card${num}`"  /> -->
	<card-1 
		:toUrl="$route.path+'?name='+data[0].name+'&age='+data[0].age+'&warum='+data[0].warum+'&occupation='+data[0].occupation+'&wie='+data[0].wie+'&was='+data[0].was+'&image='+data[0].image+'&details='+num" 
		style="width: 100%"
		:image= data[0].image
		name= "Florian"
		age= "48 Jahre"
		occupation= "Alltagshelfer"
		desc= "Ich betreue seit 3 Jahren eine 92 Jahre alte Dame" 
	/>
	<card-1 
		:toUrl="$route.path+'?name='+data[1].name+'&age='+data[1].age+'&warum='+data[1].warum+'&occupation='+data[1].occupation+'&wie='+data[1].wie+'&was='+data[1].was+'&image='+data[1].image+'&details='+num" 
		style="width: 100%"
		:image= data[1].image
		name= "Eme"
		age= "28 Jahre"
		occupation= "Krankenpfleger"
		desc= "In meiner Freizeit helfe ich Senioren mit Comii" 
	/>
	<card-1 
		:toUrl="$route.path+'?name='+data[2].name+'&age='+data[2].age+'&warum='+data[2].warum+'&occupation='+data[2].occupation+'&wie='+data[2].wie+'&was='+data[2].was+'&image='+data[2].image+'&details='+num" 
		:image= data[2].image
		style="width: 100%"
		name= "Joy"
		age= "25 Jahre"
		occupation= "Pflegekraft"
		desc= "Mit Comii kann ich spontan helfen und gutes tun" 
	/>
	<card-1 
		:toUrl="$route.path+'?name='+data[3].name+'&age='+data[3].age+'&warum='+data[3].warum+'&occupation='+data[3].occupation+'&wie='+data[3].wie+'&was='+data[3].was+'&image='+data[3].image+'&details='+num"
		style="width: 100%"
		:image= data[3].image 
		name= "Sylvia"
		age= "42 Jahre"
		occupation= "Pflegefachkraft "
		desc= "Ich nutze meine freie Zeit um mit Comii im Alltag zu unterstützen" 
	/>
      </div>
    </template>

    <our-help-details :detailsData="data[0]" v-else />
  </div>
</template>

<script>
import Card1 from "../../components/Card1.vue";
import OurHelpDetails from "./our-help-details.vue";
import {data} from "../../data/data"

export default {
  data() {
    return {
      data,
      helpDetails:[
        {
          image:"old-black-man.png",
          age:'65 Ans',
          name:"Vincent",
          occupation:'Hausmann'
        }
      ]
    };
  },
  components: { Card1, OurHelpDetails },

  computed: {
    detailsId() {
      return this.$route.query.details;
    },
  },
};
</script>

<style lang="scss" scoped></style>
