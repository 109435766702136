export var data = [
  {
    image: "IMG_3206.png",
    age: "48 Jahre",
    name: "Florian",
    occupation: "Alltagshelfer",
    desc: "Ich betreue seit 3 Jahren eine 92 Jahre alte Dame",
    warum:
      "Weil wir alle älter werden, und genau da braucht es zuverlässige Hilfe!",
    wie: "Gerda S. aus Mössingen ist vor 3 Jahren aus Hessen in ein betreutes Wohnen gekommen. Dort fragte sie nach jemandem, der Sie pro Woche ca. 30 Minuten unterstützen könnte. Durch Zufall haben wir uns kennen gelernt, seitdem helfe ich Ihr.",
    was: "Ich organisiere jede Woche die Post und helfe bei Überweisungen und im Schriftverkehr. Zudem kläre ich telefonisch bei Rückfragen der Sozialstation, des Sozialversicherungsträgers und der Krankenkasse. Gerda S. hat einen Sohn – dieser lebt jedoch so weit entfernt, dass er nicht spontan helfen kann.",
  },
  {
    image: "IMG_4667.jpg",
    age: "28 Jahre",
    name: "Eme",
    occupation: "Krankenpfleger",
    desc: "In meiner Freizeit helfe ich Senioren mit Comii",
    warum:
      "Weil ich meinen Beruf als Krankenpfleger liebe und in meiner Freizeit (Schichtdienst) gerne spontan noch anderen helfe. Der Hinzuverdienst hilft mir, aber es macht einfach auch Spass!",
    wie: "Ein Freund hat mir die Comii App als Testversion vorgestellt. Ich war sofort begeistert, da ich dann helfen kann, wenn ich Zeit habe und oft habe ich wegen meiner Schichttätigkeit etwas mehr Zeit – und diese nutze ich.",
    was: "Ich habe mich nur bei bestimmten Kategorien angemeldet. Damit mache ich genau das, was ich am besten kann: Ich helfe im Haushalt und der leichten Pflege (Waschen, Anziehen, spazieren gehen, Besorgungen,…).",
  },
  {
    image: "IMG_4668.jpg",
    age: "25 Jahre",
    name: "Joy",
    occupation: "Pflegekraft",
    desc: "Mit Comii kann ich spontan helfen und gutes tun",
    warum:
      "Ich habe in der Pflege gelernt. Allerdings arbeite ich wegen den Rahmenbedingungen Nicht mehr in meinem Beruf. Da mir der Beruf jedoch so viel Spass macht, helfe ich jetzt in meiner Freizeit.",
    wie: "Ich hatte mich für den Beruf des Alltagshelfers schon immer interessiert. Allerdings war es nie so leicht Senioren zu finden, die nur sporadisch Hilfe benötigten. Dann habe ich auf Insta Comii entdeckt. Seitdem teste ich Comii und freue mich, wenn die App in Kürze für alle verfügbar gemacht wird. Mit Comii kann ich dann Geld verdienen und Gutes tun, wenn ich Zeit habe.",
    was: "Ich gehe einkaufen, Rezepte besorgen, kümmere mich etwas um den Haushalt. Eigentlich mache ich alle gerne und die Senioren sind dankbar, dass schnell Hilfe kommt.",
  },
  {
    image: "iStock-1198560607_comii.jpg",
    name: "Sylvia",
    age: "42 Jahre",
    occupation: "Pflegefachkraft ",
    desc: "Ich nutze meine freie Zeit um mit Comii im Alltag zu unterstützen",
    warum:
      "Meine Eltern leben leider sehr weit weg von mir. Früher gab es den (fast legendären, regelmässigen) Anruf meiner Mutter: „Kannst Du kurz vorbeikommen? Bei mir müsste man …..“! Diese spontane Hilfe kann ich nicht mehr gewährleisten. Hier in Stuttgart helfe ich, da ich hoffe, dass es auch in der Nähe meiner Mutter noch hilfsbereite Menschen gibt, die sie spontan anfragen kann.",
    wie: "Durch Comii ist mir klar geworden: Da kann ja jeder helfen! Und so ist es für mich sehr einfach spontan und unkompliziert zu helfen und Gutes zu tun. Und klar, ich verdiene dadurch auch etwas Geld nebenher. Ich überlege mir ernsthaft, ob dies nicht ein berufliches Zukunftsmodell für mich ist.",
    was: "Mein erster Einsatz war „Schlüssel suchen“. Nun, zum Glück wurde der Schlüssel einer Schliessanlage recht schnell gefunden und so war die Freude auf beiden Seiten gross. Ansonsten habe ich schon Schnee geräumt, den Garten gemäht und auch die Pflanzen winterfest gemacht. Inzwischen habe ich 3 Senioren, die mich immer wieder anfragen – und ich komme gerne!",
  },
];

export var datacard2 = [
  {
    id: "0",
    colorClass: "green",
    heading: "Helfen macht Spass",
    content:
      "Spontan helfen und dabei Geld verdienen, jeder kann Alltagshelfer werden",
  },
  {
    id: "1",
    colorClass: "purple",
    heading: "Helfen, wann Du Zeit hast",
    content: "Nur wenn Du Comii offen hast, bist Du sichtbar!",
  },
  {
    id: "2",
    colorClass: "violet",
    heading: "Mach, was Du kannst",
    content: "Du kannst Deine Servicekategorien selbst bestimmen",
  },
];

export var textblock11 = [
  {
    id: "1",
    content: "Öffne Comii, wenn Du Zeit hast",
  },
  {
    id: "2",
    content: "Schau in den Benachrichtigungen nach aktuellen Aufträgen",
  },
  {
    id: "3",
    content: "Akzeptiere einen Auftrag",
  },
  {
    id: "4",
    content: "Fahre an den Standort des Seniors",
  },
  {
    id: "5",
    content:
      "Starte und Beende den Auftrag in der App und warte bis der Senior die Zahlung in der App an Dich durchgeführt hat",
  },
  {
    id: "6",
    content:
      "Du siehst Deinen Verdienst direkt auf Deinem Stripe Konto in der Comii App",
  },
];
