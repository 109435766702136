<template>
    <div class="Bloc13">
        <div class="arg1">
            <p>{{title}}</p>
        </div>
        <div class="arg2">
            <h1>{{price}}€/Monat</h1>
        </div>
        <div class="arg3">
            <p>(Monatlich kündbar)</p>
        </div>
        <div class="arg4">
            <router-link :to="toUrl">Mehr erfahren</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name:"Block13",

        props:{
            title:{
                type:String, default:"Comii Senior Silber"
            },
            price:{
                type:String, default:"0"
            },

            toUrl:{type:String, default:"#"}
        }
    }
</script>

<style lang="scss" scoped>

</style>
