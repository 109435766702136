<template>
  <footer class="app-footer footer-gray">
    <div
      class="
      lg:px-10 px-2
      content-wrapper page-block sm:justify-between   
       items-start py-8 text-gray-300 
       text-center sm:text-left smaller-text-responsive font-light"
    >

      <div class="grid lg:grid-cols-3 lg:gap-10 gap-0 grid-cols-1  lg:flex-grow justify-between lg:-mb-7
      w-full lg:w-auto flex-col flex-wrap sm:flex-row ">

        <div>
          <!-- mentions légales -->
          <router-link to="/impressum" class="pb-3"
            >Impressum</router-link 
          >
          <!-- declaration protections des donnees -->
          <router-link to="/privacy-statement" class="pb-3"
            >Datenschutzerklärung</router-link
          >
        </div>

        <div>
          <!-- conditions d'utilisation -->
          <router-link to="/conditions-senior" class="pb-3"
            >Nutzungsbedingungen Senioren APP</router-link
          >
          <!-- cookies -->
          <router-link to="/cookies-policy" class="pb-3"
            >Cookies Richtlinie</router-link
          >
        </div>
        
        <!-- <router-link to="/" class="pb-3">FAQ</router-link> -->
        <div class="pb-3">
          <!-- conditions d'utilisation -->
          <router-link to="/conditions-everydayhelpers" class="pb-3"
            >Nutzungsbedingungen Alltagshelfer APP</router-link
          >
          <div>2021 Comii. Alle Inhalte sind rechtlich geschützt</div>
        </div>

      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    colorClass: { type: String, default: "header-orange" },
  },

  computed: {
    currentColor() {
      switch (this.$store.getters.colorClass) {
        case "orange":
          return "header-orange";
        case "purple":
          return "header-purple";
        default:
          return "header-green";
      }
    },
  },
};
</script>
