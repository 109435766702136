<template>
  <div
    class="page-block block-padding"
    style="word-wrap: break-word; margin-top: 40px"
  >
    <h1 align="center" style="color: #2f5496">Allgemeine Nutzungsbedingungen</h1>
    <h1 align="center" style="color: #2f5496">Comii Senior APP - Deutschland</h1>
    <h2 style="color: #2f5496">Inhalt</h2>
    <p>
      <a
        href="#_Toc82156888"
      >
        WICHTIGES ÜBER Comii IM ÜBERBLICK
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156889"
      >
        ÜBER UNS
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156890"
      >
        DEFINITIONEN
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156891"
      >
        BEDINGUNGEN
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156892"
      >
        1. Überblick
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156893"
      >
        2. Comii App Dienstleistungen
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156894"
      >
        3. Drittleistungen
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156895"
      >
        5. Registrierung und Nutzung der Comii App
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156896"
      >
        6. Ihre Pflichten
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156897"
      >
        7. Kosten und Zahlungen
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156898"
      >
        8. Stornierung
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156899"
      >
        9. Lizenz
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156900"
      >
        10. Geistiges Eigentum
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156901"
      >
        11. Datenschutz
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156902"
      >
        12. Ihre Haftung
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156903"
      >
        13. Verfügbarkeit
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156904"
      >
        14. Haftung und Haftungsbegrenzung
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156905"
      >
        15. Kündigung und Beendigung
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156906"
      >
        16. Änderungen von Bedingungen
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156907"
      >
        17. Verfahren zur Streitbeilegung
      </a>
    </p>
    <p>
      <a
        href="#_Toc82156908"
      >
        18. Anwendbares Recht und Gerichtsstand
      </a>
    </p>
    <h1>
      <a name="_Toc82156888">WICHTIGES ÜBER Comii IM ÜBERBLICK</a>
    </h1>
    <p>
      Diese Bedingungen finden auf die Nutzung der Comii Senior App und/oder
      Comii Webseiten in Deutschland Anwendung.
    </p>
    <p>
      Die App-/Webseiten-Dienstleistungen ermöglichen es Ihnen, Dienstleistungen
      von Drittanbietern über Comii anzufordern.
    </p>
    <p>
      Für Dienstleistungen von Dritten schliessen Sie Verträge direkt mit den
      Drittanbietern ab, bei denen Comii (Eine Marke der Digital Suxess AG,
      Stuttgart) als Vermittler agiert. Dies ermöglicht Ihnen diese
      Dienstleistungen anzufordern, zu bestätigen und zu beziehen.
    </p>
    <p>
      Für die Nutzung der Comii-Dienstleistungen kann die Annahme weiterer
      Bedingungen von Dritten erforderlich sein, die ihre Nutzung bei der
      Erbringung deren Drittleistung regeln.
    </p>
    <p>
      Bitte lesen Sie diese Bedingungen aufmerksam. Sie enthalten Informationen
      über Ihre Rechte, zur Nutzung der App, die Inanspruchnahme von
      Drittleistungen sowie weitere andere wichtige Informationen.
    </p>
    <h1>
      <a name="_Toc82156889">ÜBER UNS</a>
    </h1>
    <p>
      Ihr Vertragspartner zur Nutzung der Comii App ist die Digital Suxess AG,
      Stuttgart, eine in Deutschland errichtete Aktiengesellschaft, eingetragen
      beim Amtsgericht Stuttgart unter der Nummer HRB 778492 mit Sitz in
      Löffelstr. 44, 70597 Stuttgart (nachfolgend „Comii“, “wir” oder “uns”).
      Aufsichtsrat Dr. Rainer Schultheiss, Vorstand Florian Buschbacher. Sie
      können uns unter info@comii.de kontaktieren.
    </p>
    <p>
      Unter der Marke Comii bietet die Digital Suxess 
      AG eine Plattform für Alltagshelfer, welche Dienstleistungen 
      im Umfeld der Alltagshilfe erbringen. Diese Dienstleistungen, 
      werden nicht durch Comii selbst erbracht, sondern werden durch 
      unabhängige Dritte – genannt Alltagshelfer - geleistet.
      Bei diesen Dritten handelt es sich entweder um gewerbliche tätige Personen
      (Hausmeister, mobile Pflegedienste, etc.) oder um Privatpersonen (selbständige) mit einer
      gewerblichen Anmeldung als Kleinunternehmer (gem. §19 UstG). Comii selbst
      wird nicht als Alltagshelfer tätig und gibt auch kein entsprechendes
      Angebot zur Erbringung einer solchen Leistung ab. Comii wird
      ausschliesslich als Anbieter einer Vermittlungsdienstleistung tätig, indem
      auf elektronischem Weg an einer Alltagshilfe interessierte Nutzer (Sie)
      mit Alltagshelfern verbunden werden und so das Zustandekommen eines
      Dienstleistungsvertrages zwischen diesen Nutzern (Ihnen) und diesen
      Drittanbietern vermittelt wird. Zwischen Comii und Ihnen als Nutzer kommt
      kein Vertrag zur Alltagshilfe zu Stande.
    </p>
    <p>
      Zur Nutzung unserer Vermittlungsplattform Comii schliessen Sie als Comii
      App Nutzer einen Abovertrag über den jeweiligen App Store (Google Play
      Store/Apple App Store) ab. Parallel werden Verträge zwischen den
      Alltagshelfern und Comii insoweit geschlossen, als dass Comii die
      Rechnungsstellung und weiterer Leistungen für diese Alltagshelfer (Dritte)
      übernimmt.
    </p>
    <h1>
      <a name="_Toc82156890">DEFINITIONEN</a>
    </h1>
    <p>
      “Senioren Richtlinie” meint die jeweils aktuelle Richtlinie, die
      allgemeinen Verhaltensregeln aller Senioren, welche die Comii App nutzen
      und Hilfe beanspruchen wollen. Die aktuelle Fassung der Senioren
      Richtlinie kann unter comii.de eingesehen werden.
    </p>
    <p>
      “Alltagshelfer Richtlinie” meint die jeweils aktuelle Richtlinie, die
      allgemeinen Verhaltensregeln aller Alltagshelfer, welche die Comii App
      nutzen und als Alltagshelfer für Sie tätig werden. Die aktuelle Fassung
      der Alltagshelfer Richtlinie kann hier comii.de eingesehen werden.
    </p>
    <p>
      “Drittanbieter” sind von Comii unabhängige Dritte und umfassen,
      insbesondere unabhängige Alltagshelfer, mobile Pflegedienste, Handwerker,
      Hausmeister oder Anbieter anderer Dienstleistungen im Leistungsspektrum
      von Comii.
    </p>
    <p>
      “Drittleistungen” umfassen insbesondere Leistungen in den Bereichen
      Alltagsunterstützung, die Ihnen von Drittanbietern erbracht werden, wie
      z.B. putzen, kochen, waschen, Wäsche waschen, Blumen giessen, Hunde
      ausführen, Rezepte in der Apotheke einlösen, kurze Fahrdienste zum Arzt
      oder kleinere Besorgungen des Alltages. Diese werden Ihnen durch die
      Nutzung der Comii App zugänglich gemacht.
    </p>
    <h1>
      <a name="_Toc82156891">BEDINGUNGEN</a>
    </h1>
    <h2>
      <a name="_Toc82156892">1. Überblick</a>
    </h2>
    <p>
      1.1. Diese Bedingungen regeln Ihre Registrierung, Ihren Zugang und Ihre
      Nutzung unserer Comii App (“Bedingungen”). Sie sollten die Bedingungen
      sorgfältig lesen und müssen diesen zustimmen, bevor Sie auf unsere Comii
      App zugreifen oder diese nutzen wollen. Mit Ihrer Zustimmung zur Geltung
      dieser Bedingungen im Rahmen Ihrer Registrierung und der Bestätigung Ihrer
      Registrierung durch uns, wird eine vertragliche Beziehung zwischen Ihnen
      und der Digital Suxess AG zur Nutzung der Comii App begründet. Wenn Sie
      diesen Bedingungen nicht zustimmen, erhalten Sie keinen Zugang zu unseren
      Dienstleistungen der Comii App und können diese nicht nutzen.
    </p>
    <p>
      1.2. Comii handelt als Vermittler für die Drittanbieter und deren
      Leistungen sowie als Dienstleister für die verwaltungstechnischen Vorgänge
      wie Rechnungserstellung (Alltagshelfer an Senior) und Zahlungseinzug nach
      erbrachter Dienstleistung (Drittleistung). Dabei übernimmt Comii kein
      Inkassorisiko oder wird als Factoring Unternehmen tätig. Das
      Zahlungsausfallrisiko trägt stets der Alltagshelfer.
    </p>
    <h2>
      <a name="_Toc82156893">2. Comii App Dienstleistungen</a>
    </h2>
    <p>
      2.1. Comii erbringt Ihnen gegenüber Dienstleistungen, die folgendes
      umfassen:
    </p>
    <p>
      2.1.1. Zugang und Nutzung der Comii Senior App und Comii Webseiten, die es
      Ihnen ermöglichen die Drittleistungen anzufordern und in Anspruch zu
      nehmen; und
    </p>
    <p>
      2.1.2. Dienstleistungen betreffend der Entgegennahme und Durchführung von
      Zahlungen (inklusive der Erstellung von Rechnungen und Belegen im Namen
      der Drittanbieter), die es uns ermöglichen, Beträge bei Ihnen einzuziehen
      und - im Zusammenhang mit Drittleistungen - auf die Bankkonten der
      Drittanbieter weiterzuleiten (Comii kann hierbei verbundene Unternehmen
      oder Dritte beauftragen, diese Dienstleistungen in ihrem Namen zu
      erbringen).
    </p>
    <p>
      2.2. Diese Bedingungen finden auf Ihre Nutzung der
      App-/Webseiten-Dienstleistungen in Deutschland Anwendung.
    </p>
    <p>
      2.3. Dort wo verfügbar, können Sie Drittleistungen unter Nutzung der Comii
      App anfordern und erhalten. Comii kann nicht dafür garantieren, dass eine
      flächendeckende Versorgung mit Alltagshelfern gewährleistet ist. Ebenso
      kann Comii nicht dafür gewährleisten, dass Alltagshelfer innerhalb einer
      bestimmten Zeit bei Ihnen eintreffen. Comii wird jedoch alles unternehmen,
      um eine möglichst umfangreiche und zeitgerechte Versorgung zu
      gewährleisten. Hierfür setzen wir permanent Kennzahlen zur
      Nutzungsstatistik ein. Nähere erfahren Sie in unserer
      Datenschutzrichtlinie die Sie unter comii.de finden.
    </p>
    <h2>
      <a name="_Toc82156894">3. Drittleistungen</a>
    </h2>
    <p>
      3.1. Für Drittleistungen handelt Comii als nach aussen entsprechend
      erkennbarer und benannter Vermittler zwischen Ihnen und dem jeweiligen
      Drittanbieter. Comii erbringt die Drittleistungen nicht selbst. Alle
      Drittanbieter sind unabhängige dritte Auftragnehmer, Unternehmer oder
      sonstige juristische Personen, die nicht bei Comii angestellt sind und
      nicht als Comii Subunternehmer, Beauftragte oder im Auftrag von Comii
      handeln.
    </p>
    <p>
      3.2. Wenn Sie mit Comii online sind (Comii Senior App) und in der Nähe von
      Drittanbietern (Alltagshelfer) eingeloggt sind, die ebenfalls in der Comii
      APP (Comii Helfer App) eingeloggt sind, erhalten Sie auf Comii
      Informationen über die Anzahl aller verfügbaren Helfer. Sie haben die
      Möglichkeit einen konkreten Alltagshelfer oder alle Alltagshelfer
      anzufragen. Bei der Anforderung eines Alltagshelfers wird derjenige
      Alltagshelfer zu Ihnen kommen, der sich in Ihrer Nähe befindet und Ihren
      Auftrag zuerst bestätigt. Grund ist, dass Comii schnell, unkompliziert und
      kurzfristig Unterstützung im Alltag bereitstellen will.
    </p>
    <p>
      3.3. Verfügbare Alltagshelfer können sich frei entscheiden, eine Anfrage
      anzunehmen oder abzulehnen. Die Annahme einer Anfrage durch einen
      Alltagshelfer wird Ihnen über die Comii Senior App angezeigt. Damit ist
      der Vertrag zwischen Ihnen und dem Alltagshelfer für die Drittleistung
      begründet.
    </p>
    <p>
      3.4. Für manche Drittleistungen ist es gegebenenfalls erforderlich, dass
      Sie gesonderten Vertragsbedingungen zustimmen, um die jeweilige
      Drittleistung in Anspruch nehmen zu können.
    </p>
    <p>
      3.5. Sie werden nach jedem Auftrag gebeten, Ihren Alltagshelfer zu
      bewerten. Die Alltagshelfer werden ebenso gebeten, den Auftrag mit Ihnen
      zu bewerten. Alltagshelfer und Senioren können jeweils die Bewertungen
      einsehen. Des Weiteren können diese Bewertungen entscheidungserheblich
      dafür sein, ob Drittanbieter Drittleistungen für Sie weiter erbringen oder
      nicht. Für zusätzliche Informationen zu den Bewertungen lesen Sie bitte
      unsere Senioren bzw. Alltagshelfer Richtlinien die Sie unter comii.de
      finden.
    </p>
    <p>
      3.6. Der Alltagshelfer ist für sämtliche Verpflichtungen verantwortlich,
      die aus der Erbringung der vermittelten Drittleistungen entstehen.
    </p>
    <h2>
      <a name="_Toc82156895">
        5. Registrierung und Nutzung der Comii App
      </a>
    </h2>
    <p>
      5.1. Die Comii App kann auf den meisten modernen, mit den verbreiteten
      Betriebssystemen wie Android oder iOS ausgestatteten Mobilgeräten mit
      Internetzugang und GPS Funktion heruntergeladen und genutzt werden.
    </p>
    <p>
      5.2. Für den Zugang zum Internet als Voraussetzung für die Nutzung der
      Comii APP sind Sie selbst verantwortlich, ebenso wie für
      sämtliche Kosten und Gebühren Ihres Mobilfunknetzbetreibers,
      einschliesslich des Datenverbrauchs bei Nutzung unserer App. Sie sind auch
      für den Zugang zu kompatiblen Geräten und Betriebssystemen verantwortlich,
      die für die Nutzung der App erforderlich sind, inklusive sämtlicher
      zukünftiger Software- oder Hardware-Updates.
    </p>
    <p>
      5.3. Zur Nutzung der Comii Senior App müssen Sie sich in der Comii App
      registrieren und einen Account erstellen. Eingabefehler können Sie hierbei
      jederzeit über die entsprechende Funktionalität in der App, insbesondere im
      Bereich „Mein Profil“, korrigieren. Zum Zeitpunkt der Registrierung müssen
      Sie mindestens achtzehn (18) Jahre alt sein.
    </p>
    <p>
      5.4. Zur Registrierung müssen Sie uns bestimmte persönliche Informationen
      inklusive Ihrer Adresse, Alter, Geschlecht, Telefonnummer und
      E-Mail-Adresse sowie weitere zur Nutzung bestimmter Angaben mitteilen,
      damit wir später Ihre Anfragen und Aufträge an geeignete Alltagshelfer
      vermitteln können. Um Comii nutzen zu können, ist zwingend erforderlich,
      dass Sie bei Verwendung der App Ihre GPS bzw. Geoposition freischalten.
      Nur so ist es Comii möglich in einem definierten Geozaun von ca. 10 km
      entsprechende Alltagshelfer zu finden. Zudem benötigen wir u.a. Ihre
      Bankverbindungs- und Kontoinformationen zur Erstellung eines SEPA
      Lastschriftmandats, damit wir die Beträge für die Drittleistung der
      Alltagshelfer einziehen können. Details zur Nutzung Ihrer
      personenbezogenen Daten und den Gründen für diese Erhebung verweisen wir
      auf unsere Datenschutzrichtlinie unter comii.de.
    </p>
    <p>
      Es ist möglich, das vor der Nutzung spezifische ergänzende Informationen
      wie bspw. ein Selbstbild zur Identifikation, eine Kopie des Personalausweises
      oder ähnliche Daten angefordert werden.
    </p>
    <p>
      5.5. Weder Sie noch die Drittanbieter haben die Verpflichtung, sich bei
      Comii einzuloggen oder unsere Dienstleistungen zu nutzen. Wenn Sie sich
      entscheiden, die Nutzung von Comii zu beenden, so können Sie dies
      jederzeit anhand des gewählten Abovertrages in den jeweiligen App Stores
      durchführen.
    </p>
    <p>
      5.6. Wir können Ihren Zugang zu und die Nutzung der App vorübergehend oder
      dauerhaft einschränken oder sperren, wenn konkrete Anhaltspunkte die
      Annahme rechtfertigen, dass es zu einer von Ihnen zu vertretenen
      Verletzung Ihrer Pflichten oder einem Verstoss gegen diese Bedingungen
      gekommen ist (beispielsweise, wenn wir eine Beschwerde zu einem
      hinreichend konkreten Betrugsvorwurf erhalten). Dasselbe gilt, wenn wir
      etwaige von Ihnen durch die Inanspruchnahme von Drittleistungen
      verursachte Kosten in nicht unerheblicher Höhe nicht mit der von Ihnen in
      Ihrem Account hinterlegten Zahlungsmethode abrechnen können. Es können
      Umstände vorliegen, unter denen es uns nicht gestattet ist, Sie über die
      Beschwerde zu informieren, solange die Ermittlungen (durch uns und/oder
      Dritte, wie z.B. die Polizei) hierzu noch laufen. Bei der Entscheidung
      über die Einschränkung oder Sperrung werden wir Ihre berechtigten
      Interessen angemessen berücksichtigen und die Einschränkung und Sperrung
      entsprechend wieder aufheben, wenn die Gründe hierfür wegfallen (z.B.,
      wenn die oben genannten Zahlungen erfolgt sind).
    </p>
    <h2>
      <a name="_Toc82156896">6. Ihre Pflichten</a>
    </h2>
    <p>
      6.1. Sie dürfen bei der Nutzung bzw. unter Ausnutzung von Informationen,
      die Sie über Ihre Dienstleistung oder der Comii App erhalten haben, nicht
      gegen anwendbare Gesetze oder sonstige Rechtsvorschriften verstossen. Sie
      dürfen die App nur für rechtmässige Zwecke und solche Zwecke verwenden, für
      die sie bestimmt sind. Sie dürfen die Comii App nicht missbräuchlich
      nutzen oder versuchen, uns oder Drittanbieter (z.B. in betrügerischer
      Absicht) zu schädigen. Für weitere Informationen zu den allgemeinen
      Verhaltensregeln beachten Sie bitte unsere Alltagshelfer und Senioren
      Richtlinien unter comii.de.
    </p>
    <p>
      6.2. Bei Anlegen eines Accounts und bei der Nutzung der App müssen Sie
      korrekte und vollständige Informationen angeben. Wir behalten uns das
      Recht zur Überprüfung Ihrer Angaben vor.
    </p>
    <p>
      6.3. Ihr Account ist nur für Sie bestimmt. Er kann nicht unterlizenziert
      oder geteilt werden, es sei denn, dies wird ausdrücklich von uns
      gestattet. Sobald Sie einen Account eingerichtet haben:
    </p>
    <p>§ dürfen Sie sich nicht für einen zusätzlichen Account registrieren;</p>
    <p>
      § dürfen Sie Dritten die Nutzung Ihres Accounts nicht erlauben und Ihren
      Account nicht auf Dritte übertragen;
    </p>
    <p>
      § müssen Sie Ihre erforderlichen Account-Informationen korrekt,
      vollständig und auf dem aktuellen Stand halten;
    </p>
    <p>§ müssen Sie Ihre Login-Angaben zu jeder Zeit vertraulich halten und</p>
    <p>
      § müssen Sie uns unverzüglich mitteilen, wenn Sie aus irgendeinem Grund
      den Verdacht haben, dass jemand anderes Ihren Benutzernamen oder Ihr
      Passwort kennt oder dass jemand anderes Ihren Account nutzt.
    </p>
    <p>
      6.4. Sie sind für sämtliche Gebühren oder Kosten verantwortlich, die durch
      die Inanspruchnahme von Drittleistungen entstehen, die über Ihren Account
      angefragt, beauftragt oder storniert wurden, sofern Sie die entsprechende
      Nutzung des Accounts zu vertreten haben oder diese Ihnen in sonstiger
      Weise zugerechnet werden kann.
    </p>
    <p>
      6.5. Bei Nutzung der Comii Senioren App -Dienstleistungen ist es nicht
      gestattet, Alltagshelfer (Drittanbieter), Comii oder sonstige Dritte zu
      belästigen, in deren Rechten zu beeinträchtigen oder Schäden an deren
      Eigentum zu verursachen.
    </p>
    <h2>
      <a name="_Toc82156897">7. Kosten und Zahlungen</a>
    </h2>
    <p>7.1. Kosten für die Nutzung der Comii App</p>
    <p>
      7.1.1. Die Nutzung der App ist kostenpflichtig. Die jeweils aktuellen 
      Kosten finden Sie in den jeweiligen App-Stores und auf unserer Webseite 
      comii.de. Wir behalten uns jedoch vor, Gebühren zu senken oder zu erhöhen, 
      worüber Sie in diesem Fall in Textform (ggf. über die App oder dem jeweiligen 
      App Store) informiert werden und wobei Sie die Gelegenheit haben werden, 
      das Vertragsverhältnis zu kündigen, bevor eine solche Gebühr eingeführt 
      wird. Wenn Sie die Kündigung wählen, werden Sie keinen Zugang zu den App 
      Dienstleistungen mehr haben und diese nicht mehr nutzen können.
    </p>
    <p>
      7.1.2. Wir behalten uns das Recht vor, eine Gebühr für bestimmte Elemente,
      sog. „In App Kauf“, einzuführen. Über die Einführung, Verfügbarkeit und den
      damit verbundenen Kosten informieren wir stets im Voraus.
    </p>
    <p>7.2. Kosten für Drittleistungen</p>
    <p>
      7.2.1. Wenn Sie eine Anfrage über Comii abgeben, erklären Sie sich damit
      einverstanden, für die entsprechenden Kosten für die vermittelten und
      erbrachten Drittleistungen zu zahlen und sind für alle Kosten oder
      Gebühren verantwortlich, die in Verbindung mit Ihrem Account stehen.
    </p>
    <p>
      7.2.2. Die Kosten werden von jedem Alltagshelfer selbst definiert. Wir empfehlen 25 Euro/Stunde.
      Die ersten 30 Minuten werden unabhängig von der konkreten Dauer immer fällig.
      Wie lange der Alltagshelfer vor Ort beschäftigt ist, ist eine Absprache
      mit dem Alltagshelfer vor Ort. Nach 30 Minuten wird im 15 Minuten-Takt
      abgerechnet. Die Zeiterfassung erfolgt dabei über Statusmeldungen in der
      App, so dass Sie in Ihrer Abrechnung, die für jeden Auftrag/Dienstleistung
      separat erstellt und in Ihrem Profil zugänglich gemacht wird und im Detail
      für Sie einsehbar ist. Somit kann Comii keine genaue Kostenschätzung
      abgeben und Sie müssen sich mit dem Alltagshelfer vor Ort über den
      zeitlichen und inhaltlichen Aspekt abstimmen. Werden Sie von einem freiwilligen
      Alltagshelfer untersützt, so fallen keine Gebühren für den Alltagshelfer an.
      In diesem Falle erheben wir jedoch eine Transaktionsgebühr in Höhe von 2,50 Euro, 
      die Sie in der App, über unsere integrierte Stripe Zahlungsplattform, bezahlen.
    </p>
    <p>
      7.2.3. Im Rahmen der Inanspruchnahme von Drittleistungen können ggf.
      Schäden auftreten. Diese sind unverzüglich an den Alltagshelfer zu melden.
      Jeder Alltagshelfer hat Comii gegenüber bestätigt, dass eine
      Haftpflichtversicherung existiert. Der Alltagshelfer ist verpflichtet
      diese Schadensmeldung unverzüglich mit seinem Versicherer zu klären.
    </p>
    <p>
      7.2.4. Für die Fälle, in denen Drittleistungen aufgrund eines von Ihnen zu
      vertretenden Handelns oder Unterlassens nicht erbracht werden können (zum
      Beispiel, wenn Sie nicht zur vereinbarten Zeit am vereinbarten Ort
      anwesend sind, um eine Dienstleistung entgegenzunehmen), sind jedoch die
      ersten 30 Minuten zu bezahlen. Jeder Helfer ist verpflichtet mindestens 10
      Minuten auf den Senior zu warten.
    </p>
    <p>
      7.2.5. Sobald die Drittleistung erbracht wurde, erhalten Sie eine Rechnung
      über die erbrachten Drittleistung. Sie sind verpflichtet diese Rechnung
      direkt nach Auftragsende über die Comii App zu bezahlen. Soweit anwendbar, 
      wird die geltende Umsatzsteuer in der Rechnung ausgewiesen.
    </p>
    <p>7.3. Allgemeines</p>
    <p>
      7.3.1. Für mehr Informationen über Kosten im Allgemeinen, etwaige
      Zusatzkosten, Gebühren und/oder pauschalen Schadensersatz, die im
      Zusammenhang mit den entsprechenden Drittleistungen anfallen, greifen Sie
      bitte auf unsere FAQs zurück die Sie auf Comii.de finden.
    </p>
    <p>
      7.3.2. Die Preise können mit Wirkung für die Zukunft in der Comii App von
      Zeit zu Zeit geändert werden.
    </p>
    <p>
      7.3.3. Die Kosten beinhalten keine Trinkgelder. Sie können Trinkgelder
      persönlich geben.
    </p>
    <p>
      7.3.4. Sofern nicht anders vereinbart, sind sämtliche Kosten nach 
      Erledigung durch den Drittanbieter fällig. Hierzu erhalten Sie direkt 
      nach Abschluss der Hilfe eine Rechnung in Ihrem persönlichen Bereich. 
      Sie verpflichten sich diesen Betrag über die integrierte Zahlungsabwicklung 
      direkt zu begleichen. Hierzu stehen Ihnen entweder Kreditkartenzahlungen 
      oder SEPA Überweisungen zur Verfügung. Unsere Rechnung an Sie 
      (vom Alltagshelfer aber durch uns erzeugt) enthält Angaben zum 
      Alltagshelfer, der ausgewählten Dienstleistung, der entsprechend 
      geleisteten Zeit sowie der kalkulierten Kosten. Rechnungen werden 
      im Login Bereich Ihres Profils zum Abruf bereitgehalten. Sie sind 
      damit einverstanden, dass Rechnungen nur in elektronischer Form verfügbar 
      gemacht werden. Wenn die Zahlung über die von Ihnen ausgewählte 
      Zahlungsmethode nicht getätigt werden kann, können wir - sofern
      vorhanden - auf weitere in Ihrem Account hinterlegte Zahlungsmethoden 
      zugreifen, um die Zahlung der Kosten zu veranlassen. Wenn die von 
      Ihnen hinterlegte(n) Zahlungsmethode(n) nicht belastbar ist/sind, 
      behalten wir uns das Recht vor, in angemessenem Abstand weitere 
      Versuche zur Einziehung des geschuldeten Betrags vorzunehmen. 
      Für fehlgeschlagene Zahlungen oder Rückbuchungen wird eine 
      Bearbeitungsgebühr von 25 Euro erhoben. 
    </p>
    <p>
      7.3.5. Die Kosten und Gebühren beinhalten alle anwendbaren Steuern,
      inklusive Umsatzsteuer.
    </p>
    <h2>
      <a name="_Toc82156898">8. Stornierung</a>
    </h2>
    <p>
      8.1. In manchen Fällen ist es nicht möglich, eine Anfrage für
      Drittleistungen zu stornieren, nachdem diese angenommen wurde. Für
      bestimmte Drittleistungen können die Drittanbieter es gestatten, eine
      Anfrage zu stornieren. In diesen Fällen kann Ihnen jedoch die vor der
      Stornierung angezeigte Stornogebühr in Höhe von 9,50 Euro von Comii in
      Rechnung gestellt werden.
    </p>
    <p>
      8.2. Comii und Drittanbieter können Anfragen ablehnen und die
      Drittleistungen stornieren, wenn konkrete Anhaltspunkte hinsichtlich der
      Korrektheit oder Authentizität der Anfrage oder der Kontaktinformation es
      rechtfertigen.
    </p>
    <h2>
      <a name="_Toc82156899">9. Lizenz</a>
    </h2>
    <p>
      Unter der Voraussetzung der Einhaltung dieser Bedingungen, gewähren wir
      Ihnen eine persönliche, deutschlandweite, nicht exklusive, nicht
      übertragbare, widerrufbare, nicht unterlizenzierbare Lizenz, Comii
      ausschliesslich für Ihre Nutzung und für Ihren Zugang auf Ihrem Gerät zu
      installieren und zu nutzen, und die über Comii zugänglich gemachten
      Informationen zu nutzen. Sämtliche hierin nicht ausdrücklich gewährten
      Rechte bleiben Comii vorbehalten.
    </p>
    <h2>
      <a name="_Toc82156900">10. Geistiges Eigentum</a>
    </h2>
    <p>
      Wir behalten uns alle Ihnen nicht ausdrücklich mit diesen Bedingungen
      gewährten Rechte vor. Unsere App und alle über Comii erfassten, nicht
      personenbezogenen Daten (inklusive aller Rechte des geistigen Eigentums)
      sind und verbleiben bei uns und unseren Lizenzgebern. Es ist Ihnen nicht
      gestattet und Sie dürfen es auch Dritten nicht gestatten: (a) Comii oder
      Teile hiervon unterzulizenzieren, zu kopieren, zu verändern, zu
      vertreiben, zu verkaufen, weiterzuverkaufen, zu übertragen oder zu
      vermieten; (b) auf andere als gesetzlich erlaubte Weise den Quellcode von
      Comii zu verändern oder zu entwickeln oder versuchen, den Quellcode von
      Comii auszulesen; (c) Programme oder Skripts zum Zwecke des Data-Minings,
      der Indexierung, der Überwachung oder anderweitiger Datengewinnung von
      jeglichen Teilen von Comii Daten einzusetzen oder die Einsetzung zu
      veranlassen; (d) jegliche Schutzmarken oder Werke für andere Zwecke als
      für die Nutzung von Comii zu nutzen, darzustellen oder zu manipulieren;
      (e) (i) Unternehmungen, (ii) URLs, (iii) Domainnamen, (iv)
      Softwareanwendungen, -namen oder -titel oder (v) Social-Media-Profile zu
      erstellen oder erstellen zu lassen oder sich mit Nutzernamen zu
      registrieren, die Comii-Namen, -Marken, oder -Werke oder mit diesen
      verwechselbare Marken, Namen, Titel oder Werke enthalten; (f) Comii-Namen,
      -Marken, oder -Werke als Ihr Profil- oder Hintergrundbild in sozialen
      Netzwerken zu nutzen; (g) Keywords (insbesondere bei Google Ads) zu
      buchen, die Comii-Namen, -Marken, oder -Werke enthalten; oder (h) für
      Comii Namen, Marken, oder Werke in irgendeiner Weise und unabhängig vom
      Zweck, eine Registereintragung zu beantragen, zu behaupten Eigentümer zu
      sein und/oder diese zu kopieren. Dies gilt auch für verwechselbare oder im
      Wesentlichen ähnliche Marken, Namen, Titel, oder Werke, unabhängig davon,
      ob diese alleinstehend oder zusammen mit anderen Buchstaben, anderer
      Zeichensetzung, weiteren Wörtern, Symbolen, Designs, und/oder
      irgendwelchen kreativen Werken verwendet werden; aber nur insofern, als
      dass diese Handlungen über den Umfang, der Ihnen in diesen Bedingungen
      gewährten beschränkten Lizenz hinausgehen.
    </p>
    <h2>
      <a name="_Toc82156901">11. Datenschutz</a>
    </h2>
    <p>
      Wir verarbeiten Ihre personenbezogenen Daten wie in unserer
      Datenschutzerklärung auf comii.de näher beschrieben. Auf Comii
      Dienstleistungen und Drittleistungen können ergänzende
      Datenschutzbestimmungen, inklusive solcher von Dritten Verantwortlichen,
      Anwendung finden.
    </p>
    <h2>
      <a name="_Toc82156902">12. Ihre Haftung</a>
    </h2>
    <p>
      Sie haften uns gegenüber für aufgrund eines von Ihnen zu vertretenden
      Verstosses gegen diese Bedingungen oder Missbrauchs der
      Comii App, eines von Ihnen begangenen schuldhaften
      Gesetzesverstosses oder schuldhaften Verstosses gegen Rechte Dritter für
      seitens Comii erlittene Schäden entsprechend den einschlägigen
      gesetzlichen Bestimmungen. Sie haften für alle Aktivitäten, die über Ihren
      Account getätigt werden, sofern sie solche Aktivitäten gestattet oder auf
      andere Weise zu vertreten haben.
    </p>
    <h2>
      <a name="_Toc82156903">13. Verfügbarkeit</a>
    </h2>
    <p>
      Es ist uns ein besonderes Anliegen, die App möglichst ohne Beschränkungen,
      Verzögerungen oder andere Probleme zur Verfügung zu stellen. Die
      Comii App könnte allerdings insbesondere von technisch
      bedingten Beschränkungen, Verzögerungen und/oder anderen Problemen
      betroffen sein, auf die wir keinen Einfluss haben und die für das Internet
      und die elektronische/digitale Kommunikation charakteristisch sind. Daher
      ist für Comii von uns keine bestimmte
      Mindestverfügbarkeit und hinsichtlich etwaiger auftretender
      Beschränkungen, Verzögerungen oder anderen Problemen keine bestimmte
      Reaktions- oder Fehlerbehebungszeit geschuldet.
    </p>
    <h2>
      <a name="_Toc82156904">14. Haftung und Haftungsbegrenzung</a>
    </h2>
    <p>14.1. Keine Verantwortlichkeit für Drittleistungen</p>
    <p>
      Als Vermittler sind wir für die Drittleistungen nicht verantwortlich. Wir
      sind daher nicht verantwortlich für Schäden, einschliesslich Geldbussen oder
      Schadensersatzforderungen Dritter oder für sonstige Verluste, die Ihnen
      aufgrund der Inanspruchnahme von Drittleistungen und des Verhaltens dieser
      Dritten ggf. entstehen. Comii gibt keine Zusicherung und übernimmt
      keinerlei Garantie und/oder Gewährleistung hinsichtlich der Qualität,
      Eignung, Sicherheit und Befähigung sowie Pünktlichkeit und Verlässlichkeit
      von Drittanbietern.
    </p>
    <p>14.2. Haftungsbeschränkung für App-Dienstleistungen</p>
    <p>
      Für Schäden, die durch uns, unsere gesetzlichen Vertreter oder
      Erfüllungsgehilfen verursacht wurden, haften wir unbeschränkt (i) bei
      Verletzungen des Lebens, des Körpers oder der Gesundheit, (ii) bei
      vorsätzlicher oder grob fahrlässiger Pflichtverletzung, (iii) bei
      Verletzung von Garantieversprechen und (iv) sofern der Anwendungsbereich
      des Produkthaftungsgesetzes eröffnet ist. Bei der Verletzung wesentlicher
      Vertragspflichten, deren Erfüllung die ordnungsgemässe Durchführung des
      Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der
      Vertragspartner regelmässig vertrauen darf (sog. Kardinalpflichten) durch
      leichte Fahrlässigkeit von uns, unseren gesetzlichen Vertretern oder
      Erfüllungsgehilfen, ist die Haftung der Höhe nach auf den bei
      Vertragsschluss vorhersehbaren Schaden begrenzt, mit dessen Entstehung
      typischerweise gerechnet werden muss. Im Übrigen sind Ansprüche auf
      Schadensersatz ausgeschlossen.
    </p>
    <p>
      14.3. Haftungsbeschränkung für Comii Dienstleistungen (Rechnungserstellung,
      Zeiterfassung, etc.)
    </p>
    <p>
      Bei der Inanspruchnahme von Comii Dienstleistungen gelten die jeweiligen
      die Haftung von Comii betreffenden Regelungen. Sollte keine separate
      Vereinbarung getroffen sein oder sollte die separate Vereinbarung keine
      Regelungen zur Beschränkung der Haftung von Comii enthalten, gilt Ziffer
      14.2 entsprechend.
    </p>
    <h2>
      <a name="_Toc82156905">15. Kündigung und Beendigung</a>
    </h2>
    <p>
      15.1. Sie können die App jederzeit je nach Abomodell, in der Regel monatlich, kündigen, indem Sie
      Ihren Vertrag/Abo kündigen und Ihren Account schliessen sowie die App
      löschen.
    </p>
    <p>
      15.2. Wir können diese Bedingungen und damit Ihren Zugang zu Comii
      mit sofortiger Wirkung ausserordentlich aus wichtigem
      Grund kündigen, z.B. wenn Sie in schwerwiegender Weise gegen diese
      Bedingungen oder andere Vereinbarungen zwischen Ihnen und uns verstossen
      haben und diesen Verstoss nicht innerhalb einer angemessenen, von uns
      gesetzten Frist beheben (eine Fristsetzung ist in den Fällen von § 314
      Abs. 2 S. 2 und 3 BGB entbehrlich).
    </p>
    <p>
      15.3. Uns steht es frei, den Vertrag über die Nutzung der Comii
      App jederzeit unter Einhaltung einer Frist von 7 Tagen in
      Textform (z.B. per E-Mail) ordentlich zu kündigen oder - ebenfalls nach
      vorheriger Ankündigung mit angemessener Frist - die App-Dienstleistungen
      insgesamt dauerhaft einzustellen oder zeitweise zu unterbrechen.
    </p>
    <p>
      15.4. Im Falle der Beendigung haben Sie sämtliche ausstehenden,
      Drittanbietern und/oder Comii geschuldeten Kosten unverzüglich zu zahlen.
      Jegliche solche Ansprüche bestehen nach Beendigung fort und wir behalten
      uns sämtliche Rechte vor, diese Zahlungen nach Beendigung einzufordern.
    </p>
    <h2>
      <a name="_Toc82156906">16. Änderungen von Bedingungen</a>
    </h2>
    <p>
      16.1. Wir können Ihnen jederzeit eine Änderung dieser Bedingungen
      vorschlagen. Dies erfolgt entweder durch eine Bekanntgabe der neuen
      Bedingungen in den Comii Mobil-App(s) und/oder Webseite(n), denen Sie dann
      unmittelbar zustimmen, oder per E-Mail. Im Fall der Bekanntgabe per E-Mail
      werden Ihnen Änderungen dieser Bedingungen spätestens einen Monat vor dem
      vorgeschlagenen Zeitpunkt ihres Wirksamwerdens angeboten. Ihre Zustimmung
      gilt als erteilt, wenn die Ablehnung nicht vor dem vorgeschlagenen
      Zeitpunkt des Wirksamwerdens der Änderungen gegenüber uns in Textform
      angezeigt wird. Wenn Sie mit den Änderungen nicht einverstanden sind,
      steht Ihnen bis zu dem vorgeschlagenen Zeitpunkt des Wirksamwerdens der
      Änderungen ein fristloses und kostenfreies Kündigungsrecht zu (siehe
      Ziffer 15.1). Wir werden Sie in der Nachricht, mit der die Änderungen
      angeboten werden, auch noch einmal besonders auf das Ablehnungsrecht, die
      Frist dafür und die Möglichkeit zur Kündigung hinweisen. Unser
      Kündigungsrecht gemäss Ziffer 15.3 bleibt unberührt.
    </p>
    <p>
      16.2. Sollte eine Bestimmung dieser Bedingungen ganz oder teilweise
      nichtig oder unwirksam sein oder werden, so wird dadurch die Wirksamkeit
      der übrigen Bestimmungen nicht berührt. An die Stelle von nicht
      einbezogenen oder unwirksamen Bestimmungen dieser Bedingungen tritt das
      Gesetz. Sofern ein solches Gesetz im jeweiligen Fall nicht zur Verfügung
      steht (Regelungslücke) oder zu einem untragbaren Ergebnis führen würde,
      werden die Parteien in Verhandlungen darüber eintreten, anstelle der nicht
      einbezogenen oder unwirksamen Bestimmung eine wirksame Regelung zu
      treffen, die ihr wirtschaftlich möglichst nahekommt.
    </p>
    <p>
      16.3. Unter der Voraussetzung, dass dies nicht zu einer mehr als
      unerheblichen Beeinträchtigung Ihrer Rechte (wie Sie Ihnen auf Basis
      dieser Bedingungen oder aufgrund geltender Gesetze zustehen) führt, können
      wir diesen Vertrag über die Nutzung der App-/Webseiten-Dienstleistungen
      oder jegliche unserer Rechte und Verpflichtungen daraus im Ganzen oder
      teilweise ohne Ihre vorherige Zustimmung von Subunternehmern und/oder
      Erfüllungsgehilfen erbringen lassen oder auf einen Dritten übertragen,
      sofern Sie durch eine solche Übertragung auf einen Dritten nicht
      wirtschaftlich schlechter gestellt werden. Im Falle der Übertragung an
      einen Dritten steht Ihnen ein fristloses Kündigungsrecht zu (vgl. Ziffer
      15.1)
    </p>
    <p>
      16.4. Diese Bedingungen, gemeinsam mit jeglicher ergänzenden Vereinbarung
      über die Gewährung der App-Dienstleistung, stellen unsere gesamte
      Vereinbarung mit Ihnen dar.
    </p>
    <h2>
      <a name="_Toc82156907">17. Verfahren zur Streitbeilegung</a>
    </h2>
    <p>
      Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von
      Streitigkeiten (sog. „OS-Plattform“) geschaffen. Die OS-Plattform dient
      als Anlaufstelle zur aussergerichtlichen Beilegung von Streitigkeiten
      betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen oder
      Online-Dienstleistungsverträgen erwachsen. Sie können die OS-Plattform
      hier abrufen: ec.europa.eu/consumers/odr.
    </p>
    <p>
      Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor
      einer Verbraucherschlichtungsstelle teilzunehmen.
    </p>
    <h2>
      <a name="_Toc82156908">18. Anwendbares Recht und Gerichtsstand</a>
    </h2>
    <p>
      Diese Bedingungen und die zwischen Ihnen und der Digital Suxess AG
      (Bereitstellung der Comii App) auf dieser Basis zustande kommenden
      Rechtsverhältnisse unterliegen dem Recht der Bundesrepublik Deutschland.
      Gerichtsstand ist Stuttgart.
    </p>
    <p>
      Als Verbraucher mit Wohnsitz im Europäischen Wirtschaftsraum (EWR) können
      Sie Ansprüche im Zusammenhang mit diesen Bedingungen und den zwischen
      Ihnen und uns auf dieser Basis zustande kommenden Rechtsverhältnissen
      sowohl vor dem zuständigen Gericht an Ihrem Wohnsitz, als auch vor dem
      zuständigen Gericht am Unternehmenssitz von Comii geltend machen. Wenn
      Comii seine Rechte gegen Sie als Verbraucher mit Wohnsitz im Europäischen
      Wirtschaftsraum (EWR) durchsetzen möchte, können wir das nur vor den
      zuständigen Gerichten an Ihrem Wohnsitz tun.
    </p>
    <p>
      Mit der Annahme dieser Bedingungen erklären Sie Ihr Einverständnis zur
      Geltung und Einhaltung der Bedingungen.
    </p>
  </div>
</template>

<script>
export default {
     mounted(){
        window.scrollTo(0,0);
    }
};
</script>

<style lang="scss" scoped>
h1,
h2,
h3 {
  color: #2f5496;
  margin-bottom: 15px;
}
p {
  margin-bottom: 25px;
  a {
    color: #2f5496;
    text-decoration: underline;
  }
}
</style>
