<template>
  <div class="media-library  page-block block-padding mb-12">
    <div class="w-full sm:w-5/6 mx-auto responsive-heading3 text-center mb-12">
        <div class="top-bar rounded bg-purple-400 responsive-top-bar h-1 mx-auto my-4"></div>
        <h2 class="text-purple-main responsive-heading1.5 font-bold mb-5">
          Mediathek
        </h2>
        
        <p class="mx-auto text-pruple-main mb-10 text-purple-main">
          Herzlich willkommen auf unserer Mediathek! Hier erhalten Sie aktuelle Videos zu unseren Alltagshelfern und Senioren.
        </p>
        
        <p class="text-gray-main">
          Wir freuen uns, wenn Sie unseren YouTube-Kanal abonnieren oder bei <a class="media_link" href="https://www.instagram.com/comii_die_app/">Instagram</a> und <a class="media_link" href="https://www.facebook.com/comiiDieApp/">Facebook</a> folgen.
        </p>

        
    </div>

    <div class="media-items flex flex-wrap justify-evenly lg:justify-between text-responsive">

        <div class="media-item flex flex-col items-center">
            <div class="flex w-44 lg:w-52 mb-6">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/KIUysvAH-RE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p class="text-center  mb-6 text-gray-main px-4">
                Lernen Sie unsere Alltagshelfer kennen
            </p>

            <a 
            href="https://www.youtube.com/channel/UCR6Nds33WnnhS80LzRrQykw" 
            class="border-purple-main text-purple-main border px-4 py-2  hover:bg-purple-main hover:text-white transition">
            Alles sehen
            </a>
        </div>
        <div class="media-item flex flex-col items-center mb-10">
            <div class="flex w-44 lg:w-52 mb-6">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/pzpWvo9s4Fc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p class="text-center  mb-6 text-gray-main px-4">
                Comii ist einfach und unkompliziert 
            </p>

            <a 
            href="https://www.youtube.com/channel/UCR6Nds33WnnhS80LzRrQykw" 
            class="border-purple-main text-purple-main border px-4 py-2  hover:bg-purple-main hover:text-white transition">
            Alles sehen
            </a>
        </div>
        <div class="media-item flex flex-col items-center">
            <div class="flex w-44 lg:w-52 mb-6">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/O_stf-4pRW4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p class="text-center  mb-6 text-gray-main px-4">
                Jeder kann Alltagshelfer werden
            </p>

            <a 
            href="https://www.youtube.com/channel/UCR6Nds33WnnhS80LzRrQykw" 
            class="border-purple-main text-purple-main border px-4 py-2  hover:bg-purple-main hover:text-white transition">
            Alles sehen
            </a>
        </div>
        <div class="media-item flex flex-col items-center">
            <div class="flex w-44 lg:w-52 mb-6">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/DB0xktZECU8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p class="text-center  mb-6 text-gray-main px-4">
                Comii eine App für Senioren und Alltagshelfer
            </p>

            <a 
            href="https://www.youtube.com/channel/UCR6Nds33WnnhS80LzRrQykw" 
            class="border-purple-main text-purple-main border px-4 py-2  hover:bg-purple-main hover:text-white transition">
            Alles sehen
            </a>
        </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
    .media_link{
        color: #607ca8;
    }
</style>
