<template>
    <div>
        <div>
            <!-- For Everyday helpers first block after 
                the main top image and tab bar view
            -->
            <bloc-21
                title="Wie funktioniert Comii?"
                content="Comii bildet den gesamten Kreislauf von der Suche bis zur Zahlung ab.
                "
            >
            </bloc-21> 

            <div class="helpers-gallery-block lg:mb-0 mb-5 flex flex-wrap gap-6 max-w-screen-lg mx-auto">
                <bloc-11
                    class="pb-6"
                    v-for="content in textblock11"
                    :key="content.id"
                    :number="`${content.id}`"
                    :content="content.content" 
                    :colorClass="colorClass"> 
                </bloc-11>
            </div>
        </div>
    </div>
</template>

<script>
import Bloc21 from "../../components/Bloc21.vue";
import Bloc11 from "../../components/Bloc11.vue";
import {textblock11} from "../../data/data"

export default {
    components: {
        Bloc21,
        Bloc11
    },
      data(){
	return  {
        textblock11
    }
  },
    props: ['colorClass']
};
</script>

<style></style>
