<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    :style="`fill: var(${currentColor})`"
  >
    <path
      d="M12,27V15H8v-4h4V8.852C12,4.785,13.981,3,17.361,3c1.619,0,2.475,0.12,2.88,0.175V7h-2.305C16.501,7,16,7.757,16,9.291V11 h4.205l-0.571,4H16v12H12z"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    colorClass: { type: String, default: "orange" },
  },

  computed: {
    currentColor() {
      switch (this.colorClass) {
        case "green":
          return "--main-green";
        case "purple":
          return "--main-purple";
        default:
          return "--main-orange";
      }
    },
  },
};
</script>
