<template>
  <div 
    class="relative lg:w-1/2 lg:mx-0 mx-5 h-auto lg:mb-0 mb-20 pt-12 pb-8 px-6"
    :style="`
        background-color: var(${currentColor});
    `">
    <div class="text-white">
        <h1 class="text-2xl py-6 font-bold">
            {{ heading }}
        </h1>
        <p class="text-2xl font-light">
            {{ content }}
        </p>
    </div>
    <div 
        class="below-number w-24 h-24 absolute rounded-full bg-white top-0 left-1/2"
    >
    </div>
    <div 
        class="w-24 h-24 absolute rounded-full flex justify-center items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        :style="`
            background-color: var(${currentColor});
        `"
    >
        <span class="text-white font-bold text-4xl" >{{ number }}</span>
    </div>
  </div>
</template>

<script>
export default {
    props: ['colorClass', 'heading', 'content', 'number'],
    computed: {
        currentColor() {
        switch (this.colorClass) {
            case "green":
            return "--main-green";
            case "purple":
            return "--main-purple";
            default:
            return "--main-orange";
        }
        },
    },
}
</script>

<style lang="scss" scoped>
.below-number {
    transform: translate(calc(-50% + 4px), calc(-50% + 4px));
}
</style>