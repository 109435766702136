<template>
  <div class="comii-idea mb-9">
    <div class="idea-title w-full sm:w-11/12 mx-auto block-padding page-block mb-11">
      <div class="top-bar rounded bg-purple-400 responsive-top-bar h-1 mx-auto my-4"></div>
      <h2 class="text-purple-main responsive-heading1.5 text-center font-bold mb-5">
        Comii die Idee
      </h2>

      <p class="mx-auto text-gray-main text-responsive mb-10 text-purple-main w-4/5 text-center">
        Jeder von uns kennt diese Situation: Man ist allein und könnte jemanden
        gebrauchen, der kurz zur Apotheke geht, einen Schrank aufbaut oder das
        Handy einrichtet.
      </p>

      <h3 class="text-purple-main text-responsive font-black text-left mb-4">
        Aber wer hilft? Wie finde ich jetzt jemanden, der verfügbar ist?
      </h3>

      <p class="mx-auto text-gray-main text-responsive mb-5">
        Das Alter ist kein Problem! Es gibt immer wieder Situationen, in denen
        man spontan jemanden bitten würde, einem zu helfen. Aber wer ist das?
        <br />
        <br />
        Sind die eigenen Kinder nicht in der Nähe? Der Nachbar, der im Urlaub
        ist? Daniel (Fachmann für häusliche Pflege und Hilfe) und Florian
        (Experte für Digitalisierung und künstliche Intelligenz), beides Experten
        auf ihrem Gebiet, wollten dieses DILEMMA lösen.
        <br />
        <br />
        Und so kam es, dass Comii auf der Schwäbischen Alb geboren wurde. Gemäss
        dem Motto "Alles Gute kommt von oben" haben wir die Geburtsvorbereitung
        über mehrere Monate hinweg konzipiert. Wir haben mit vielen Senioren
        gesprochen, sie teilweise in ihrem Alltag begleitet und Comii gemeinsam
        mit ihnen entwickelt und umgesetzt.
        <br />
        <br />
        Comii als Synonym für Gemeinschaft, Gesellschaft &amp; Community. Wir
        halten zusammen und helfen uns gegenseitig.
        <br />
        <br />
        Comii lebt von Kooperation und Partizipation. Und das kann jeder: Ob
        Schüler, Student, Teilzeitkraft, Pfleger, Pflegekraft, Frührentner, Senior oder
        alle, die sich etwas dazuverdienen und Gutes tun wollen.
        <br />
        Wir hoffen, dass sich dieses Modell verbreitet, damit so viele Senioren
        wie möglich aktive Unterstützung erhalten können, wann immer sie diese
        brauchen und wo immer sie sich befinden.
      </p>
    </div>

  <!------------------------------------ bloc violet --------------------------------------------->
    <div class="bg-purple-main ">
      <div class="page-block block-padding another-purple-band 
       flex justify-center text-white py-16 mb-10 
       relative">
          <div class="absolute flex left-0 top-0 sm:bottom-0 z-0 w-2/5">
              <img class="h-full w-full" v-lazy="require('@/assets/images/polys.png')" alt="">
          </div>
       <div class="w-full relative z-10 py-10 sm:py-0">
            <div class="leading-none flex justify-center w-16 md:w-20 mx-auto mb-1"><img v-lazy="require('@/assets/images/quotes.png')" alt="quotes"></div>
            <div class="responsive-heading1 font-light text-center w-full sm:w-5/6 md:w-1/2 mx-auto">
              Selbstständig bleiben ist unser Motto.
            </div>
       </div>
      
       <div class="absolute flex right-0 sm:top-0 bottom-0 z-0 w-2/5">
              <img class="h-full w-full" v-lazy="require('@/assets/images/polys-reverse.png')" alt="">
          </div>
      </div>
    </div>
  <!------------------------------------ fin du bloc violet --------------------------------------------->


    <div class="block-padding page-block flex flex-col-reverse md:flex-row">
      <div class="bg-gray-100 md:w-3/6 lg:w-4/6 pl-10 pt-6 pb-24"> 
          <div class="top-bar rounded bg-purple-400 w-20 h-1 my-3"></div>
        <h2 class="text-purple-main responsive-heading3 font-bold mb-2">
            Comii - die Vorteile
        </h2>
          <ul class="w-full pr-2 lg:w-3/4 text-responsive" >
              <h1></h1>
            <li class="plist-item">Spontan Hilfe erhalten</li>
            <li class="plist-item">
              Auswahl durch Kategorien (Pflege, Haushalt, Garten,..)
            </li>
            <li class="plist-item">Erfahrene Alltagshelfer und Pflegekräfte</li>
            <li class="plist-item">Sichtung des Kurzprofils der Alltagshelfer</li>
            <li class="plist-item">Freiwillige &amp; professionelle Helfer</li>
            <li class="plist-item">Maximal 25 €/Stunde (Die ersten 30 Minuten werden pauschal abgerechnet)</li>
          
            <li class="plist-item">Danach Zeiterfassung im 15 Minuten Takt</li>
            <li class="plist-item">Volle Transparenz der Arbeitszeit des Alltagshelfers</li>
            <li class="plist-item">
              Sehr guter Überblick über Ihre Rechnungen in Ihrem Profil
            </li>
            <li class="plist-item">Alltagshelfer sind versichert</li>
            <li class="plist-item">Sehr hoher Sicherheitsstandard</li>
          </ul>
      </div>
      <div class=" w-full md:w-3/6 lg:w-2/6 bg-purple-light-main image-prov " >
        <img v-lazy="require('@/assets/images/oldman-with-youngman.jpg')"
        
            class=""
         alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
