<template>
  <div>
    <top-hero
      appLink="helfer"
      :image="currentTab < 0 ? 'old-couple.jpg': topImages[currentTab]"
      :colorClass="$store.getters.colorClass"
      :mainTitle="currentTab < 0 ? 'Für mobile Pflegedienste' : tabBarContent[currentTab].text"
      :isMainHero="currentTab < 0"
      :heading-border-left="currentTab < 0"
      image-position="center"
    >
      Comii hilft mobilien Pflegediensten in der Organisation.
    </top-hero>
    <tab-bar-view
      :tabs="tabBarContent"
      secondary-base-path="Für mobile Pflegedienste"
      base-url="/mobile-care"
      :colorClass="$store.getters.colorClass"
      section-title=""
    >
      <div v-show="!currentSubHeading">
        
        <!-- For Everyday helpers first block after 
          the main top image and tab bar view
        -->
        <bloc-21 class="mb-8" title="Für mobile Pflegedienste..." no-content>
        </bloc-21>
        <div class="lg:mx-20 mx-5 lg:text-center text-left lg:mb-12 mb-5 lg:text-2xl text-xl text-gray-main font-extralight">
            <p>Als mobiler Pflegedienst oder soziale Einrichtung kennen Sie das Dilemma: 
            Es ist schwierig, professionelles Pflegepersonal oder Pflegehelfer/innen zu finden.
            Viele Angestellte wollen oder können nur Teilzeit arbeiten.</p> <br>
            
            <p class="text-main-orange">Ihre Arbeitsbelastung ist bereits beträchtlich und kurzfristige 
              Lücken müssen geschlossen werden. 
              Sie möchten Ihren Mitarbeitern die Freiheit geben, 
              sich sozial zu engagieren, auch neben ihrer Haupttätigkeit.</p>
        </div>
        
        <!-- 
          Second block which has left 
          image and right content 
        -->
        <div>
          <div class="lg:flex">
            <img class="lg:w-1/2" v-lazy="require('@/assets/images/home-3.jpg')" alt="">
            <div class="pl-5">
              <h1 class="text-main-orange text-3xl mb-3">Comii - Hilfe!</h1>
              <p class="text-2xl mb-5 text-gray-main">Wenn Sie Comii auf den Mobiltelefonen des Unternehmens verwenden, 
                erhalten Sie sofort und einfach eine umfassende Software für die Auftragsabwicklung, 
                Rechnungsstellung und Verwaltung. Und das alles in Form einer App! 
                <br><br>Jeder Mitarbeiter und jede Mitarbeiterin kann sich anmelden und das Profil seines/ihres Unternehmens speichern. 
                So können Sie sich ständig optimieren und haben einen vollständigen Überblick über Ihre Arbeitsstunden - Comii stellt die Rechnung in Ihrem Namen aus. 
                Comii ist einfach und genial.
              </p>
            </div>
          </div>
          <div class="lg:flex block">
            <img class="lg:hidden block lg:w-1/2" v-lazy="require('@/assets/images/home-3.jpg')" alt="">
            <div class="pl-5">
              <h1 class="text-main-orange mt-8 text-2xl mb-3">Sie haben die Wahl</h1>
              
              <div class="flex mb-5 lg:text-2xl text-xl">
                <div class="pr-4 font-bold text-main-orange">&#10095;</div>
                <p class="text-gray-main">
                  Nutzen Sie die kostenlose Comii Alltagshelfer App und stellen Sie sicher, dass Ihre Senioren die Comii Senioren App nutzen.
                </p>
              </div>

              <div class="flex mb-5 lg:text-2xl text-xl">
                <div class="pr-4 font-bold text-main-orange">&#10095;</div>
                <p class="text-gray-main"> 
                  Beantragen Sie ein persönliches Gespräch über die Nutzung von Comii, gerne auch als Business Lösung.
                </p>
              </div>
              
              
              <div class="flex lg:text-2xl text-xl">
                <div class="border-l-8 border-main-orange"></div>
                <div class="lg:w-2/3 shadow border-2 rounded p-3">
                <p class="text-gray-main">Wir haben alle nur ein Ziel: <span class="text-main-orange">Senioren bestmöglich zu helfen und dabei Spass zu haben.</span></p>
              </div>
              </div>
              
            </div>
            <img class="lg:block hidden lg:w-1/2" v-lazy="require('@/assets/images/home-3.jpg')" alt="">
          </div>

        </div>
        <bloc-21 class="mb-8" title="Comii - die Vorteile" no-content>
        </bloc-21>

        <div class="lg:mx-40 lg:pl-0 pl-5 grid lg:grid-rows-6 lg:grid-flow-col font-light text-gray-main">
          <div v-for="(mobile,index) in mobileArray" :key="index" class="w-full lg:mb-0 mb-5 lg:pr-20 pr-0 neighborhood lg:space-y-0 space-y-5 text-left block text-xl flex-grow flex-shrink-0">
            <div class="bg-main-orange lg:flex justify-center inline items-center p-4 w-5 h-8 text-white font-bold">
                <span><span v-if="index < 9">0</span>{{index +1}}</span>
            </div>
            <div v-html="mobile"></div>
          </div>
        </div>
      </div>

      <div v-show="currentSubHeading === 'payment'">
        <payment />
        <div class="mt-20">
        </div>
        
      </div>

      <faqs 
        v-show="currentSubHeading === 'faqs'"
        :faq-content="faqContent"
      ></faqs>

    </tab-bar-view>
    <top-hero v-if="currentSubHeading === 'payment'"
        :image="currentTab < 0 ? 'old-couple.jpg': topImages[currentTab]"
        :colorClass="$store.getters.colorClass"
        isMainHero
        exists-telecharger-button
        heading-border-top
        hideSelect
        image-position="center"
      >
    Nutzen Sie auch unsere App für Ihr Unternehmen! Wir helfen Ihnen dabei, Ihre Ressourcen und Bestellungen zu verwalten. <br><br> Flexibles Füllen von Auftragslücken, vollständige Rechnungsstellung, automatisierte Zahlungsprozesse und vieles mehr...
    </top-hero>
  </div>
</template>

<script>
import TopHero from "../../components/global/TopHero.vue";
import TabBarView from "../../components/global/TabBarView.vue";
// import Bloc1 from "../../components/Bloc1.vue";
import Bloc21 from "../../components/Bloc21.vue";
// import Card25 from "../../components/Card25.vue";
import Payment from "./payment.vue"
import Faqs from "../for_seniors/faqs.vue";

export default {
  name: "MobileCare",
  components: {
    TopHero,
    TabBarView,
    // Bloc1,
    Bloc21,
    Payment,
    Faqs,
    // Card25,

  },
  data() {
    return {
      mobileArray: [
        'Wir reduzieren Ihren <br>Verwaltungsaufwand',
        'Weniger Ausfallzeiten bei spontanen Stornierungen',
        'Flexible Übernahme von Anfragen, um Lücken zu schliessen.',
        'Anhand der Anträge nach Kategorien können die verfügbaren Hilfen (je nach Qualifikation) priorisiert werden.',
        'Überblick über alle digitalen Anfragen von Senioren',
        'Spontane Hilfe anbieten',
        'Statistiken über Ihr Netzwerk und Hilfsdienste',
        'Senioren, die noch keine Kunden bei Ihnen sind, sehen Ihre verfügbaren Helfer und Betreuer.',
        'Senioren können sich für konkrete Hilfen entscheiden',
        'Mithilfe der Kategorien können Sie konkrete Hilfe anfordern',
        'Die Betreuer können sich nach Annahme der Bestellung mit dem Senior in Verbindung setzen.'
      ],
      colorClass: "orange",
      tabBarContent: [
        {
          text: "Bezahlung, Preise & Rechnungsstellung",
          param: "payment"
        },
        {
          text: "FAQS",
          param: "faqs"
        }
      ],
      topImages: [
        'old-women-with-phone.jpg',
        'doctor-2-old-women.jpg',
        'man-woman-with-tablet.jpg',
        'coins.jpg',
        'doctor-with-patient.jpg',
        'faqs-female.jpg'
      ],
      faqContent: [
        {
          question: "Was macht Comii?",
          answer: ` Comii ist eine einzigartige Plattform zur Unterstützung von Senioren, die im Alltag selbständig bleiben wollen. Dazu nutzt Comii eine Vielzahl an Alltagshelfern, die im Umgang mit Senioren vertraut und ausgebildet sind. Durch eine raffinierte, technische Lösung bringt Comii Senioren und Alltagshelfer zusammen. Und zwar immer zu dem Zeitpunkt, in dem die Hilfe auch benötigt wird - nämlich meist sofort. Comii ist schnell wie die Feuerwehr, ist sicher, seniorengerecht einfach und hilft genauso unkompliziert.`,
        },
        {
          question: "Wer steht hinter Comii ?",
          answer: `  Hinter Comii stehen langjährige Experten aus der Seniorenhilfe und technische Enthusiasten, die moderne Technik und Digitalisierung im Life Science und Elderly-Care Bereich in Deutschland verankern möchten.`,
        },
        {
          question: "Wie verdient Comii sein Geld?",
          answer: `Comii verdient nur durch eine geringe monatliche App Gebühr von Senioren und einer Transaktionsgebühr für Alltagshelfer zur Sicherstellung der technischen Infrastruktur. Oberstes Ziel ist die perfekte Bereitstellung einer Alltagshilfe - und zwar genau in dem Zeitpunkt in dem Hilfe benötigt wird.`,
        },
        {
          question: `Was ist das aussergewöhnliche an Comii?`,
          answer: `Comii ist schnell. Comii ist einfach. Comii macht Spass. Durch intelligente Algorithmen können wir das perfekte Matching zwischen Hilfesuchenden und Alltagshelfern anbieten. Beides matchen wir durch eine spezielle Geofencing Technologie.`,
        },
        {
          question: `Warum verlangt Comii 25 Euro die Stunde, obwohl der Mindeststundenlohn bei 12 Euro liegt?`,
          answer: `Comii möchte, dass die Alltagshelfer direkt in der Nähe von Hilfesuchenden sind. Deshalb wird die Vergütung auch erst dann zeitlich gestartet, wenn der Alltagshelfer beim Senior angekommen ist. 
          Somit ist die Anfahrts-und Abfahrszeit zum und vom Senior in der Veranwortung des Helfers. Anfahrts-und Abfahrtszeiten sind also in dem Stundensatz abgegolten. Ebenso ist Comii spontan. Die Hilfe die Sie benötigen kommt sehr schnell. Diese Flexibilität ist mit eingepreist. `,
        },
        {
          question: `Zerstören Sie dadurch nicht den Mindestlohn?`,
          answer: `Comii setzt sich für gerechte und soziale Löhne ein. Wir unterstützen all diejenigen, die soziale Verantwortung tragen und sich engagieren möchten um spontan Gutes zu tun.`,
        },
      ],
    };
  },
  computed: {
    currentSubHeading() {
      return this.$route.params.subheading;
    },
    currentTab() {
      return this.tabBarContent.findIndex( ele => ele.param === this.currentSubHeading);
    }
  },
  created() {
    this.$store.dispatch("setColorClass", this.colorClass);
  },
};
</script>

<style>
</style>
