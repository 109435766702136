<template>
  <div>
    <top-hero
      appLink="helfer"
      :image="
        currentTab < 0 ? 'doctor-woman-wheelchair.jpg' : topImages[currentTab]
      "
      :colorClass="$store.getters.colorClass"
      :mainTitle="
        currentTab < 0
          ? 'Für Nachbarschaftsnetzwerke'
          : tabBarContent[currentTab].text
      "
      :isMainHero="currentTab < 0"
      :heading-border-left="currentTab < 0"
      image-position="center"
    >
      Die Comii App ermöglicht es Senioren und Hilfesuchenden, spontane
      Alltagshelfer in ihrer Nähe zu finden und diese mit bestimmten Aufgaben zu
      betrauen. Auf dieser Seite finden Sie alle Informationen zu Comii.
    </top-hero>
    <tab-bar-view
      :tabs="tabBarContent"
      secondary-base-path="Für Nachbarschaftsnetzwerke"
      base-url="/neighborhood"
      :colorClass="$store.getters.colorClass"
      section-title=""
    >
      <div v-show="!currentSubHeading">
        <bloc-21
          title="Für Nachbarschaftsnetzwerke"
          content="Nachbarschaftsnetzwerke sind ein wesentlicher und wichtiger Pfeiler unserer Gesellschaft. Häufig stossen diese ehrenamtlichen Netzwerke an ihre organisatorischen Grenzen."
        ></bloc-21>
        <card-25
          image="woman-daughter.jpg"
          overflow-y-image
          reversed
          equal-width
        >
          <template #side>
            <div class="px-8">
              <h1 class="text-orange-main text-xl lg:text-4xl font-bold">
                <span>Und hier hilft Ihnen Comii!</span>
              </h1>
              <br />
              <p class="text-xl font-light">
                Comii koordiniert Seniorenanfragen mit verfügbaren Alltagshelfern.
              </p>
              <br />
              <p class="text-xl font-light">
                Unsere Plattform ist eine technisch ausgereifte Lösung.
                Voraussetzung: Ihre Senioren und Alltagshelfer haben ein
                Smartphone und können unsere Comii App nutzen.
              </p>
              <br />
              <p class="text-xl font-light">
                Dafür haben wir das Design und die Bedienung, in Zusammenarbeit mit Senioren, so einfach wie
                möglich gestaltet.
              </p>
            </div>
          </template>
        </card-25>
      </div>
      <remuneration v-show="currentSubHeading === 'remuneration'">
      </remuneration>
      <faqs 
        v-show="currentSubHeading === 'faqs'"
        :faq-content="faqContent"
      ></faqs>
    </tab-bar-view>

    <!-- Main Neighborhood Page after tab-bar-view -->
    <div v-show="!currentSubHeading" class="mt-12">
      <div class="bg-another-gray py-10">
        <div class="page-block block-padding">
          <h1 class="lg:w-1/2 w-full font-bold text-center text-2xl text-orange-main lg:text-4xl">Sie haben die Wahl:</h1>
          <br />
          <div class="text-gray-main lg:flex gap-12 items-center pb-4">
            <div class="flex-1">
              <left-arrow-text
                text="Kostenlose Nutzung der Comii Alltagshelfer App und Sicherstellung, dass Ihre Senioren die Comii Senioren App nutzen."
                color-class-not-on-text
                :colorClass="colorClass"
              />
              <br />
              <left-arrow-text
                text="Beantragen Sie ein persönliches Gespräch über die Nutzung von Comii für Ihr Netzwerk."
                color-class-not-on-text
                :colorClass="colorClass"
              />
            </div>
            <div class="flex-1">
              <div class="w-16 mx-auto text-2xl">
                <img v-lazy="require('@/assets/images/left-quote-orange.png')" alt="" />
              </div>
              <p
                class="font-light italic text-2xl mx-auto text-center text-orange-main"
              >
                Wir haben alle nur ein Ziel:
              </p>
              <p class="text-orange-main font-bold text-2xl mx-auto text-center">
                Senioren bestmöglich zu helfen und dabei Spass zu haben.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="page-block block-padding">
        <bloc-21 class="mb-8" title="Comii - die Vorteile" no-content>
        </bloc-21>
        <div class="grid lg:grid-cols-3 grid-cols-1 gap-5 lg:gap-10 pb-6 font-light text-gray-main">
          <bloq-25
            v-for="(whatToDo, index) in neighborhoodArray"
            :key="index"
            :number="index + 1"
            :text="whatToDo"
            class=""
            side-by-side
          >
          </bloq-25>
        </div>
      </div>
    </div>

    <!-- Neighborhood renumeration page after tab-bar-view -->
    <div v-show="currentSubHeading === 'remuneration'">
      <div class="page-block neighborhood-renumeration-bottom-image p-8"
        v-lazy:background-image="require('@/assets/images/people-drinking.jpg')" >
        <div class="p-8 relative text-white image-text-block max-w-lg ml-auto">
          <h1 class="text-4xl text-2xl">
            Wir unterstützen das Ehrenamt!
          </h1>
          <br />
          <p class="text-2xl">
            Wir unterstützen das Ehrenamt! Mit Comii reduzieren wir
            Ihren administrativen und organisatorischen Aufwand.
          </p>
          <br />
          <p class="text-2xl">
            Bitte richten Sie Ihre individuellen Anfragen direkt an uns.
          </p>
          <div
            class="background-image-absolute absolute top-0 left-0 w-full h-full bg-orange-main"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHero from "../../components/global/TopHero.vue";
import TabBarView from "../../components/global/TabBarView.vue";
import Bloc21 from "../../components/Bloc21.vue";
import Bloq25 from "../../components/Bloq25.vue";
import Card25 from "../../components/Card25.vue";
import LeftArrowText from "../../components/utils/LeftArrowText.vue";
import Remuneration from "./remuneration.vue";
import Faqs from "../for_seniors/faqs.vue";

export default {
  name: "Neighboorhood",
  components: {
    TopHero,
    TabBarView,
    Bloc21,
    Card25,
    LeftArrowText,
    Bloq25,
    Remuneration,
    Faqs,
  },
  data() {
    return {
      faqContent: [
        {
          question: "Was macht Comii?",
          answer: ` Comii ist der erste und einzigartige Service zur Hilfe von Senioren, die im Alltag selbständig bleiben wollen. Dazu nutzt Comii eine Vielzahl an Alltagshelfern, die im Umgang mit Senioren vertraut und ausgebildet sind. Durch eine raffinierte, technische Lösung bringt Comii Senioren und Alltagshelfer zusammen. Und zwar immer in dem Zeitpunkt, in dem die Hilfe auch benötigt wird - nämlich meist sofort. Comii ist schnell wie die Feuerwehr, ist sicher, seniorengerecht einfach und hilft genauso unkompliziert.`,
        },
        {
          question: "Wer steht hinter Comii ?",
          answer: `  Hinter Comii stehen langjährige Experten aus der Seniorenhilfe und technische Enthusiasten, die moderne Technik und Digitalisierung im Life Science und Eldery-Care Bereich entwickeln.`,
        },
        {
          question: "Womit verdient Comii sein Geld?",
          answer: `Comii verdient nur durch eine geringe monatliche App Gebühr für Senioren sowie durch eine Transaktionsgebühre pro Auftrag. Damit finanzieren wir unsere Infrastrukturkosten. `,
        },
        {
          question: `Was ist das aussergewöhnliche an Comii?`,
          answer: `Comii ist schnell. Comii ist einfach. Comii macht Spass. Comii verwendet intelligente Geo Funktionen zur Sicherstellung eines reibungslosen Ablaufes. 
          Die Geo Funktionen erlauben uns, nur Alltagshelfer anzufragen, die auch in der unmittelbaren Umgebung der Senioren verfügbar sind.`,
        },
        {
          question: `Warum verlangt Comii 25 Euro die Stunde, obwohl der Mindeststundenlohn bei 12 Euro liegt?`,
          answer: `Erstens, da Anfahrts- und Abfahrtszeiten in der Verantwortung der Alltagshelfer sind und zweitens, weil spontane Hilfe sofort geleistet werden kann. Die
          Gebührenstruktur wurde mit Senioren erarbeitet.`,
        },
        {
          question: `Zerstören Sie dadurch nicht den Mindestlohn?`,
          answer: `Comii setzt sich für gerechte und soziale Löhne - gerade in der Altenpflege - ein. Wir unterstützen all diejenigen, die soziale Verantwortung tragen und sich engagieren möchten.`,
        },
      ],
      neighborhoodArray: [
        `Wir reduzieren Ihren Verwaltungsaufwand`,
        `Statistiken über Ihr Netzwerk und die geleistete Hilfe`,
        `Senioren können über Kategorien konkrete Hilfe auswählen`,
        `Überblick über alle digitalen Anfragen von Senioren`,
        `Ehrenamtliche Helfer können Anfragen direkt sehen und annehmen`,
        `Persönliche Profile garantieren den Helfern nur Anfragen zu erhalten, die auch umsetzbar sind`,
        `Kurzprofile der Alltagshelfer erlauben den richtigen Helfer anzufragen`,
        `Senioren sehen alle verfügbare Alltagshelfer in der Umgebung`,
        `Alltagshelfer können mit dem Senior in Kontakt treten, nachdem sie seine Anfrage angenommen haben.`,
      ],
      colorClass: "orange",
      tabBarContent: [
        {
          text: "Preise, Vergütung & Rechnungsstellung",
          param: "remuneration",
        },
        // "<router-link to='/lifehelpers/tasks'>Aufgaben eines Alltagshelfers</router-link>",
        {
          text: "FAQS",
          param: "faqs",
        },
      ],
      topImages: ["coins.jpg", "faq-neighborhood.jpg"],
    };
  },
  computed: {
    currentSubHeading() {
      return this.$route.params.subheading;
    },
    currentTab() {
      return this.tabBarContent.findIndex(
        (ele) => ele.param === this.currentSubHeading
      );
    },
  },
  created() {
    this.$store.dispatch("setColorClass", this.colorClass);
  },
};
</script>

<style lang="scss">
.neighborhood.bloq25 p {
  color: var(--text-gray);
}

.neighborhood-renumeration-bottom-image {
  // background-image: url("../../assets/images/people-drinking.jpg");
  background-position: center;
  background-size: cover;

  .image-text-block {
    z-index: 0;

    .background-image-absolute {
      opacity: 0.79;
      z-index: -1;
    }
  }
}
</style>
