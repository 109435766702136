<template>
  <div class="table14">
    <table>
      <thead>
        <tr class="tr1" :class="`bg-main-${currentColor}`">
          <th class="hidden sm:table-cell"></th>
          <th class="td1 text-center sm:text-left">{{ tableHeading }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in tableData"
          :key="`tableitem${index}`"
          :class="`${item.itemsList ? 'tr6' : ''} ${
            index % 2 == 1 ? 'bg-main-' + currentColor + '-faded' : ''
          }`"
          class=" "
        >
          <td
            class="td21 hidden sm:table-cell"
            :class="`text-main-${currentColor}`"
          >
            {{ item.leftDatum }}
          </td>
          <td :class="`${item.itemsList ? 'td23' : 'td22'}`">
            <h3
              class="block sm:hidden text-center mb-2"
              :class="`text-main-${currentColor}`"
            >
              {{ item.leftDatum }}
            </h3>
            <p v-if="item.itemsList" class="text-center sm:text-left">
              {{ item.rightDatum }}
            </p>
            <span
              v-else
              v-html="item.rightDatum"
              class="text-center block sm:inline sm:text-left"
            ></span>
            <ul v-if="item.itemsList">
              <li
                v-for="(element, index) in item.itemsList"
                :key="`element${index}`"
                class="text-center sm:text-left"
              >
                <span class="gt" :class="`text-main-${currentColor}`"
                  >&#10095;</span
                >
                <span v-html="element"></span>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Table11",
  props: {
    tableHeading: { type: String, default: "Comii Senior Basique" },
    tableData: { type: Array[Object] },
  },

  computed: {
    currentColor() {
      return this.$store.getters.colorClass;
    },
  },

  created() {
    console.log(this.tableData);
  },
};
</script>

<style lang="scss" scoped></style>
