<template>
  <div>
    <top-hero
      appLink="helfer"
      :image="currentTab < 0 ? 'old-couple.jpg': topImages[currentTab]"
      :colorClass="$store.getters.colorClass"
      :mainTitle="currentTab < 0 ? 'Für Alltagshelfer' : tabBarContent[currentTab].text"
      :isMainHero="currentTab < 0"
      :heading-border-left="true"
      image-position="center"
      :isColorTitle="true"
      :heroWhiteGradient="true"
    >
     Hier kannst Du die Comii Alltaghelfer App auf Dein Handy herunterladen: 
    </top-hero>
    <tab-bar-view
      :tabs="tabBarContent"
      secondary-base-path="Für Alltagshelfer/innen"
      base-url="/lifehelpers"
      :colorClass="$store.getters.colorClass"
      section-title=""
    >
      <div v-show="!currentSubHeading">
        
        <!-- For Everyday helpers first block after 
          the main top image and tab bar view
        -->
        <bloc-21
          title="Comii – Unsere Helfer"
          content="Unsere Alltagshelfer sind eine tragende Säule
            von Comii. Nicht jeder kann alles machen. Und genau aus diesem
            Grund müssen unsere Alltagshelfer ein persönliches Profil erstellen.
            So stellen wir sicher, dass nur ein Helfer zu Ihnen nach Hause
            kommt, der Sie bestmöglich unterstützt."
        ></bloc-21>
        <!-- 
          Second block which has left 
          image and right content 
        -->
        <div>
          <bloc-1
          image="home-3.jpg"
          :no-action-button="true"
          :no-store-buttons="true"
          :no-top-h-line="true"
          title="Alltagshelfer"
          >
          <template #text>
            Zu unseren zahlreichen Alltagshelfern gehören professionelle Betreuungskräfte, Seniorenbetreuer, mobile
            Pflegedienste, qualifiziertes Pflegepersonal, Schüler, Studenten,
            Hausmeister oder auch Handwerker.<br><br> Auch viele Rentner oder Menschen
            im Vorruhestand möchten in ihrer Freizeit Gutes tun und anderen
            Menschen helfen.
          </template>
          </bloc-1>
          <bloc-1
          image="photo1.jpg"
          :reverse="true"
          :no-action-button="true"
          :no-store-buttons="true"
          :no-top-h-line="true"
          title="Freiwillige"
          >
          <template #text>
            Alle unsere Alltagshelfer werden von uns geprüft und
            authentifiziert.<br><br> Alle Alltagshelfer sind verpflichtet, 
	    eine Haftpflichtversicherung zu besitzen. Darüber hinaus verwenden wir ein
            transparentes Bewertungssystem, um Erfahrungen in Comiizu teilen.
          </template>
          </bloc-1>
        </div>
      </div>
      <tasks 
        v-show="currentSubHeading === 'tasks'"
      ></tasks>
      <how-it-works v-show="currentSubHeading === 'howitworks'">
      </how-it-works>
      <become-helper class="mt-12" v-show="currentSubHeading === 'becomehelper'">
      </become-helper>
      <payment
        v-show="currentSubHeading === 'payment'"
      ></payment>
      <assistants
        v-show="currentSubHeading === 'assistants'"
      ></assistants>
      <faqs
        v-show="currentSubHeading === 'faqs'"
      ></faqs>
    </tab-bar-view>

    <!-- Main Helpers Page after tab-bar-view -->
    <div
      v-show="!currentSubHeading"
      class="lg:mt-10 mt-0 bg-orange-main w-full h-64 flex items-center justify-center"
    >
      <div class="page-block w-3/5 text-center text-white">
        <p class="text-white text-lg mb-10">Wir haben alle nur ein Ziel:</p>
        <h1 class="lg:text-5xl text-xl text-center font-light italic">
          Senioren bestmöglich zu helfen, Gutes tun und dabei Spass zu haben.
        </h1>
      </div>
    </div>


    <!-- Helpers Tasks page after tab-bar-view -->
    <div class="mb-20" v-show="currentSubHeading === 'tasks'">
			<top-hero
				image="man-wife-sitting.jpg"
				mainTitle="Comii Alltagshelfer sind Comii Superhelden..."
				colorClass="orange"
				:isMainHero="false"
				image-position="center"
				hero-bottom-padding="180px"
				heading-border-top
			>
			</top-hero>
			<div class="lg:flex gap-8 mx-auto -mt-28 max-w-screen-lg">
				<card-2
					v-for="(item, id) in datacard2" :key = id
					:heading = item.heading
					:content= item.content
					:color-class= item.colorClass
					class="flex-1"
					:number="`0${id + 1}`"
			> </card-2>
			</div>
		</div>

    <!-- How It works page after tab-bar-view -->
    <div class="mb-20" v-show="currentSubHeading === 'howitworks'">
      <top-hero
        :image="currentTab < 0 ? 'old-couple.jpg': topImages[currentTab]"
        :colorClass="$store.getters.colorClass"
        mainTitle="Für Alltagshelfer"
        isMainHero
        exists-telecharger-button
        heading-border-top
        hideSelect
        image-position="center"
      >
        Alltagshelfer finden die Comii 
	Alltagshelfer App in allen App Stores:
      </top-hero>

      <bloc-21
        no-content
        title="Rechnungsstellungsprozess bei Comii"
      ></bloc-21>
      <card-25
        class="page-block block-padding"
        image="teacher-students.jpg"
      >
        <template #side>
          <div class="mt-2 text125">
            <div class="title125">
              <span class="sp25">01</span>            
            </div>
            <p>
              Nach Auftragsende steht die Rechnung in Deinem Namen in der App bereit.
	      Bei Dir als Ausgangsrechnung und beim Senior als Eingangsrechnung, die zu bezahlen ist.
            </p>
          </div>
          <div class="text125">
            <div class="title125">
              <span class="sp25">02</span>
            </div>
            <p>
              Der Senior muss die Rechnung direkt
	      nach dem Auftrag in der Comii App bezahlen. 
            </p>
          </div>
          <div class="text125">
            <div class="title125">
              <span class="sp25">03</span>
            </div>
            <p>
              Warte bis der Senior diese an Dich bezahlt hat. Und bedanke Dich für das Vertrauen.
            </p>
          </div>
          <div class="text225">
            <div class="title225">
              <span class="sp25">04</span>            
            </div>
            <p> Das Geld wird Dir direkt über Stripe, abz. 9,5% Transaktionskosten, gutgeschrieben.</p>
          </div>
        </template>
      </card-25>
    </div>

    <!-- Become Helper page after Tab-bar-view -->
    <div 
      v-show="currentSubHeading === 'becomehelper'"
      class=""
    >
      <div class="bg-another-gray">
        <div class="page-block block-padding">
          <div class="flex text-left lg:mx-20 lg:justify-end">
            <div class="mt-5">
              <div class="inline-block h-1 w-24 rounded bg-orange-main mb-2"></div>
              <h1 class="text-main-orange text-2xl">Melde bei Deiner Stadt<br> ein Gewerbe als Alltagshelfer an.</h1>
            </div>
          </div>
          
          <div class="lg:flex gap-5 w-full">
            <div class="lg:w-1/2 w-full">
              <div class="mt-2 text125">
                <div class="title125">
                  <span class="sp25">01</span>
                </div>
                <p>Schliesse eine Haftpflichtversicherung ab, falls Du keine hast. Das Finanzamt sendet Dir einen steuerlichen Erfassungsbogen, in dem Du angeben musst, ob Du eine Rechnung mit oder ohne Umsatzsteuer ausstellen möchtest.
		Wenn Du weniger als 22.000 Euro an Umsatz pro Jahr anstrebst, so ist die Kleinunternehmerregelung - also Rechnung ohne Umsatzsteuer - für Dich ideal.</p>
              </div>
              <div class="text125">
                <div class="title125">
                  <span class="sp25">02</span>
                </div>
                <p>Damit erhältst Du die Erlaubnis, als "Kleingewerbetreibender" zu arbeiten und Rechnungen ohne Mehrwertsteuer ausstellen zu dürfen. Als Nachweis verwaltest Du Deine Steuernummer in Deinem Profil.</p>
              </div>
              <div class="text225">
                <div class="title225">
                  <span class="sp25">03</span>
                </div>
                <p>In Deinem Comii Profil bitte "Kleinunternehmer" angeben. Du kannst auch unterschiedliche Profile anlegen.</p>
              </div>
              <div class="text225 mt-4 pb-6">
                <div class="font-bold title225">
                  <h1>Jetzt kannst Du loslegen und mit Comii Gutes tun!</h1>
                </div>
              </div>
            </div>
            <img class="lg:w-1/2 w-full mt-8" v-lazy="require('@/assets/images/doctor-2-old-women.jpg')" alt="">           
          </div>
        </div>
      </div>

      <div class="page-block block-padding">
        <div class="mt-10 text-2xl mb-8">
          <div class="inline-block h-1 w-24 rounded bg-orange-main mb-2"></div>
          <h1 class="text-main-orange">Was Sie jedoch tun<br> müssen</h1>
        </div>
        <div class="lg:flex hidden grid lg:grid-cols-3 grid-cols-1 gap-10 pb-6">
          <bloq-25 
            v-for="(whatToDo, index) in whatToDoArray" 
            :key="index"
            :number="index + 1"
            :text="whatToDo"
            class="text-xl flex-1"
          >
          </bloq-25>
        </div>
        
        <div class="ml-5 lg:hidden block">
          <div class="text125" v-for="(whatToDo, index) in whatToDoArray" :key="index">
            <div class="title125 mb-3 text-2xl">
              <span class="sp25">{{ index + 1 }}</span>
            </div>
            <p>{{ whatToDo }}</p>
          </div>
        </div>

      </div>

      <div class="page-block">
        <div class="flex mt-5 justify-center text-center">
          <div>
            <div class="inline-block h-1 w-24 rounded bg-orange-main mb-2"></div>
            <div class="text-4xl text-main-orange font-bold mb-8" style="font-family: Roboto;"><span>Alltagshelfer werden</span></div>
          </div>
        </div>

        <div class="lg:flex w-full h-auto">
          <div class="bg-main-green text-2xl lg:w-2/5 px-10 py-20 text-white">
            <h1 class="mb-2">Schritte 1</h1>
            <p class="">Gewerbeanmeldung als Alltagshelfer bei Deiner örtlichen Stadtverwaltung ausfüllen: ca. 15 - 30 €, 
              online Anträge oft günstiger.</p><br><br>

              <p class="font-bold">Du bekommst damit die Gewerbeanmeldung als Alltagshelfer</p>                        
          </div>
          <img class="lg:w-3/5" v-lazy="require('@/assets/images/photo3.jpg')" alt="">
        </div>

        <div class="lg:flex w-full h-auto">
          <img class="lg:block hidden lg:w-3/5" v-lazy="require('@/assets/images/home-5.jpg')" alt="">
          <div class="bg-main-orange text-2xl lg:w-2/5 px-10 py-20 text-white">
            <h1 class="mb-2">Schritte 2</h1>
            <p class="">Das Finanzamt schickt Dir einen  <span class="font-bold">"steuerlichen Erfassungsbogen"</span>, 
              da Du als Selbständiger dann eine neue Steuernummer bekommst. </p><br><br>
            <p class="font-bold">Bitte hier darauf achten, dass Du ggf. die Kleinunternehmerregelung (Rechnungen ohne Umsatzsteuer) nutzt. Dies erspart Dir eine monatliche Umsatzsteuermeldung!</p>                        
          </div>
          <img class="lg:w-3/5 lg:hidden block" v-lazy="require('@/assets/images/home-5.jpg')" alt="">
        </div>

        <div class="lg:flex w-full h-auto">
          <div class="bg-main-purple text-2xl lg:w-4/6 px-2 lg:px-15 py-20 text-white">
            <h1 class="mb-5">Schritte 3</h1>
            <p class="ml-5 mb-5">Vervollständige Dein Profil in der Comii-App:</p> 
   
            <div class="w-full">
              <p class="mb-2">
              <span class="pr-4 font-bold text-white">&#10095;</span>
              Lade Deine Gewerbeanmeldung unter "Dokumente" hoch
              </p>
            <p class="mb-2">
              <span class="pr-4 font-bold text-white">&#10095;</span>
              Ergänze Deine Steueridentifikationsnummern bzw. Steuernummer in Deinem Profil
            </p>
            <p class="mb-2">
              <span class="pr-4 font-bold text-white">&#10095;</span>
              Bestätige, dass Du eine ausreichende Haftpflichtversicherung hast
            </p>
            <p class="mb-2">
              <span class="pr-4 font-bold text-white">&#10095;</span>
              Ergänze Deine Präferenzen, also Dinge die Du erledigen kannst bzw. möchtest
            </p>
            <p class="mb-2">
              <span class="pr-4 font-bold text-white">&#10095;</span>
              Gib auf Wunsch Deinen Impfstatus an
            </p>
            </div>
            
          </div>
          <img class="lg:w-2/6 object-cover" v-lazy="require('@/assets/images/old-couple.jpg')" alt="">
        </div>

      </div>
      <bloc-21
        class="page-block block-padding"
        title="Für Alltagshelfer<br/> Qualitätskriterien und Nachweise"
        content="Comii setzt sich für Qualitätsstandards im Bereich der Alltagshilfe ein. Deshalb hat jeder Alltagshelfer die Möglichkeit, Qualifikationen, Zertifikate und Diplome in ein persönliches Profil hochzuladen. Senioren sehen diese Zertifikate nicht! Sobald deine Zertifikate hochgeladen sind, sehen die Senioren, dass du diese Zertifikate/Diplome besitzt. <br/><br/>Dazu hast du die Möglichkeit, deine Erfahrungen oder Fähigkeiten auf einer Sterneskala, anhand von Kategorien und Unterkategorien zu benennen oder die möglichen Kategorien auszuwählen, die du bereitstellen möchtest. <br/><br/> Die Dokumente, die vor dem Speichern im PDF-Format vorbereitet werden müssen, sind folgende:"
        reduced-margins
        normal-h-rule-color
        full-width
        content-left
      ></bloc-21>
      <div class="text-orange-main page-block block-padding grid lg:grid-cols-2 grid-cols-1 lg:w-4/5 pb-12">
      
      </div>
    </div>
  </div>
</template>

<script>
import TopHero from "../../components/global/TopHero.vue";
import TabBarView from "../../components/global/TabBarView.vue";
import Bloc1 from "../../components/Bloc1.vue";
import Bloc21 from "../../components/Bloc21.vue";
import Tasks from "./tasks.vue";
import BecomeHelper from "./becomehelper.vue";
import HowItWorks from "./howitworks.vue";
import Payment from "./payment.vue";
import Assistants from "./assistants.vue";
import Faqs from "../for_seniors/faqs.vue";
import Card2 from "../../components/Card2.vue";
import Card25 from "../../components/Card25.vue";
import Bloq25 from "../../components/Bloq25.vue";
import {datacard2} from "../../data/data"
// import Bloc25 from "../../components/Bloc25.vue";
// import Blok25 from "../../components/Blok25.vue";
// import LeftArrowText from "../../components/utils/LeftArrowText.vue";
export default {
  name: "LifeHelpers",
  components: {
    TopHero,
    TabBarView,
    Bloc1,
    Bloc21,
    Tasks,
    HowItWorks,
    Card2,
    Card25,
    BecomeHelper,
    Bloq25,
    // Bloc25,
    // Blok25,
    // LeftArrowText,
    Payment,
    Assistants,
    Faqs
  },
  data() {
    return {
      datacard2,
      whatToDoArray: [
        `Informationen für Personen, die eine gewerbliche Tätigkeit ausüben (selbstständiger Hausmeister, mobile Pflegedienste, ...)`,
        `Registrieren Sie sich auf Comii als Gewerbetreibender und geben Ihre Umsatzsteuer-Identifikationsnummer an.`,
        `Und Sie können bereits Ihre erste Anfrage annehmen.`
      ],
      colorClass: "orange",
      tabBarContent: [
        {
          text: "Wie&nbsp;funktioniert Comii?",
          param: "howitworks"
        },
        // "<router-link to='/lifehelpers/tasks'>Aufgaben einer Lebenshelferin</router-link>",
        {
          text: "Aufgaben&nbsp;eines Alltagshelfer",
          param: "tasks",
        },
        {
          text: "Alltagshelfer&nbsp; werden",
          param: "becomehelper"
        },
        {
          text: "Bezahlung,&nbsp;Preise&nbsp;& Rechnungsstellung",
          param: "payment"
        },
        {
          text: "Unsere&nbsp;Alltagshelfer",
          param: "assistants"
        },
        {
          text: "FAQS",
          param: "faqs"
        }
      ],
      topImages: [
        'old-women-with-phone.jpg',
        'doctor-2-old-women.jpg',
        'man-woman-with-tablet.jpg',
        'coins.jpg',
        'doctor-with-patient.jpg',
        'faqs-female.jpg'
      ]
    };
  },
  computed: {
    currentSubHeading() {
      return this.$route.params.subheading;
    },
    currentTab() {
      return this.tabBarContent.findIndex( ele => ele.param === this.currentSubHeading);
    }
  },
  created() {
    this.$store.dispatch("setColorClass", this.colorClass);
  },
  // methods: {
  //   onTabChanged() {
  //     // this.currentTab = index;
  //   },
  // },
};
</script>

<style>
</style>
