var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("top-hero " + (_vm.isMainHero ? 'main-hero' : ''))},[_c('div',{staticClass:"content-wrapper page-block block-padding",class:{
        'py-20': !_vm.isMainHero && !_vm.headingBorderTop,
        'pt-8': _vm.headingBorderTop,
      },style:(("background-image: " + (_vm.heroWhiteGradient
          ? 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,7) 20%, rgba(255,255,255,0) 50%)'
          : _vm.heroDarkGradient
          ? 'radial-gradient(closest-side at 50% 50%, rgba(3, 1, 91, 0) 0%, rgba(3, 1, 90, 0.024) 18%, rgb(34 36 34 / 50%) 100%)'
          : 'linear-gradient(90deg, transparent, transparent)') + ", url( " + (require('@/assets/images/' + _vm.image)) + ");\n\n\t\t\tbackground-position: " + _vm.imagePosition + ";\n      padding-bottom: " + _vm.heroBottomPadding + "\n    "))},[_c('div',{staticClass:"hero-content w-full sm:w-96",class:((_vm.isMainHero ? 'pt-32' : 'pt-24') + " " + (_vm.isMainHero ? 'pb-12' : 'pb-20'))},[(_vm.headingBorderTop || _vm.headingBorderLeft)?_c('div',{staticClass:"h-1 responsive-top-bar-lg rounded-xl mx-0 sm:mx-auto ",class:{ 'md:hidden ml-4 mb-2':!_vm.headingBorderTop && _vm.headingBorderLeft},style:({
            'background-color': ("var(" + _vm.currentColor + ")"),
            'margin-top': _vm.headingBorderTop ? '' : '',
          })}):_vm._e(),_c('div',{staticClass:"hero-title responsive-heading1.5 text-left font-bold mb-6",class:{
            'pt-6 mx-auto sm:mx-0': _vm.headingBorderTop,
            'border-0 md:border-l-4 pl-4': !_vm.headingBorderTop && _vm.headingBorderLeft,
            
          },style:({
            color:
              _vm.isColorTitle ? ("var(" + _vm.currentColor + ")") : _vm.isWhiteTitle? 'white': 'var(--breadcrump-dark)',
            'border-left-color': ("var(" + _vm.currentColorBorderLeft + ")"),
          }),domProps:{"innerHTML":_vm._s(_vm.mainTitle)}}),_c('div',{class:{ 'pl-4': _vm.headingBorderLeft }},[(_vm.isMainHero)?_c('div',{staticClass:"hero-text smaller-text-responsive text-gray-main text-left text-lg mb-6 "},[_vm._t("default")],2):_vm._e(),(_vm.isMainHero)?_c('div',{staticClass:"store-buttons lg:w-3/5 w-2/3 flex lg:justify-start justify-start"},[(_vm.appLink == 'senior')?_c('a',{staticClass:"storebut mr-4",attrs:{"href":"https://play.google.com/store/apps/details?id=com.comii.senior&gl=DE"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('@/assets/images/play-store.png')),expression:"require('@/assets/images/play-store.png')"}],attrs:{"alt":"google play link"}})]):_vm._e(),(_vm.appLink == 'senior')?_c('a',{staticClass:"storebut",attrs:{"href":"https://apps.apple.com/de/app/comii-senior/id1584937013"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('@/assets/images/app-store.png')),expression:"require('@/assets/images/app-store.png')"}],attrs:{"alt":"google play link"}})]):_vm._e(),(_vm.appLink == 'helfer')?_c('a',{staticClass:"storebut mr-4",attrs:{"href":"https://play.google.com/store/apps/details?id=com.comii.support&gl=DE"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('@/assets/images/play-store.png')),expression:"require('@/assets/images/play-store.png')"}],attrs:{"alt":"google play link"}})]):_vm._e(),(_vm.appLink == 'helfer')?_c('a',{staticClass:"storebut",attrs:{"href":"https://apps.apple.com/de/app/comii-alltagshelfer/id1584936494"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('@/assets/images/app-store.png')),expression:"require('@/assets/images/app-store.png')"}],attrs:{"alt":"google play link"}})]):_vm._e()]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }