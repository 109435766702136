<template>
  <div class="prices mb-7">
    <div class="w-full sm:w-4/5 xl:w-3/5 mx-auto text-center responsive-heading3">
      <div
        class="top-bar rounded bg-green-light-main responsive-top-bar h-1 my-4 mx-auto"
      ></div>
      <h2 class="text-green-main responsive-heading1.5 font-bold mb-6">
        Preise &amp; Rechnungsstellung
      </h2>

      <p class="mb-8 text-gray-main font-extralight">
        Comii hat transparente und attraktive Preise. Sie zahlen maximal 25 Euro/Stunde. 
        Die ersten 30 Minuten (12,50 Euro) werden pauschal berechnet. 
        Sie brauchen kein Bargeld! Sie erhalten in Comii die Rechnung und bezahlen direkt in unserer App. Das Geld wird an den Alltagshelfer weitergeleitet!
      </p>
    </div>

    <div
      class="prices-block flex items-stretch justify-evenly lg:justify-between flex-wrap"
      v-if="!currentCategory"
    >
      <bloc-13
        class="mb-6"
        v-for="(opt, index) in priceOptions"
        :key="`price${index}`"
        :title="opt.title"
        :price="opt.price"
        :toUrl="$route.path + '?category=' + opt.to"
      />
    </div>

    <div v-for="(table, index) in tablesData" :key="`table${index}`" class="">
      <table-14
        v-if="table.category === currentCategory"
        :tableHeading="table.heading"
        :tableData="table.data"
      />
    </div>

    <router-link
      v-if="currentCategory"
      class="block mx-auto px-6 mt-6 py-2 border 
      w-max border-green-main text-green-main hover:bg-green-main 
      hover:text-white transition-colors font-semibold"
      :to="$route.path.split('?')[0]"
    >
      Zurück
    </router-link>
  </div>
</template>

<script>
import Bloc13 from "../../components/Bloc13.vue";
import Table14 from "../../components/Table14.vue";
export default {
  name: "Prices",
  data() {
    return {
      priceOptions: [
        { title: "Comii Senior Basic", price: "0", to: "basic" },
        { title: "Comii Senior Silber", price: "4,99", to: "silver" },
        { title: "Comii Senior Premium", price: "7,99", to: "committees" },
      ],

      tablesData: [
        {
          heading: "Comii Senior Basic (noch nicht verfügbar)",
          category: "basic",
          data: [
            { leftDatum: "APP-Gebühren", rightDatum: "Kostenlos" },
            {
              leftDatum: "Freiwillige Helfer",
              rightDatum:
                'Bis zu 10 freiwillige Helfer im Alltag einsetzen <span class="text-green-main">(Nur 2,50 € pro Einsatz)</span>',
            },
            {
              leftDatum: "Gewerbliche Alltagshelfer",
              rightDatum:
                'Bis zu 5 Alltagshelfer (Fachkräfte für Alltagshilfe erhalten maximal <span class="text-green-main">25 €/Stunde)</span>',
            },
            {
              leftDatum: "Zeitintervall für die Rechnungsstellung",
              rightDatum:
                "Unsere Alltagshelfer werden in den ersten 30 Minuten pauschal bezahlt, danach in einem Zeitrythmus von 15 Minuten.",
            },
            {
              leftDatum: "Comii Funktionsumfang",
              rightDatum: "Voller Funktionsumfang (ohne Premiumfunktionen)",
              itemsList: [
                "Automatische Suche nach Alltagshelfern in Ihrer Region.",
                "Individuelle Auswahl der Helfer",
                "Transparente Bestell- und Rechnungsübersichten in Ihrem App-Profil.",
                "Einfaches und bargeldloses Bezahlen mit SEPA oder Kreditkarte",
              ],
            },
          ],
        },
        {
          heading: "Comii Senior Silber",
          category: "silver",

          data: [
            {
              leftDatum: "App-Gebühren",
              rightDatum: `<span class="text-green-main">4,99 €/Monat (Monatlich kündbar)</span>`,
            },
            {
              leftDatum: "Freiwillige Helfer",
              rightDatum:
                'Unbegrenzter Einsatz freiwilliger Alltagshelfer <span class="text-green-main">(nur 2,50€ pro Einsatz)</span>',
            },
            {
              leftDatum: "Gewerbliche Alltagshelfer",
              rightDatum:
                'Unbegrenzte Beauftragung gewerblicher Alltagshelfer. <span class="text-green-main"> (Alltagshelfer erhalten 25 €/Stunde)</span>',
            },
            {
              leftDatum: "Zeitintervall für die Rechnungsstellung",
              rightDatum:
                "Alle Alltagshelfer erhalten eine pauschale Vergütung für die ersten 30 Minuten (12,50 Euro) und danach in Zeitintervallen von 15 Minuten.",
            },
            {
              leftDatum: "Funktionen der APP",
              rightDatum: "Voller Funktionsumfang (ohne Premiumfunktionen)",
              itemsList: [
                "Automatische Suche nach Alltagshelfern in Ihrer Region.",
                "Individuelle Auswahl der Helfer",
                "Transparente Bestell- und Rechnungsübersichten in Ihrem App-Profil.",
                "Einfaches bezahlen direkt in unserer App nach Beendigung des Auftrages",
              ],
            },
          ],
        },
        {
          heading: "Comii Senior Premium (noch nicht verfügbar)",
          category: "committees",

          data: [
            {
              leftDatum: "App-Gebühren",
              rightDatum: `<span class="text-green-main">7,99 € /Monat (Monatlich kündbar)</span>`,
            },
            {
              leftDatum: "Freiwillige Helfer",
              rightDatum:
                'Unbegrenzter Einsatz freiwilliger Alltagshelfern <span class="text-green-main"> (keine weiteren Kosten)</span>',
            },
            {
              leftDatum: "Gewerbliche Alltagshelfer",
              rightDatum:
                'Unbegrenzte Beauftragung professionellen Alltagshelfer. <span class="text-green-main"> (Alltagshelfer erhalten 25 €/Stunde)</span>',
            },
            {
              leftDatum: "Zeitintervall für die Rechnungsstellung",
              rightDatum:
                "Alltagshelfer werden in den ersten 30 Minuten pauschal bezahlt, danach im Rythmus von 15 Minuten.",
            },
            {
              leftDatum: "Funktionen der APP",
              rightDatum: "",
              itemsList: [
                `Zusätzlich mit Kalenderfunktion`,
                `Zusätzlich mit "Beauftragung für Hilfesuchende ohne Smartphone".`,
                "Zusätzlich mit Live-Tracking & verschlüsselte Sprachaufzeichnung von Alltagshelfern",
              ],
            },
          ],
        },
      ],
    };
  },
  components: { Bloc13, Table14 },

  computed: {
    currentCategory() {
      return this.$route.query.category;
    },
  },

  //   created(){
  //           console.log(this.$route);

  //   }
};
</script>

<style lang="scss" scoped></style>
