<template>
  <div class="page-block block-padding" style="word-wrap: break-word; margin-top:40px;">
    <h1 class="text-2xl" align="center">Cookie-Erklärung</h1>
    <h2 style="color:#2f5496">Inhalt</h2>
    <p>
      <a
        href="#_Toc81561112"
      >
        Allgemeines
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561113"
      >
        Aktivieren/Deaktivieren von Cookies und Cookies löschen
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561114"
      >
        Personalisiertes Cookie-Paket
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561115"
      >
        Essentielles Cookie-Paket
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561116"
      >
        Welche Technologien verwenden wir?
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561117"
      >
        1. Was ist ein Skript?
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561118"
      >
        2. Was ist ein Tracker?
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561119"
      >
        3. Was sind Cookies?
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561120"
      >
        4. Was sind Social-Media-Buttons?
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561121"
      >
        Warum verwenden wir diese Technologien?
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561122"
      >
        1. Funktionszwecke
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561123"
      >
        2. Analysezwecke
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561124"
      >
        3. Marketingzwecke
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561125"
      >
        Der Schutz Ihrer Daten durch uns und durch Dritte
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561126"
      >
        1. Der Schutz Ihrer Daten durch Comii.de
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561127"
      >
        2. Technologien von Dritten
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561128"
      >
        Welche Rechte haben Sie?
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561129"
      >
        1. Aktivieren und Deaktivieren von Cookies und Löschen von Cookies
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561130"
      >
        2. Recht auf Einsichtnahme, Korrektur oder Löschung Ihrer Daten
      </a>
    </p>
    <p>
      <a
        href="#_Toc81561131"
      >
        3. Tipps, Fragen und Beschwerden
      </a>
    </p>
    <h1>
      <a name="_Toc81561112">Allgemeines</a>
    </h1>
    <p>
      Comii.de ist ein Service der Digital Suxess AG, Stuttgart
      (www.DigitalSuxess.de). Wir nutzen auf unserer Comii Website und in
      unseren Anwendungen Cookies, Tracker, Skripte und Social-Media-Buttons (im
      Folgenden gemeinsam die „Technologien“ genannt). Diese helfen uns, Ihnen
      ein besseres, schnelleres und sichereres Erlebnis auf unserer Webseite zu
      bieten. Auch die von Comii.de eingesetzten Cookies von Dritten nutzen
      diese Technologien. Natürlich nehmen wir den Schutz Ihrer Daten im
      Zusammenhang mit diesen Technologien sehr ernst. Für weitere Informationen
      darüber, wie wir mit Ihren Daten umgehen, möchten wir Sie gerne auf unsere
      Datenschutzerklärung verweisen.
    </p>
    <p>
      Mit dieser Cookie-Erklärung möchten wir Ihnen gerne erklären, welche
      Technologien wir verwenden und warum wir sie verwenden. Wenn Sie noch
      weitere Fragen über unsere Verwendung von Technologien oder über den
      Schutz Ihrer Daten im Zusammenhang mit diesen Technologien haben, können
      Sie uns jederzeit eine E-Mail schicken: Datenschutz@comii.de.
    </p>
    <h1>
      <a name="_Toc81561113">
        Aktivieren/Deaktivieren von Cookies und Cookies löschen
      </a>
    </h1>
    <h2>
      <a name="_Toc81561114">Personalisiertes Cookie-Paket</a>
    </h2>
    <p>
      Sowohl wir als auch Dritte mit denen wir kooperieren, zeigen Dir ggf.
      personalisierte Anzeigen. Diese Anzeigen könnten für Dich relevant sein.
      Unsere Website funktioniert auch bei deaktivierten Cookies wie gewohnt.
      Wir verwenden funktionale Cookies, welche beispielsweise in Deinem Profil
      hinterlegten, vorläufigen Präferenzen speichern. Zudem verwenden wir
      analytische Cookies um unsere Webseite und Dienste stets auf einem
      bestmöglichen technischen Stand zu halten und um Probleme oder
      Verbesserungsmöglichkeiten zügig zu erkennen.
    </p>
    <h2>
      <a name="_Toc81561115">Essentielles Cookie-Paket</a>
    </h2>
    <p>
      Sowohl wir als auch Dritte mit denen wir kooperieren, zeigen Dir
      möglicherweise unpersonalisierte Anzeigen. Diese richten sich allerdings
      nicht nach Deinen Interessen oder Servicepräferenzen. Unsere Website
      funktioniert auch ohne Aktivierung bzw. Deaktivierung wie gewohnt. Hierfür
      nutzen wir funktionsbezogene Cookies, wie das Cookie, das zur Speicherung
      des Inhalts Ihrer Anfragen für einen Alltagshelfer dient. Zudem verwenden
      wir analytische Cookies, um zu sehen, wie man die Website verbessern kann.
    </p>
    <h2>
      <a name="_Toc81561116">Welche Technologien verwenden wir?</a>
    </h2>
    <p>
      Wie bereits zuvor erwähnt, verwenden wir verschiedene Technologien, um
      Daten zu sammeln. Unter anderem setzen wir Skripte, Tracker und Cookies
      ein. Im Folgenden erläutern wir die Technologien, die wir verwenden.
    </p>
    <h2>
      <a name="_Toc81561117">1. Was ist ein Skript?</a>
    </h2>
    <p>
      Ein Skript ist ein Programmcode, der die Funktionalität und
      Interaktivität unserer Website und deren Inhalte unterstützt. Dieser Code
      kann auf unserem Server oder auf Ihrem Gerät ausgeführt werden.
    </p>
    <h2>
      <a name="_Toc81561118">2. Was versteht man unter einem Tracker?</a>
    </h2>
    <p>
      Ein Tracker ist ein unsichtbares Textfragment oder eine Abbildung
      auf unserer Website, welche dazu dient, die Nutzung und Navigation auf
      unserer Website zu erfassen. Um diese Nutzung zu erfassen, setzen wir
      mehrere Tracker ein, die jeweils verschiedene Informationen über Sie
      speichern. Wir können auch Dritten gestatten, dass sie Tracker auf unserer
      Website setzen, damit sie für uns den Verkehr auf unserer Website
      überwachen.
    </p>
    <h2>
      <a name="_Toc81561119">3. Was versteht man unter einem Cookie?</a>
    </h2>
    <p>
      Ein Cookie ist eine simple Textdatei, die mit den Seiten unserer
      Website mitgeschickt und von Ihrem Webbrowser auf der Festplatte Ihres
      Computers abgelegt wird. Manche Cookies dienen lediglich dazu, beim Besuch
      einer Website eine Verbindung zwischen Ihren Aktivitäten auf unserer
      Website herzustellen. Andere Cookies werden dauerhaft auf der Festplatte
      Ihres Computers gespeichert und werden an unsere Server oder die Server
      der Dritten zurückgesendet, die die Cookies für uns gesetzt haben, sobald
      Sie unsere Website erneut besuchen.
    </p>
    <h2>
      <a name="_Toc81561120">4. Was sind Social-Media-Buttons?</a>
    </h2>
    <p>
      Ausser Cookies nutzen unsere Websites auch Social-Media-Buttons. Diese
      Buttons dienen dazu, Webseiten auf sozialen Netzwerken wie Facebook,
      Twitter, Instagram und YouTube zu bewerben („liken“) oder zu teilen
      („tweeten“). Diese Buttons funktionieren mithilfe von Codefragmenten, die
      bspw. von Facebook stammen.
    </p>
    <h1>
      <a name="_Toc81561121">Warum verwenden wir diese Technologien?</a>
    </h1>
    <p>
      Wir setzen Cookies, Tracker und Skripte zu verschiedenen Zwecken ein, und
      zwar zu Funktions-, Analyse- und Marketingzwecken. Im Folgenden
      präsentieren wir eine Übersicht über die Verwendung der Technologien und
      deren Zwecke. Für eine spezifische Übersicht aller von uns verwendeten
      Technologien, die entsprechenden Zwecke und der konkreten Funktionen der
      Technologien möchten wir Sie auf unsere Liste der Technologien verweisen.
      Wir passen diese Liste regelmäßig an, damit Sie ein möglichst genaues Bild
      über die von uns verwendeten Technologien erhalten.
    </p>
    <h2>
      <a name="_Toc81561122">1. Funktionszwecke</a>
    </h2>
    <p>
      Wir verwenden verschiedene Technologien, dass unsere Website optimal
      funktioniert und einfach zu verwenden ist. Zu diesem Zweck setzen wir
      Skripte und Cookies ein. Skripte sorgen dafür, dass die Website interaktiv
      ist und dass tatsächlich etwas passiert, wenn Sie auf eine Schaltfläche
      klicken. Cookies dienen dazu, Funktionen wie die Servicepräferenz zu
      ermöglichen. Ohne Cookies könnten die von Ihnen ausgewählten Services
      nicht mit dem Alltagshelfer abgeglichen bzw. gespeichert werden. Auch die
      Speicherung Ihrer Adresse ist eine Funktion, die ohne Cookies nicht
      funktionieren würde. Manche Technologien, die zu Funktionszwecken
      eingesetzt werden, sind für die Funktionalität unserer Website so wichtig,
      dass sie nicht ausgeschaltet werden können. Diese Technologien werden nach
      Ihrem Besuch auf unserer Website nur für ein paar Stunden gespeichert.
    </p>
    <h2>
      <a name="_Toc81561123">2. Analysezwecke</a>
    </h2>
    <p>
      Wir analysieren anhand von Cookies und Trackern Ihr Verhalten auf unserer
      Website, um unsere Website zu verbessern und an Ihre Wünsche anzupassen.
      Wir hoffen unsere Website auf diese Weise möglichst benutzerfreundlich
      gestalten zu können. Zum Beispiel erfassen wir, welche Seiten am
      häufigsten besucht werden und wie Sie auf unserer Website navigieren.
    </p>
    <h2>
      <a name="_Toc81561124">3. Marketingzwecke</a>
    </h2>
    <p>
      Wir hoffen natürlich, dass Sie unsere Website möglichst häufig nutzen, und
      verwenden aus diesem Grund Cookies und Tracker zu Werbezwecken. Mithilfe
      von Cookies können wir Ihnen interessante Angebote, Sonderkonditionen
      und/oder Rabatte anbieten. Zu diesem Zweck analysieren wir unter anderem,
      wie oft Sie unsere Website nutzen und welche Dienstleistung Sie
      interessiert. So können wir unser Angebot und unsere Anzeigen besser auf
      Ihre Wünsche abstimmen. Ein weiterer Marketingzweck, für den wir Tracker
      verwenden, ist es zu prüfen, ob Sie von einer Website unserer Werbepartner
      auf unsere Seite kommen sind. Dies ist notwendig, da wir unsere
      Werbepartner für deren Dienstleistung bezahlen müssen.
    </p>
    <h1>
      <a name="_Toc81561125">
        Der Schutz Ihrer Daten durch uns und durch Dritte
      </a>
    </h1>
    <h2>
      <a name="_Toc81561126">1. Der Schutz Ihrer Daten durch Comii.de</a>
    </h2>
    <p>
      Comii.de nimmt den Schutz Ihrer Daten ernst und ergreift geeignete
      Maßnahmen, um Missbrauch, Verlust, unbefugten Zugriff, unerwünschte
      Veröffentlichung und unerlaubte Änderung zu bekämpfen. Wenn Sie den
      Verdacht haben, dass Ihre Daten nicht angemessen geschützt sind oder Sie
      Hinweise auf Missbrauch haben, dann können Sie uns eine E-Mail schicken:
      <a href="mailto:Sicherheit@comii.de">Sicherheit@comii.de</a>
    </p>
    <p>
      Unser Sicherheitspersonal nimmt jede Meldung ernst und verfolgt diese bis
      zur Lösung. Sie erhalten eine Rückmeldung, sofern dies nicht gegen
      gesetzliche Verpflichtungen verstößt.
    </p>
    <h2>
      <a name="_Toc81561127">2. Technologien von Dritten</a>
    </h2>
    <p>
      Wie bereits erwähnt, setzen wir Technologien von Dritten ein. Diese
      Dritten helfen uns dabei, die beschriebenen Ziele zu erreichen. Wir
      gestatten es Dritten nicht, Ihre Daten zu eigenen Zwecken oder solchen
      Zwecken zu nutzen, die nicht mit unseren Zwecken übereinstimmen. Mit
      Dritten, die in unserem Auftrag Technologien auf unserer Website
      platzieren, schließen wir Verarbeitungsverträge, um ein einheitliches
      Datenschutz- und Geheimhaltungsniveau für Ihre Daten zu gewährleisten. Wir
      sind auch weiterhin für die Verarbeitung Ihrer persönlichen Daten im
      Auftrag von comii.de verantwortlich.
    </p>
    <h1>
      <a name="_Toc81561128">Welche Rechte haben Sie?</a>
    </h1>
    <h2>
      <a name="_Toc81561129">
        1. Aktivieren und Deaktivieren von Cookies und Löschen von Cookies
      </a>
    </h2>
    <p>
      Sie können Ihre Präferenzen für die Technologien (im Internet häufig nur
      als Cookies bezeichnet) jederzeit anpassen. Außerdem haben Sie die
      Möglichkeit, bereits gesetzte Cookies (nur Cookies speichern Daten auf
      Ihrem Gerät) zu löschen. Wenn Sie die Technologien deaktivieren, kann es
      sein, dass Teile unserer Website nicht mehr funktionieren. Technologien,
      die für die Funktionalität der Website notwendig sind (unter anderem
      diverse Skripte und Cookies) können nicht deaktiviert werden. Klicken Sie
      auf einen der folgenden Links, um herauszufinden, wie Sie die
      Einstellungen für jeden Browser anpassen und eventuell Cookies löschen
      können:
    </p>
    <p>Chrome</p>
    <p>Firefox</p>
    <p>Internet Explorer</p>
    <p>Edge</p>
    <p>Safari (iOS)</p>
    <h2>
      <a name="_Toc81561130">
        2. Recht auf Einsichtnahme, Korrektur oder Löschung Ihrer Daten
      </a>
    </h2>
    <p>
      Sie haben das Recht, die Einsichtnahme, Korrektur oder Löschung Ihrer
      Daten zu beantragen (weitere Informationen finden Sie in unserer
      Datenschutzerklärung. Um Missbrauch zu verhindern, können wir Sie bei
      einem solchen Antrag bitten, sich entsprechend zu identifizieren.
    </p>
    <h2>
      <a name="_Toc81561131">3. Tipps, Fragen und Beschwerden</a>
    </h2>
    <p>
      Für weitere Informationen über unsere Datenschutzrichtlinien und die
      Verarbeitung Ihrer persönlichen Daten möchten wir Sie gerne auf unsere
      Datenschutzerklärung verweisen. Wenn Sie Fragen oder Beschwerden im
      Zusammenhang mit unseren Cookie-Richtlinien haben, beantworten wir gerne
      alle Ihre Fragen.
    </p>
    <p>
      Diese Cookie-Erklärung kann angepasst werden und wurde am 13.1.2022
      erstellt.
    </p>
  </div>
</template>

<script>
export default {

     mounted(){
        window.scrollTo(0,0);
    }
};
</script>

<style lang="scss" scoped>
h1,
h2,
h3 {
  color: #2f5496;
  margin-bottom: 15px;
}

p {
  margin-bottom: 25px;

  a {
    color: #2f5496;
    text-decoration: underline;
  }
}
</style>
