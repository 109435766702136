<template>
    <div class="neighborhood lg:flex lg:space-y-0 space-y-5 lg:text-left text-center block gap-6 text-xl flex-grow flex-shrink-0">
        <div class="bg-main-orange lg:flex justify-center inline items-center p-2 h-8 text-white font-bold">
            <span>0{{ number }}</span>
        </div>
        <p>{{ text }}</p>
    </div>
</template>

<script>
    export default {
        props: {
            number: {
                type: Number,
                default: 1
            },
            text: {
                type: String,
                default: `Informationen für Personen, die eine gewerbliche Tätigkeit ausüben (selbstständiger Hausmeister, mobile Pflegedienste, ...)`
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>