<template>
  <div class="text-center lg:mt-16 mt-5"
		:class="{
			'': !leftHRule,
			'': leftHRule,
			'': !noTopMargin
		}"
	>
    <div 
			class="lg:w-4/5 mx-auto"
		>
      <div
        class="inline-block h-1 w-24 rounded"
        :class="{
          'bg-orange-main': normalHRuleColor,
          'bg-orange-light-main': !normalHRuleColor,
					'mb-4': !reducedMargins,
					'mb-2': reducedMargins
        }"
      ></div>
      <div
        class="lg:text-4xl text-xl lg:mb-6 px-8 mb-4 font-bold text-orange-main"
        :class="{
					'': !leftHRule,
					'': !reducedMargins,
					'': reducedMargins
				}"
        style="font-family: Roboto;"
				v-html="title"
      >
        <!-- {{ title }} -->
      </div>
      <p
        v-if="!noContent"
        class="mx-auto lg:mb-12 mb-5 lg:text-2xl text-xl text-gray-main font-extralight"
        v-html="content"
      >
        <!-- {{ content }} -->
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    smallerText: {
      type: Boolean,
      default: false
    },

		reducedMargins: {
			type: Boolean,
			default: false
		},

		noBottomMargin: {
			type: Boolean,
			default: false
		},

		noTopMargin: {
			type: Boolean,
			default: false
		},

    noContent: {
      type: Boolean,
      default: false,
    },
    leftHRule: {
      type: Boolean,
      default: false,
    },

		fullWidth: {
			type: Boolean,
			default: false
		},

    contentLeft: {
      type: Boolean,
      default: false
    },

    normalHRuleColor: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "Comii – Unsere Helfer",
    },
    content: {
      type: String,
      default: `Unsere Alltagshelfer sind eine tragende Säule
				von Comii. Nicht jeder kann alles machen. Und genau aus diesem
				Grund müssen unsere Hilfskräfte ein persönliches Profil erstellen.
				So stellen wir sicher, dass nur ein Helfer zu Ihnen nach Hause
				kommt, von dem Sie auch schon Informationen haben.`,
    },
  },
};
</script>

<style>
</style>
