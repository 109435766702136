<template>
  <div class="services mb-12">
    <div class="w-full sm:w-5/6 mx-auto text-center responsive-heading2 text-gray-main ">
      <div
        class="top-bar rounded bg-green-light-main responsive-top-bar h-1 my-4 mx-auto"
      ></div>
      <h2 class="text-green-main responsive-heading1.5 font-bold mb-6">
        Service Kategorien
      </h2>

      <p class=" mb-20 lg:mb-28">
        Hier erhalten Sie einen Überblick über Beispiele angebotener Servicekategorien.
        Jede Servicekategorie hat zusätzliche Unterkategorien.
      </p>
    </div>

    <div class="grid grid-cols-1 xs:grid-cols-2 mmd:grid-cols-3 gap-5">
    <img v-lazy="require('../../assets/images/Leistungen1.png')" alt="" />
    <img v-lazy="require('../../assets/images/Leistungen2.png')" alt="" />
    <img v-lazy="require('../../assets/images/Leistungen3.png')" alt="" />
    <img v-lazy="require('../../assets/images/Leistungen4.png')" alt="" />
    <img v-lazy="require('../../assets/images/Leistungen5.png')" alt="" />
    <img v-lazy="require('../../assets/images/Leistungen6.png')" alt="" />
      <!-- <bloc-24
        :hideParagraph2="true"
        :hideSubHeading1="true"
        :spaceOutParts="true"
        :justifyParagraphs="true"
        :fillAction="true"
        style="width: 100%"
        v-for="num in 6"
        :key="`bloc24${num}`"
      /> -->
    </div>
  </div>
</template>

<script>
// import Bloc24 from "../../components/Bloc24.vue";
export default {
  // components: { Bloc24 },
};
</script>

<style lang="scss" scoped></style>
