<template>
  <div class="lg:mx-0 mx-8 bloc11 group" :class="`hover:bg-${colorClass}-main`">
    <div class="side11 text-3xl" :class="`bg-${colorClass}-main`">
      <span :class="`group-hover:text-${colorClass}-main`">0{{ number }}</span>
    </div>
    <div
      class="main11 text-xl"
      :style="`${overridePadding ? 'margin:'+ overridePadding : ''}`"
    >
      <p v-html="content"></p>
    </div>
    <div class="tire11" :class="`border-${colorClass}-main`"></div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: `Öffne Comii wenn Du Zeit hast
            `,
    },
    number: {
      type: String,
      default: "1",
    },
    colorClass: {
      type: String,
      default: "orange",
    },
    overridePadding: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped></style>
