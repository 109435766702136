<template>
  <div>
    <bloc-21
      title="Bezahlung,&nbsp;Preise&nbsp;& Rechnungsstellung"
      content="Das Comii Preismodell ist übersichtlich und transparent."   
    >
    </bloc-21>
	<divided-table 
		:colorClass="colorClass" 
		:firstRowBackgroundColored="false"
		class="text-xl"
	>
		<tr class="heading">
			<th></th>
			<th>
				<div class>
					<h1>Comii Alltagshelfer App</h1>
				</div>
			</th>
		</tr>
		<tr>
			<td class="text-orange-main">
				App-Gebühren
			</td>
			<td class="text-orange-main">
				kostenlos
			</td>
		</tr>
		<tr>
			<td>
				Dein Umsatz
			</td>
			<td>
				Alltagshelfer erhalten <span class="font-semibold text-orange-main">25 €/Stunde</span> (Alle Kategorien)
			</td>
		</tr>
		<tr>
			<td>
				Zeiterfassung
			</td>
			<td>
				Du wirst pauschal für die ersten 30 Minuten vergütet, danach alle 15 Minuten. Die Ankunfts- und Abfahrtszeiten beim Senior/Hilfesuchenden sind in der Pauschale enthalten.
			</td>
		</tr>
		<tr>
			<td>
				Transaktionsgebühren
			</td>
			<td>
				Pro Auftrag (Transaktion) erheben wir eine Transaktionsgebühr von nur 9,5 Prozent. Diese Transaktionsgebühr wird direkt von Deinem Umsatz abgezogen.
				Diese Transaktionskosten müssen wir erheben, da die Zahlungsregulierung über Stripe.com etwas kostet und um unsere Kosten decken zu können.
			</td>
		</tr>
		<tr>
			<td>
				Auszahlung Deines Umsatzes
			</td>
			<td>
				Achte darauf, dass der Senior direkt nach dem Auftrag Deine Tätigkeit in der Comii App bezahlt. Nur so ist sichergestellt, dass Du auch bezahlt wirst.
				Per Klick in "Vergütung" siehst Du die ausgeführte Zahlung auf Dein Stripe Bankkonto. Danach wird dieses Geld automatisch auf Dein privates Konto überwiesen. 
			</td>
		</tr>
		<tr>
			<td>
				Funktionsumfang der Comii Helfer App
			</td>
			<td>
				<p class="font-semibold">Volle Funktionalität</p>
				<div class="-ml-7">
					<left-arrow-text
						text="Automatische Suche nach Aufträgen von hilfesuchenden Personen in deiner Region."
						class="py-2"
						no-predefined-font-size
						color-class-not-on-text
						:color-class="colorClass"
					/>
					<left-arrow-text
						text="Akzeptiere Aufträge mit einem Klick."
						class="py-2"
						no-predefined-font-size
						color-class-not-on-text
						:color-class="colorClass"
					/>
					<left-arrow-text
						text="Persönliches Profil mit den Aktivitäten, die du vorschlägst."
						class="py-2"
						no-predefined-font-size
						color-class-not-on-text
						:color-class="colorClass"
					/>
					<left-arrow-text
						text="Transparente Übersicht über Aufträge und Rechnungen in deinem Profil."
						class="py-2"
						no-predefined-font-size
						color-class-not-on-text
						:color-class="colorClass"
					/>
					<left-arrow-text
						text="<span class='font-semibold'>25€</span>/Stunde Einfache und bargeldlose Überweisung. Transparenter Zeitstempel (Erfassung der Stunden)"
						class="py-2"
						no-predefined-font-size
						color-class-not-on-text
						:color-class="colorClass"
					/>
					<left-arrow-text
						text="Einfache und bargeldlose Überweisung."
						class="py-2"
						no-predefined-font-size
						color-class-not-on-text
						:color-class="colorClass"
					/>
					<left-arrow-text
						text="Bewertungssystem für Helfer und Senioren"
						class="py-2"
						no-predefined-font-size
						color-class-not-on-text
						:color-class="colorClass"
					/>
				</div>
			</td>
		</tr>
	</divided-table>
  </div>
</template>

<script>
import Bloc21 from '../../components/Bloc21.vue';
import DividedTable from '../../components/DividedTable.vue';
import LeftArrowText from '../../components/utils/LeftArrowText.vue';
export default {
	components: {
		Bloc21,
		DividedTable,
		LeftArrowText
	},
	props: {
		colorClass: {
			type: String,
			default: "orange"
		}
	}
};
</script>

<style>
</style>
