<template>
  <section class="abt_comii block-padding page-block">
    <div class="top-bar rounded bg-purple-400 responsive-top-bar h-1 mx-auto my-4"></div>
    <h2 class="text-purple-main responsive-heading1 text-center font-bold mb-5">
      Über Comii
    </h2>

    <div class="responsive-heading3 text-gray-600">
      <p class="mb-7">
        <span class="text-purple-main font-bold">Comii </span>
        ist ein Start-Up unter dem Dach der Digital Suxess AG, einem
        Unternehmen, welches sich auf Hightech und künstliche Intelligenz
        spezialisiert hat. Dank dieser Spezialisierung entwickeln wir
        kontinuierlich digitale Geschäftsmodelle, digitale Workflows und 
        helfen jungen Unternehmen, sich erfolgreich zu entwickeln.
      </p>

      <p class="mb-7">
        Sie möchten im Alter so lange wie möglich selbstständig bleiben? Mit
        Comii bieten wir Ihnen ein grosses Netzwerk an Alltagshelfern, die Ihnen
        schnell und kostengünstig helfen können - falls im Alltag
        das eine oder andere Hindernis auftauchen sollte.
      </p>

      <p class="text-purple-main mb-7 italic">
        Wir sind für Sie da, damit Sie weiterhin unabhängig und selbstständig
        verschiedene Lebenssituationen bewältigen und in Ihrem Zuhause bleiben
        können.
      </p>

      <p class="mb-7">
        Und Comii wächst weiter. Jeden Tag kommen neue Alltagshelfer, Pflegefachkräfte,
        pflegende Angehörige und Senioren zu uns. 
        Dank des konstruktiven Feedbacks und der Wünsche
        unserer Helfer und Senioren werden wir in den kommenden Monaten auch
        viele neue Funktionen in unsere Comii App integrieren.
      </p>
    </div>

    <div class="flex flex-col lg:flex-row h-auto lg:h-112 xl:h-96 mb-8 lg:mb-16">
      <img
        class="w-full lg:w-5/12 h-96 lg:h-auto object-cover object-center"
        v-lazy="require('@/assets/images/old-woman-sitting.jpg')"
        alt="image"
      />
      <div class="w-full lg:w-7/12 px-8 responsive-heading3 bg-gray-100 py-6">
        <div class="rounded bg-purple-400 responsive-top-bar h-1 mb-2 mt-10"></div>
        <h3 class="font font-bold responsive-heading2 text-purple-main mb-4">
          Unser Motto: Comii - Selbstständig bleiben!
        </h3>
        <p class="text-gray-500 mb-6">
          Damit verfolgen wir das Ziel, dass Sie im Alltag auf spontane Hilfe
          zählen können. Dazu haben wir eine App entwickelt, mit der Sie auf
          Knopfdruck Hilfe anfordern können.
        </p>

        <p class="text-purple-main mb-9">
          Zögern Sie nicht, uns zu kontaktieren. Comii lebt und wächst, am
          besten mit Ihnen. Wir freuen uns darauf, Sie kennenzulernen!
        </p>

        <div>
            <a class="text-sm text-purple-main border border-purple-300 p-2" href="mailto:info@comii.de">Kontaktieren Sie uns</a>
        </div>
      </div>
    </div>

    <div>
      <div class="top-bar rounded bg-purple-400 responsive-top-bar h-1 mx-auto my-4"></div>
      <h2 class="text-purple-main responsive-heading2 text-center font-bold mb-5">
        Transparente Preise
      </h2>

      <p class="mx-auto text-gray-main responsive-heading3 mb-5 w-full sm:w-3/5 text-center">
        Comii hat transparente und günstige Preise. Sämtliche Erträge stecken wir in unsere Entwicklung, um Ihnen 
        das beste Serviceerlebnis zu bieten!
      </p>
<!----
      <div
        class="flex items-center justify-center text-purple-main text-sm font-bold mb-12"
      >
        Mehr erfahren
        <span
          class="ml-2 flex border font-bold justify-center items-center flex-shrink-0 border-purple-600 rounded-full w-5 h-5"
          >&#10132;</span
        >
      </div>
 ------->
 
    </div>

    <div class="plan-cards 
      grid gap-4 grid-cols-1  sm:grid-cols-2 lg:grid-cols-3  items-stretch
    
     mb-5">
      <!---- ----------------------->

      <div class="plan-card responsive-heading3 w-full mb-4">
        <div class="plan-card__image">
          <img v-lazy="require('@/assets/images/doctor-woman-wheelchair.jpg')" alt="" />
        </div>
        <div class="plan-card__name">Comii Basic</div>
        <div class="plan-card__down px-8 py-5">
          <ul class="plan-card__items">
            <li class="plist-item">Umfassende Funktionen</li>
            <li class="plist-item">Keine laufenden Kosten</li>
            <li class="plist-item">Bis zu 10 freiwillige Helfer beauftragen</li>
            <li class="plist-item">Bis zu 5 professionelle Alltagshelfer beauftragen <strong>(Max. 25 €/Stunde Takt: 30/15/15 Minuten)</strong></li>
            <li class="plist-item">Danach <strong>2,50 €/freiwillige Helfer</strong> </li>
            <li class="plist-item">Leichte Bedienung und klare Übersicht über Ihre Anfragen und Kosten</li>
          </ul>
        </div>
      </div>
      <!---- ----------------------->
      <div class="plan-card responsive-heading3 w-full mb-4">
        <div class="plan-card__image">
          <img v-lazy="require('@/assets/images/old-couple.jpg')" alt="" />
        </div>
        <div class="plan-card__name">Comii Silver</div>
        <div class="plan-card__down px-8 py-5">
          <ul class="plan-card__items">
            <li class="plist-item">Monatlicht für nur <strong> 4,99 €/Monat (59,88 €/Jahr, monatlich kündbar)</strong></li>
            <li class="plist-item">Unbegrenzte Anfragen an Alltagshelfer</li>
            <li class="plist-item">Freiwillige Hilfe für nur <strong> 2,50 €/Auftrag</strong></li>
            <li class="plist-item">Professionelle Alltagshilfen <strong> (Max. 25 €/Stunde) (Abrechnung im 30/15/15 Minuten Takt)</strong></li>
            
          </ul>
        </div>
      </div>
      <!---- ----------------------->

      <div class="plan-card responsive-heading3 w-full mb-4">
        <div class="plan-card__image">
          <img v-lazy="require('@/assets/images/doctor-2-old-women.jpg')" alt="" />
        </div>
        <div class="plan-card__name">Comii Premium</div>
        <div class="plan-card__down px-8 py-5">
          <ul class="plan-card__items">
            <li class="plist-item">Monatlich für nur <strong> 7,99 €/Monat (95,88 €/Jahr, monatlich kündbar)</strong></li>
            <li class="plist-item">Alle Vorteile der Comii-App</li>
            <li class="plist-item">Zusätzliche mit Kalenderfunktion</li>
            <li class="plist-item">Zusätzlich <strong>"Beauftragung aus der Ferne".</strong></li>
            <li class="plist-item">Zusätzlich Hotline Kundenservice</li>
          </ul>
        </div>
      </div>
      <!---- ----------------------->

    </div>
  </section>
</template>

<script>
// import PlanCard from '../../components/PlanCard.vue';
export default {
  components: {},
};
</script>

<style lang="scss" scoped></style>
