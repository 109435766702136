<template>
    <div class="hurry page-block block-padding mb-8">
        <div class="w-full xs:w-5/6 mx-auto text-center responsive-heading3 mb-10">
            <div class="top-bar rounded bg-purple-400 responsive-top-bar h-1 mx-auto my-4"></div>
            <h2 class="text-purple-main responsive-heading1.5 font-bold mb-5">
              Presse
            </h2>
            
            <p class="mx-auto text-pruple-main mb-10 text-purple-main">
              Unser Marketing- und Kommunikations-Team begrüsst Sie herzlich bei Comii!
            </p>
            
            <p class="text-gray-main">
              Sie haben Fragen rund um Comii? Sie benötigen Marketingmaterial wie bspw. Logo oder Flyer?
              Oder Sie möchten in unseren Marketingverteiler aufgenommen werden? Dann
              senden Sie uns gerne Ihre Anfrage an Info@comii.de.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>
