var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center lg:mt-16 mt-5",class:{
			'': !_vm.leftHRule,
			'': _vm.leftHRule,
			'': !_vm.noTopMargin
		}},[_c('div',{staticClass:"lg:w-4/5 mx-auto"},[_c('div',{staticClass:"inline-block h-1 w-24 rounded",class:{
          'bg-orange-main': _vm.normalHRuleColor,
          'bg-orange-light-main': !_vm.normalHRuleColor,
					'mb-4': !_vm.reducedMargins,
					'mb-2': _vm.reducedMargins
        }}),_c('div',{staticClass:"lg:text-4xl text-xl lg:mb-6 px-8 mb-4 font-bold text-orange-main",class:{
					'': !_vm.leftHRule,
					'': !_vm.reducedMargins,
					'': _vm.reducedMargins
				},staticStyle:{"font-family":"Roboto"},domProps:{"innerHTML":_vm._s(_vm.title)}}),(!_vm.noContent)?_c('p',{staticClass:"mx-auto lg:mb-12 mb-5 lg:text-2xl text-xl text-gray-main font-extralight",domProps:{"innerHTML":_vm._s(_vm.content)}}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }