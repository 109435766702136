import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import LifeHelpers from '../views/lifehelpers/index.vue';
import AboutComii from '../views/about_comii/index.vue';
import ForSeniors from '../views/for_seniors/index.vue';
import Neighborhood from '../views/neighborhood/index.vue';
import MobileCare from '../views/mobile_care/index.vue';
import ConditionsEveryday from '../views/conditions-everydayhelpers.vue';
import ConditionsSeniors from '../views/conditions-senior.vue';
import CookiesPolicy from '../views/cookies-policy.vue';
import Impressum from '../views/impressum.vue';
import PrivacyStatement from '../views/privacy-statement.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
 
  {
    path: '/lifehelpers',
    name: 'lifehelpers',
    component: LifeHelpers
  },
  {
    path: '/lifehelpers/:subheading',
    name: 'LifeHelpersHeading',
    component: LifeHelpers
  },
  {
    path: '/about',
    name: 'About',
    component: AboutComii
  },
  {
    path: '/about/:subheading',
    name: 'About SubHeading',
    component: AboutComii
  },
  {
    path: '/for-seniors',
    name: 'For Seniors',
    component: ForSeniors
  },
  {
    path: '/for-seniors/:subheading',
    name: 'ForSeniorsSubHeading',
    component: ForSeniors
  },

  {
    path: '/neighborhood',
    name: 'Neighborhood',
    component: Neighborhood
  },
  {
    path: '/neighborhood/:subheading',
    name: 'NeighborhoodHeading',
    component: Neighborhood
  },

  {
    path: '/mobile-care',
    name: 'mobile care',
    component: MobileCare
  },
  {
    path: '/mobile-care/:subheading',
    name: 'MobileCareSubHeading',
    component: MobileCare
  },
  {
    path: '/conditions-everydayhelpers',
    name: 'ConditionsEverydayHelpers',
    component: ConditionsEveryday
  },
  {
    path: '/conditions-senior',
    name: 'ConditionsSenior',
    component: ConditionsSeniors
  },
  {
    path: '/cookies-policy',
    name: 'CookiesPolicy',
    component: CookiesPolicy
  },
  {
    path: '/impressum',
    name: 'Impressum',
    // beforeEnter() {location.href = 'https://digitalsuxess.de/impressum'},
    component: Impressum
  },
  {
    path: '/privacy-statement',
    name: 'PrivacyStatement',
    component: PrivacyStatement
  },
  
]

const router = new VueRouter({
  
  routes,
  mode:'history'
})

export default router
