<template>
  <div class="for-seniors">
    <top-hero
      :colorClass="$store.getters.colorClass"
      :image="tabsImages[currentTabIndex] || 'doctor-2-old-women.jpg'"
      :isMainHero="!currentSubHeading ? true : false"
      :mainTitle="`${!currentTab ? 'Für Senioren' : currentTab.text}`"
      :heroDarkGradient="false"
      heroBottomPadding="20px"
      :heroWhiteGradient="true"
      :headingBorderLeft="true"
      :isColorTitle="true"
      text="Lorem Ipsum is simply dummy text the printing and typesetting indus Lorem printing and typesetting ."
    >
      <p class="">
        Hier gelangen Sie direkt zu den App Stores. Bitte wählen Sie je nach
        Gerät den richtigen App Store (APPLE Geräte: APP Store) alle anderen
        (Android) bitte den Google Play Store verwenden.
      </p>
    </top-hero>

    <tab-bar-view
      :tabs="tabs"
      @onTabSelected="onTabChanged"
      :basePath="'Startseite'"
      baseUrl="/for-seniors"
      :sectionTitle="'Für Senioren'"
      secondaryBasePath="Für Senioren"
      :colorClass="$store.getters.colorClass"
      :hasBlockPadding="currentSubHeading !== undefined"
    >
      <for-seniors v-show="!currentSubHeading" />
      <how-comii-works v-show="currentSubHeading === 'how-comii-works'" />
      <services v-show="currentSubHeading === 'services'" />
      <prices v-show="currentSubHeading === 'prices'" />
      <our-help v-show="currentSubHeading === 'our-help'" />
      <comii-is-safe v-show="currentSubHeading === 'comii-is-safe'" />
      <faqs v-show="currentSubHeading === 'faqs'" />
    </tab-bar-view>
  </div>
</template>

<script>
import TabBarView from "../../components/global/TabBarView.vue";
import TopHero from "../../components/global/TopHero.vue";
import ComiiIsSafe from "./comii-is-safe.vue";
import Faqs from "./faqs.vue";
import ForSeniors from "./for-seniors.vue";
import HowComiiWorks from "./how-comii-works.vue";
import OurHelp from "./our-help.vue";
import Prices from "./prices.vue";
import Services from "./services.vue";

export default {
  data() {
    return {
      tabs: [
        { text: "Wie funktioniert Comii?", param: "how-comii-works" },
        { text: "Comii Kategorien und Leistungen", param: "services" },
        { text: "Preise & Rechnungsstellung", param: "prices" },
        { text: "Unsere Alltagshelfer", param: "our-help" },
        { text: "Comii ist sicher", param: "comii-is-safe" },
        { text: "FAQs", param: "faqs" },
      ],
      tabsImages: [
        "doctor-2-old-women.jpg",
        "two-doctors-oldman.jpg",
        "coins.jpg",
        "photo1.jpg",
        "man-woman-vegetables.jpg",
        "support-line.jpg",
      ],
      //   currentTab: null,
    };
  },
  components: {
    TopHero,
    TabBarView,
    HowComiiWorks,
    ForSeniors,
    Services,
    Prices,
    OurHelp,
    ComiiIsSafe,
    Faqs,
  },

  methods: {
    onTabChanged(index) {
      this.currentTab = index;
    },
  },

  computed: {
    currentSubHeading() {
      return this.$route.params.subheading;
    },

    currentTab() {
      return this.tabs.find((t) => t.param === this.currentSubHeading);
    },

    currentTabIndex() {
      const index = this.tabs.findIndex(
        (t) => t.param === this.currentSubHeading
      );
      return index >= 0 ? index : null;
    },
  },

  created() {
    this.$store.dispatch("setColorClass", "green");
  },
};
</script>

<style lang="scss" scoped></style>
